import React from "react";

import { useLivechat } from "~/apps/corporate/contexts/livechat.context";

import { useNavBarDesktopHelpButtonTranscription } from "./help-button.transcription";
import { styles } from "./styles";

export const NavBarDesktopHelpButton: React.FC = () => {
  const { transcription } = useNavBarDesktopHelpButtonTranscription();

  const { openLivechat } = useLivechat();

  return (
    <button
      css={styles.root}
      onClick={() => {
        openLivechat();
      }}
    >
      {transcription.title}
    </button>
  );
};
