import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    defaultLabel: "Filters",
  },
  [LanguageCode.PT_BR]: {
    defaultLabel: "Filtros",
  },
} as const;

export const useFiltersTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
