import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    empty: "Refer the next Smartrips customer and earn $1,000.00!",
    link: "View my referrals",
    share: "Share the link with colleagues from other companies:",
    title: "Member Get Member",
  },
  [LanguageCode.PT_BR]: {
    empty: "Indicou o próximo cliente Smartrips, você ganha R$1.000,00!",
    link: "Ver minhas indicações",
    share: "Compartilhe o link com colegas de outras empresas:",
    title: "Indique e Ganhe",
  },
} as const;

export const useUserProfileDrawerMemberGetMemberSectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
