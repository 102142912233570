import React, { useCallback, useMemo, useRef, useState } from "react";

import { useOnClickOutside } from "../../hooks/use-on-click-outside";
import { Option } from "../../types";
import { Icon } from "../icon/icon";
import { useFiltersTranscription } from "./filters.transcription";
import { styles } from "./styles";

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> & {
  anchor?: "left" | "right";
  closeOnSelect?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: null | string;
  onChange: (value: string) => void;
  options: Readonly<Option<string>[]>;
} & (
    | {
        isMulti?: false;
        value: string;
      }
    | {
        isMulti: true;
        value: string[];
      }
  );

export const Filters: React.FC<Props> = ({
  anchor,
  closeOnSelect,
  icon = (
    <div css={styles.icon.root}>
      <Icon css={styles.icon.icon} size={18} use="adjustments-horizontal" />
    </div>
  ),
  isMulti,
  label,
  onChange,
  options,
  value,
  ...props
}) => {
  const { transcription } = useFiltersTranscription();

  const [open, setOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const count = useMemo(
    () =>
      options.reduce(
        (prev, curr) => (value.includes(curr.value) ? prev + 1 : prev),
        0,
      ),
    [options, value],
  );

  const disabled = useMemo(() => props.disabled || options.length === 0, [
    options,
    props.disabled,
  ]);

  const handleChange = useCallback(
    (value: string) => {
      if (closeOnSelect) {
        close();
      }

      onChange(value);
    },
    [closeOnSelect, onChange],
  );

  const toggleOpen = useCallback(() => {
    if (options.length === 0) {
      return;
    }

    setOpen((prev) => !prev);
  }, [options]);

  useOnClickOutside(() => {
    close();
  }, ref);

  return (
    <div css={styles.root} ref={ref} {...props}>
      <button css={styles.container} disabled={disabled} onClick={toggleOpen}>
        {count > 0 && isMulti ? <span css={styles.count}>{count}</span> : null}
        {label !== null ? (
          <span css={styles.label}>
            {label !== undefined
              ? label
              : isMulti
              ? transcription.defaultLabel
              : options.find((option) => option.value === value)?.label ||
                transcription.defaultLabel}
          </span>
        ) : null}
        {icon}
      </button>
      {open ? (
        <ul css={styles.options.root({ anchor })}>
          {options.map((option) => {
            const selected = isMulti
              ? value.includes(option.value)
              : value === option.value;

            return (
              <li css={styles.options.option.root} key={option.value}>
                <button
                  css={styles.options.option.button({
                    selected,
                  })}
                  onClick={() => {
                    handleChange(option.value);
                  }}
                >
                  <span css={styles.options.option.label}>{option.label}</span>
                  {selected ? (
                    <Icon
                      css={styles.options.option.check}
                      size={12}
                      use="check"
                    />
                  ) : null}
                </button>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
