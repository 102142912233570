import React, { useCallback, useMemo, useState } from "react";

import { Select } from "~/apps/shared/components/select/select";
import { Option } from "~/apps/shared/types";
import moment, { Moment } from "moment";

import "moment/dist/locale/pt-br";
moment.locale("pt-BR");

import { styles } from "./styles";
import {
  days,
  months,
  yearsForBithDate,
  yearsForExpiration,
  yearsForIssue,
} from "./three-way-date-select.constants";
import { normalizeDate } from "./three-way-date-select.helper";

type Props = Pick<React.ComponentProps<typeof Select>, "onBlur"> & {
  label?: string;
  onChange?: (date: Moment) => void;
  type: "birthDate" | "expirationDate" | "issueDate" | null;
  value?: Moment;
};

export const ThreeWayDateSelect: React.FC<Props> = ({
  label = "Data",
  onBlur,
  onChange,
  type,
  value,
}) => {
  const [date, setDate] = useState({
    day: value ? value.format("DD") : null,
    month: value ? value.format("MM") : null,
    year: value ? value.format("YYYY") : null,
  });

  const day = useMemo(() => {
    const day = days.find(
      (day) => date.day && parseInt(day.value, 10) === parseInt(date.day, 10),
    );

    if (!day) {
      return undefined;
    }

    return {
      label: day.label,
      value: day.value,
    };
  }, [date]);

  const handleBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (!onBlur) {
      return;
    }

    onBlur(event);
  };

  const handleChangeDay = useCallback(
    ({ value }: Option<string>) => {
      const day = value;

      setDate((prev) => ({
        ...prev,
        day,
      }));

      if (!onChange) {
        return;
      }

      const normalizedDate = normalizeDate({
        day,
        month: date.month,
        year: date.year,
      });

      onChange(normalizedDate);
    },
    [date, onChange],
  );

  const handleChangeMonth = useCallback(
    ({ value }: Option<string>) => {
      const month = value;

      setDate((prev) => ({
        ...prev,
        month,
      }));

      if (!onChange) {
        return;
      }

      const normalizedDate = normalizeDate({
        day: date.day,
        month,
        year: date.year,
      });

      onChange(normalizedDate);
    },
    [date, onChange],
  );

  const handleChangeYear = useCallback(
    ({ value }: Option<string>) => {
      const year = value;

      setDate((prev) => ({
        ...prev,
        year,
      }));

      if (!onChange) {
        return;
      }

      const normalizedDate = normalizeDate({
        day: date.day,
        month: date.month,
        year,
      });

      onChange(normalizedDate);
    },
    [date, onChange],
  );

  const month = useMemo(() => {
    const month = months.find(
      (month) =>
        date.month && parseInt(month.value, 10) === parseInt(date.month, 10),
    );

    if (!month) {
      return undefined;
    }

    return {
      label: month.label,
      value: month.value,
    };
  }, [date]);

  const years = useMemo(() => {
    switch (type) {
      case "birthDate":
        return yearsForBithDate;
      case "expirationDate":
        return yearsForExpiration;
      case "issueDate":
        return yearsForIssue;
      default:
        return [];
    }
  }, [type]);

  const year = useMemo(() => {
    const year = years.find(
      (year) =>
        date.year && parseInt(year.value, 10) === parseInt(date.year, 10),
    );

    if (!year) {
      return undefined;
    }

    return {
      label: year.label,
      value: year.value,
    };
  }, [date, years]);

  return (
    <div css={styles.root}>
      <span css={styles.select.label}>{label}</span>
      <div css={styles.select.root}>
        <Select
          css={styles.select.day}
          id="day"
          isSearchable
          name="day"
          onBlur={handleBlur}
          onChange={handleChangeDay}
          options={days}
          placeholder="DD"
          value={day}
        />
        <Select
          css={styles.select.month}
          id="month"
          isSearchable
          name="month"
          onBlur={handleBlur}
          onChange={handleChangeMonth}
          options={months}
          placeholder="MM"
          value={month}
        />
        <Select
          css={styles.select.year}
          id="year"
          isSearchable
          name="year"
          onBlur={handleBlur}
          onChange={handleChangeYear}
          options={years}
          placeholder="YYYY"
          value={year}
        />
      </div>
    </div>
  );
};
