import moment from "moment";

export const normalizeDate = ({
  day,
  month,
  year,
}: {
  day: null | string;
  month: null | string;
  year: null | string;
}) => {
  return moment(
    `${year}-${month?.length === 1 ? `0${month}` : month}-${
      day?.length === 1 ? `0${day}` : day
    }`,
  );
};
