import React, { createContext, useCallback, useEffect, useState } from "react";

import { LanguageCode } from "~/apps/shared/constants/enums";
import { LANGUAGE_MOMENT_LOCALE_MAP } from "~/apps/shared/constants/language-locales";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import moment from "moment";

import { useUser } from "./user.context";

interface Actions {
  changeLanguageCode: (languageCode: LanguageCode) => Promise<void>;
}

type State = {
  isLoadingChangeLanguageCode: boolean;
  languageCode: LanguageCode;
};

const initialState: State = {
  isLoadingChangeLanguageCode: false,
  languageCode: LanguageCode.PT_BR,
};

type ContextProps = Actions & State;

const LanguageContext = createContext<ContextProps>({
  ...initialState,
  changeLanguageCode: async () => {
    return;
  },
});

export const LanguageProvider: React.FC = ({ children }) => {
  const { updateUserPreferences, user } = useUser();

  const [state, setState] = useState(initialState);

  const changeLanguageCode = useCallback(
    async (languageCode: LanguageCode) => {
      setState((prev) => ({
        ...prev,
        isLoadingChangeLanguageCode: true,
      }));

      const updateUserPreferencesResponse = await updateUserPreferences({
        language: languageCode,
      });

      if (!updateUserPreferencesResponse) {
        setState((prev) => ({
          ...prev,
          isLoadingChangeLanguageCode: false,
        }));

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingChangeLanguageCode: false,
        languageCode: updateUserPreferencesResponse.language,
      }));
    },
    [updateUserPreferences],
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const languageCode = user.getLanguageCode();

    if (!languageCode) {
      return;
    }

    setState((prev) => ({
      ...prev,
      languageCode,
    }));
  }, [user?.getLanguageCode()]);

  useEffect(() => {
    moment.locale(LANGUAGE_MOMENT_LOCALE_MAP[state.languageCode]);
  }, [state.languageCode]);

  return (
    <LanguageContext.Provider
      value={{
        ...state,
        changeLanguageCode,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = useContextFactory(
  "LanguageContext",
  LanguageContext,
);
