import {
  Capabilities,
  CurrencyCode,
  LanguageCode,
  Permissions,
  TripsStatusFilter,
  UserBookingPhoneConfig,
  VipStatus,
} from "~/apps/shared/constants/enums";
import { Option } from "~/apps/shared/types";

import { UserPreferences } from "../models/user.model";

export type ConvertGuestToUserRequest = {
  client_token: string;
};

export type ConvertUserToGuestRequest = {
  client_token: string;
};

export type CreateUserRequest = {
  billing_profile_token?: string | null;
  booking_target_tokens: string[];
  boss_token?: string;
  capabilities?: Capabilities;
  client_token: string;
  email: string;
  first_name: string;
  guest: boolean;
  hidden?: boolean;
  last_name: string;
  password?: string;
  phone?: string | null;
  role: string;
  send_invite: boolean;
  username?: string;
  userPreference?: {
    currency?: string;
  };
  approval_bypass?: ApprovalBypass;
};

export type CreateUserResponse = {
  active: boolean;
  booking_phone_config: UserBookingPhoneConfig;
  capabilities: Capabilities;
  email: string;
  first_name: string;
  full_name: string;
  guest: boolean;
  hidden: boolean;
  last_name: string;
  phone: string;
  role?: string;
  send_sms: boolean;
  userPreferences?: {
    currency: CurrencyCode;
    language: LanguageCode;
  };
  user_token: string;
};

export enum ApprovalBypass {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export type DeleteUserResponse =
  | DeleteUserResponseFailure
  | DeleteUserResponseSuccess;

export enum DeleteUserResponseFailurePendingApprovalType {
  APPROVAL_PROCESS = "approval-process",
  EXPENSE_ADVANCE = "expense-advance",
  EXPENSE_REPORT = "expense-report",
  TRAVEL = "travel",
}

export type DeleteUserResponseFailure = {
  pending_issues: {
    items: Option<string>[];
    type: DeleteUserResponseFailurePendingApprovalType;
  }[];
};

type DeleteUserResponseSuccess = void;

export type EditUserRequest = {
  billing_profile_token?: string;
  booking_phone_config?: UserBookingPhoneConfig;
  booking_target_tokens?: string[];
  boss_token?: string;
  capabilities?: Capabilities;
  email?: string;
  first_name?: string;
  guest?: boolean;
  last_name?: string;
  password?: string;
  phone?: string;
  role?: string;
  send_sms?: boolean;
  approval_bypass?: ApprovalBypass;
};

export type EditUserResponse = {
  accepted_terms: boolean;
  active: boolean;
  agency_token: string;
  billing_profile_token?: string | null;
  booking_phone_config: UserBookingPhoneConfig;
  boss_token?: string | null;
  capabilities: Capabilities;
  client_token: string;
  created_at: string;
  email: string;
  failed_login_attempts: number;
  first_name: string;
  full_name: string;
  guest: boolean;
  hidden: boolean;
  id: string;
  last_login: string;
  last_name: string;
  legacy_auth: boolean;
  locked: boolean;
  password_forgot_token: string | null;
  password: string;
  phone: string;
  provider_share_code_info?: any;
  role?: string;
  send_sms: boolean;
  share_code: string | null;
  tripactions_id?: string | null;
  user_token: string;
  username: string;
};

export type EditUserProfileDto = {
  billingProfileToken: string | null;
  firstName: string;
  lastName: string;
  phone: string | null;
  sendSms: boolean | null;
};

export type EditUserProfileRequestBody = {
  billing_profile_token?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  send_sms?: boolean;
};

export type EditUserProfileResponse = {
  active: boolean;
  agency_token: string;
  billing_profile_token: string;
  booking_phone_config: string;
  capabilities: Capabilities;
  email: string;
  first_name: string;
  full_name: string;
  guest: boolean;
  last_name: string;
  phone: string;
  role: string;
  send_sms: boolean;
  user_token: string;
};

export type GetShareCodeResponse = {
  code: string;
  home_link: string;
};

export type GetUsersByNameResponse = {
  billing_profile_token: string | null;
  client_token: string;
  email: string;
  first_name: string;
  full_name: string;
  last_name: string;
  user_token: string;
}[];

export type GetTravelsCountRequest = {
  filters: {
    pendingFilters: TripsStatusFilter[];
    search: string;
    travelerToken: string;
  };
  userToken: string;
};

export type GetTravelsCountResponse = {
  all: number;
  canceled: number;
  draft: number;
  now: number;
  past: number;
  upcoming: number;
};

export type GetUserAcceptedTermsResponse = {
  has_accepted_terms: boolean;
};

export type GetUserFromAccessTokenDto = {
  agency_token: string;
  capabilities?: Capabilities;
  client_slug?: string;
  client_name?: string;
  client_token?: string;
  guest?: boolean | null;
  hidden?: boolean;
  permissions?: Permissions;
  plan?: string;
  role?: string;
  send_sms?: boolean | null;
  user_preferences: {
    currency: CurrencyCode;
    language: LanguageCode;
  } | null;
  user_token: string;
  vip_status?: VipStatus;
};

export type GetUserResponse = {
  active: number;
  agency_token?: string;
  billing_profile_token: string;
  booking_phone_config?: UserBookingPhoneConfig;
  capabilities: Capabilities;
  client_token: string;
  created_at: string;
  email: string;
  first_name: string;
  full_name: string;
  guest: number;
  hidden: number;
  last_login: string;
  last_name: string;
  phone: string;
  role: string;
  send_sms: number;
  user_preferences: UserPreferences | null;
  user_token: string;
  vip_status?: VipStatus;
  user_attributes: {
    approval_bypass: ApprovalBypass;
    created_at: string;
    pagarme_id: string | null;
    token: string;
    updated_at: string;
    user_token: string;
    vip_status: VipStatus;
  };
};

export type GetUserRewardsPointsResponse = {
  notPaidPoints: number;
  paidPoints: number;
  rewardActive: boolean;
};

export type LoginDto = {
  email: string;
  password: string;
};

export type LoginResponse = {
  data: {
    access_token: string;
  };
  status: string;
};

export type ReenviteUserResponse = {
  code: number;
  message: string;
  type: string;
};

export type UpdateUserPreferencesRequest = {
  currency: CurrencyCode;
  language: LanguageCode;
};

export type UpdateUserPreferencesResponse = {
  created_at: Date;
  currency: CurrencyCode;
  language: LanguageCode;
  token: string;
  updated_at: Date;
  user_token: string;
};

export type UserResponse = {
  active: boolean | number;
  capabilities: Capabilities;
  client_token: string;
  created_at: string;
  document?: string | null;
  email: string;
  first_name: string;
  full_name?: string;
  last_login?: string | null;
  last_name: string;
  role: string;
  user_token: string;
};

export type UserCamelDto = {
  active: boolean | number;
  capabilities: Capabilities;
  clientToken: string;
  createdAt: string;
  document?: string | null;
  email: string;
  firstName: string;
  fullName?: string;
  lastLogin?: string | null;
  lastName: string;
  role: string;
  userToken: string;
};

export type UserSearchResponse = {
  client_token: string;
  document: string;
  email: string;
  first_name: string;
  full_name: string;
  label: string;
  last_name: string;
  user_token: string;
};
