import React, { useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { MemberGetMemberDrawer } from "~/apps/corporate/components/member-get-member-drawer/member-get-member-drawer";
import { useMemberGetMember } from "~/apps/corporate/contexts/member-get-member.context";
import { CopyIconButtonWithTooltip } from "~/apps/shared/components/copy-icon-button-with-tooltip/copy-icon-button-with-tooltip";

import { useUserProfileDrawerMemberGetMemberSectionTranscription } from "./member-get-member-section.transcription";
import { styles } from "./styles";

export const UserProfileDrawerMemberGetMemberSection: React.FC = () => {
  const {
    transcription,
  } = useUserProfileDrawerMemberGetMemberSectionTranscription();

  const { isLoadingShareCode, shareCode } = useMemberGetMember();

  const [
    isMemberGetMemberDrawerOpen,
    setIsMemberGetMemberDrawerOpen,
  ] = useState(false);

  const formattedShareCode = useMemo(() => {
    if (!shareCode) {
      return null;
    }

    return `indiqueeganhe.smartrips.com.br/?share-code=${shareCode}`;
  }, [shareCode]);

  const handleCloseMemberGetMemberDrawer = useCallback(() => {
    setIsMemberGetMemberDrawerOpen(false);
  }, []);

  const handleOpenMemberGetMemberDrawer = useCallback(() => {
    setIsMemberGetMemberDrawerOpen(true);
  }, []);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>{transcription.title}</span>
          <button
            css={styles.header.button}
            onClick={() => {
              handleOpenMemberGetMemberDrawer();
            }}
          >
            {transcription.link}
          </button>
        </div>
        <div css={styles.referral.root}>
          {formattedShareCode ? (
            <>
              <span css={styles.referral.label}>{transcription.share}</span>
              <div css={styles.referral.display.root}>
                <span css={styles.referral.display.text}>
                  {formattedShareCode}
                </span>
                <CopyIconButtonWithTooltip
                  css={styles.referral.display.button}
                  text={formattedShareCode}
                />
              </div>
            </>
          ) : isLoadingShareCode ? (
            <>
              <span css={styles.referral.label}>{transcription.share}</span>
              <div css={styles.referral.display.root}>
                <Skeleton height="18px" width="222px" />
                <CopyIconButtonWithTooltip
                  css={styles.referral.display.button}
                  disabled
                  text=""
                />
              </div>
            </>
          ) : (
            <span css={styles.referral.label}>{transcription.empty}</span>
          )}
        </div>
      </div>
      <MemberGetMemberDrawer
        onClose={handleCloseMemberGetMemberDrawer}
        open={isMemberGetMemberDrawerOpen}
      />
    </>
  );
};
