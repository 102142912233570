import { LanguageCode } from "~/apps/shared/constants/enums";
import moment, { Moment } from "moment";
import * as yup from "yup";

import { FlightsSearchPlace } from "../offer-searcher.types";

const flightFormSchemaTranscription = {
  [LanguageCode.EN_US]: {
    destination: "Fill in your destination",
    endDate: "Fill in the return date",
    origin: "Fill in your origin",
    startDate: "Fill in the departure date",
  },
  [LanguageCode.PT_BR]: {
    destination: "Preencha seu local de destino",
    endDate: "Preencha a data de volta",
    origin: "Preencha seu local de origem",
    startDate: "Preencha a data de ida",
  },
} as const;

export const createFlightFormSchema = (languageCode: LanguageCode) =>
  yup.object().shape({
    cabinClass: yup.string(),
    destination: yup
      .mixed<FlightsSearchPlace>()
      .required(
        flightFormSchemaTranscription[
          languageCode as keyof typeof flightFormSchemaTranscription
        ].destination,
      )
      .nullable(true),
    endDate: yup
      .mixed<Moment>()
      .test(
        "",
        flightFormSchemaTranscription[
          languageCode as keyof typeof flightFormSchemaTranscription
        ].endDate,
        (endDate, context) => {
          if (context.parent.type === "roundtrip" && !endDate) {
            return false;
          }

          return true;
        },
      )
      .nullable(true),
    origin: yup
      .mixed<FlightsSearchPlace>()
      .required(
        flightFormSchemaTranscription[
          languageCode as keyof typeof flightFormSchemaTranscription
        ].origin,
      )
      .nullable(true),
    startDate: yup
      .mixed<Moment>()
      .required(
        flightFormSchemaTranscription[
          languageCode as keyof typeof flightFormSchemaTranscription
        ].startDate,
      )
      .test("", "Data inicial inferior a hoje", (startDate) => {
        if (!startDate) {
          return false;
        }

        const today = moment().startOf("day");

        if (startDate.isBefore(today)) {
          return false;
        }

        return true;
      })
      .nullable(true),
    type: yup.mixed<"oneway" | "roundtrip">().required(),
  });

export type FlightFormSchema = yup.InferType<
  ReturnType<typeof createFlightFormSchema>
>;
