import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    oneway: "One way",
    roundtrip: "Round trip",
    title: "Latest searches",
    traveler: "Traveler",
  },
  [LanguageCode.PT_BR]: {
    oneway: "Só ida",
    roundtrip: "Ida e volta",
    title: "Buscas recentes",
    traveler: "Viajante",
  },
} as const;

export const useNewTripRecentFlightsTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
