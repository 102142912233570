import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

import { useUserProfileDrawer } from "./user-profile-drawer.context";

const transcriptions = {
  [LanguageCode.EN_US]: {
    sections: {
      logout: {
        link: "Logout",
        title: "Session",
      },
      safety: {
        link: "Update password",
        title: "Safety",
      },
    },
    title: (view: ReturnType<typeof useUserProfileDrawer>["view"]) => {
      switch (view) {
        case "edit-basic-information":
          return "Edit basic information";
        case "save-document":
          return "Save document";
        case "save-loyalty-program":
          return "Save loyalty program";
        case "save-payment-method":
          return "Save payment method";
        case "save-user-boss":
          return "Save supervisor";
        default:
          return "My profile";
      }
    },
  },
  [LanguageCode.PT_BR]: {
    sections: {
      logout: {
        link: "Sair",
        title: "Encerrar sessão",
      },

      safety: {
        link: "Alterar senha",
        title: "Segurança",
      },
    },
    title: (view: ReturnType<typeof useUserProfileDrawer>["view"]) => {
      switch (view) {
        case "edit-basic-information":
          return "Editar informações básicas";
        case "save-document":
          return "Salvar documento";
        case "save-loyalty-program":
          return "Salvar programa de fidelidade";
        case "save-payment-method":
          return "Salvar meio de pagamento";
        case "save-user-boss":
          return "Salvar supervisor";
        default:
          return "Meu perfil";
      }
    },
  },
} as const;

export const useUserProfileDrawerTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
