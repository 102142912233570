import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { DOCUMENT_TYPES_TRANSCRIPTION } from "~/apps/shared/constants";
import { LanguageCode } from "~/apps/shared/constants/enums";

const createTranscriptions = (languageCode: LanguageCode) =>
  ({
    [LanguageCode.EN_US]: {
      empty: "No documents registered.",
      link: (remaningDocument?: keyof typeof DOCUMENT_TYPES_TRANSCRIPTION) => {
        if (!remaningDocument) {
          return "Add documents";
        }

        return `Add ${DOCUMENT_TYPES_TRANSCRIPTION[remaningDocument][languageCode]}`;
      },
      title: "Documents",
    },
    [LanguageCode.PT_BR]: {
      empty: "Nenhum documento cadastrado.",
      link: (
        remaningDocument?: keyof typeof DOCUMENT_TYPES_TRANSCRIPTION | null,
      ) => {
        if (!remaningDocument) {
          return "Adicionar documentos";
        }

        return `Adicionar ${DOCUMENT_TYPES_TRANSCRIPTION[remaningDocument][languageCode]}`;
      },
      title: "Documentos",
    },
  } as const);

export const useUserProfileDrawerDocumentsSectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcriptions = createTranscriptions(languageCode);

  const transcription = transcriptions[languageCode];

  return { transcription };
};
