import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  input: {
    input: css({
      width: "100%",
    }),
    root: css({
      alignItems: "center",
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      backgroundColor: "transparent",
      display: "flex",
      gap: "16px",
      outline: "none",
      overflow: "hidden",
      padding: "0 16px",
    }),
  },
  menu: {
    item: ({
      isDisabled,
      isHighlighted,
    }: {
      isDisabled?: boolean;
      isHighlighted: boolean;
    }) =>
      css({
        backgroundColor: isHighlighted
          ? theme.colors.background.gray
          : "transparent",
        color: theme.colors.gray[500],
        cursor: isDisabled ? "default" : "pointer",
        display: "flex",
        fontSize: "0.875rem",
        padding: "12px 16px",
      }),
    root: css({
      backgroundColor: theme.colors.white,
      border: `1px solid ${theme.colors.gray[100]}`,
      borderRadius: "8px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      left: "0",
      listStyle: "none",
      overflow: "hidden",
      position: "absolute",
      right: "0",
      top: "calc(100% + 4px)",
      width: "100%",
      zIndex: 10,
    }),
  },
};
