import * as clientApi from "~/apps/corporate/apis/client.api";
import * as travelApi from "~/apps/corporate/apis/travel.api";
import * as userApi from "~/apps/corporate/apis/user.api";
import errors, { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { formatServiceError } from "~/apps/shared/utils/errors";

import { GetUsersRequestParams } from "../../dtos/client.dto";
import { GetUserTravelRequest } from "../../dtos/travel.dto";
import {
  GetTravelsCountRequest,
  GetUserRewardsPointsResponse,
} from "../../dtos/user.dto";
import { TravelsCount, UserTravels } from "../../models/travel.model";
import { User } from "../../models/user.model";

export const deleteTravel = async (travelToken: string) => {
  const result: { error?: Error } = {};

  try {
    await travelApi.deleteTravel(travelToken);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_TRAVEL_LIST_DELETE_TRAVEL_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_TRAVEL_LIST_DELETE_TRAVEL_ERROR;
    }
  }

  return result;
};

export const getTravelsCount = async (
  data: GetTravelsCountRequest,
): Promise<Either<Error, TravelsCount>> => {
  try {
    return success(await userApi.getTravelsCount(data));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_TRAVELS_COUNT_ERROR,
      }),
    );
  }
};

export const getUserRewardsPoints = async (
  userToken: string,
): Promise<Either<Error, GetUserRewardsPointsResponse>> => {
  try {
    return success(await travelApi.getUserRewardsPoints(userToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_LOAD_POINTS_ERROR,
      }),
    );
  }
};

export const getUsers = async (
  params?: GetUsersRequestParams,
): Promise<Either<Error, User[]>> => {
  try {
    return success(await clientApi.getUsersRequest(params));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
      }),
    );
  }
};

export const getUserTravels = async (
  data: GetUserTravelRequest,
): Promise<Either<Error, UserTravels>> => {
  try {
    return success(await travelApi.getUserTravels(data));
  } catch (error) {
    const { response } = error;

    if (response) {
      const { data, status } = error.response;

      if (status === 400 && data.type === errors.ERROR_FINDING_TRAVEL) {
        return failure(
          formatServiceError({
            error: ERROR.UNEXPECTED,
          }),
        );
      }
    }

    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_LIST_USER_TRAVELS_ERROR,
      }),
    );
  }
};
