import React, { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { useLoyaltyPrograms } from "~/apps/corporate/contexts/loyalty-program.context";
import { LoyaltyProgram } from "~/apps/corporate/models/loyalty-program.model";
import latamPassLogo from "~/apps/shared/assets/images/loyalty-programs/latam_pass.svg";
import smilesLogo from "~/apps/shared/assets/images/loyalty-programs/smiles.svg";
import tudoAzulLogo from "~/apps/shared/assets/images/loyalty-programs/tudo_azul.png";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Menu } from "~/apps/shared/components/menu/menu";
import { MenuItem } from "~/apps/shared/components/menu/menu-item/menu-item";
import { LOYALTY_PROGRAMS } from "~/apps/shared/constants";

import { useUserProfileDrawer } from "../../../user-profile-drawer.context";
import { useUserProfileDrawerLoyaltySectionTranscription } from "./loyalty-section.transcription";
import { styles } from "./styles";

const COMPANY_LOGOS = {
  latam_pass: latamPassLogo,
  smiles: smilesLogo,
  tudo_azul: tudoAzulLogo,
} as const;

export const UserProfileDrawerLoyaltySection: React.FC = () => {
  const { transcription } = useUserProfileDrawerLoyaltySectionTranscription();

  const {
    fetchUserFidelityPrograms,
    isLoading,
    programs,
  } = useLoyaltyPrograms();

  const {
    addLoyaltyProgram,
    editLoyaltyProgram,
    openRemoveLoyaltyProgramAlertDialog,
  } = useUserProfileDrawer();

  const [
    moreOptionsPopoverAnchorElement,
    setMoreOptionsPopoverAnchorElement,
  ] = useState<HTMLElement | null>(null);
  const [
    selectedLoyaltyProgram,
    setSelectedLoyaltyProgram,
  ] = useState<LoyaltyProgram | null>(null);

  const handleCloseMoreOptionsPopover = useCallback(() => {
    setMoreOptionsPopoverAnchorElement(null);
    setSelectedLoyaltyProgram(null);
  }, []);

  const handleOpenMoreOptionsPopover = useCallback(
    (event: React.MouseEvent<HTMLElement>, loyaltyProgram: LoyaltyProgram) => {
      setMoreOptionsPopoverAnchorElement(event.currentTarget);
      setSelectedLoyaltyProgram(loyaltyProgram);
    },
    [],
  );

  useEffect(() => {
    void fetchUserFidelityPrograms();
  }, [fetchUserFidelityPrograms]);

  return (
    <>
      <div css={styles.root}>
        <div css={styles.header.root}>
          <span css={styles.header.title}>{transcription.title}</span>
          <button
            css={styles.header.button}
            onClick={() => {
              addLoyaltyProgram();
            }}
          >
            {transcription.link}
          </button>
        </div>
        <div css={styles.loyalty.root}>
          {programs.length > 0 ? (
            programs.map((loyaltyProgram) => (
              <div
                css={styles.loyalty.card.root}
                key={loyaltyProgram.loyaltyProgramToken}
              >
                <div css={styles.loyalty.card.top.root}>
                  <img
                    alt={LOYALTY_PROGRAMS[loyaltyProgram.name]}
                    css={styles.loyalty.card.top.image}
                    height={32}
                    src={COMPANY_LOGOS[loyaltyProgram.name]}
                  />
                  <button
                    css={styles.loyalty.card.top.button}
                    onClick={(event) => {
                      handleOpenMoreOptionsPopover(event, loyaltyProgram);
                    }}
                  >
                    <Icon size={16} use="ellipsis-vertical" />
                  </button>
                </div>
                <div css={styles.loyalty.card.bottom.root}>
                  <span css={styles.loyalty.card.bottom.name}>
                    {LOYALTY_PROGRAMS[loyaltyProgram.name]}
                  </span>
                  <span css={styles.loyalty.card.bottom.code}>
                    {loyaltyProgram.code}
                  </span>
                </div>
              </div>
            ))
          ) : isLoading ? (
            <Skeleton height="132px" width="352px" />
          ) : (
            <span css={styles.loyalty.empty}>{transcription.empty}</span>
          )}
        </div>
      </div>
      <Menu
        anchorEl={moreOptionsPopoverAnchorElement}
        onClose={handleCloseMoreOptionsPopover}
        open={!!moreOptionsPopoverAnchorElement}
      >
        <MenuItem
          onClick={() => {
            if (!selectedLoyaltyProgram) {
              return;
            }

            editLoyaltyProgram(selectedLoyaltyProgram);

            handleCloseMoreOptionsPopover();
          }}
        >
          {transcription.menu.edit}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (!selectedLoyaltyProgram) {
              return;
            }

            openRemoveLoyaltyProgramAlertDialog(selectedLoyaltyProgram);

            handleCloseMoreOptionsPopover();
          }}
        >
          {transcription.menu.remove}
        </MenuItem>
      </Menu>
    </>
  );
};
