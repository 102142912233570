import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      confirm: "Remove",
    },
    fallbackMessage: "Do you really want to remove the payment method?",
    message: (paymentMethodDescription: string) =>
      `Do you really want to remove the payment method${
        paymentMethodDescription ? ` ${paymentMethodDescription}` : ""
      }?`,
    title: "Remove Payment Method",
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      confirm: "Remover",
    },
    fallbackMessage: "Deseja realmente remover o meio de pagamento?",
    message: (paymentMethodDescription: string) =>
      `Deseja realmente remover o meio de pagamento${
        paymentMethodDescription ? ` ${paymentMethodDescription}` : ""
      }?`,
    title: "Remover meio de pagamento",
  },
} as const;

export const useRemovePaymentMethodAlertDialogTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
