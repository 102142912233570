import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      cancel: "Cancel",
      save: "Save",
    },
    fields: {
      allowApprovers:
        "Enable the use of this card in my travels by approvers and bookers.",
      cardCvv: {
        label: "CVV*",
        placeholder: "Enter CVV...",
      },
      cardExpirationDate: {
        label: "Expiration date*",
        placeholder: "Enter expiration date...",
      },
      cardHolderName: {
        info: "Exactly as shown on the card.",
        label: "Cardholder name*",
        placeholder: "Enter cardholder name...",
      },
      cardNickname: {
        label: "Card nickname*",
        placeholder: "Enter a nickname for the card...",
      },
      cardNumber: {
        label: "Card number*",
        placeholder: "Enter card number...",
      },
      cardPurposes: "Card purposes",
    },
    services: {
      bus: "Bus",
      car: "Car",
      flight: "Flight",
      hotel: "Hotel",
      other: "Travel Fee and Others",
      ride: "Taxi",
    },
    unavailableServiceTypeOptionsInfo: (
      unavailableServiceTypeOptions: string[],
    ) =>
      `*The travel manager did not allow the registration of individual cards for purchases of the(s) service(s): ${unavailableServiceTypeOptions.join(
        ", ",
      )}.`,
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      cancel: "Cancelar",
      save: "Salvar",
    },
    fields: {
      allowApprovers:
        "Viabilizar o uso deste cartão em minhas viagens por parte de aprovadores e solicitantes.",
      cardCvv: {
        label: "CVV*",
        placeholder: "Digite o CVV...",
      },
      cardExpirationDate: {
        label: "Data de expiração*",
        placeholder: "Digite a data de expiração...",
      },
      cardHolderName: {
        info: "Exatamente igual ao cartão.",
        label: "Nome do Portador*",
        placeholder: "Digite o nome do portador do cartão...",
      },
      cardNickname: {
        label: "Apelido do cartão*",
        placeholder: "Digite um apelido para o cartão...",
      },
      cardNumber: {
        label: "Número do cartão*",
        placeholder: "Digite o número do cartão...",
      },
      cardPurposes: "Finalidades do cartão",
    },
    services: {
      bus: "Ônibus",
      car: "Carro",
      flight: "Aéreo",
      hotel: "Hotel",
      other: "Fee de Viagem e Outros",
      ride: "Táxi",
    },
    unavailableServiceTypeOptionsInfo: (
      unavailableServiceTypeOptions: string[],
    ) =>
      `*O gestor de viagens não permitiu o cadastro de cartões individuais para compras dos(s) serviço(s): ${unavailableServiceTypeOptions.join(
        ", ",
      )}.`,
  },
} as const;

export const useCreatePaymentMethodTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
