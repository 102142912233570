import React, { Fragment, useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";

import personWithMegaphone from "~/apps/shared/assets/images/person-with-megaphone.svg";
import { CopyIconButtonWithTooltip } from "~/apps/shared/components/copy-icon-button-with-tooltip/copy-icon-button-with-tooltip";
import { Drawer, DrawerHeader } from "~/apps/shared/components/drawer/drawer";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Stepper } from "~/apps/shared/components/stepper/stepper";
import {
  Step,
  StepLabel,
} from "~/apps/shared/components/stepper/stepper-step/stepper-step";

import { Button, CircularSpinner } from "@toolkit/v2";

import { useMemberGetMember } from "../../contexts/member-get-member.context";
import { useMemberGetMemberDrawerTranscription } from "./member-get-member-drawer.transcription";
import { MemberGetMemberTermsAndConditionsDialog } from "./member-get-member-terms-and-conditions-dialog/member-get-member-terms-and-conditions-dialog";
import { styles } from "./styles";

type Props = React.ComponentProps<typeof Drawer>;

export const MemberGetMemberDrawer: React.FC<Props> = ({
  onClose,
  ...props
}) => {
  const {
    generateShareCode,
    isGeneratingShareCode,
    isLoadingShareCode,
    shareCode,
  } = useMemberGetMember();
  const [
    isTermsAndConditionsDialogOpen,
    setIsTermsAndConditionsDialogOpen,
  ] = useState(false);
  const { transcription } = useMemberGetMemberDrawerTranscription();

  const formattedShareCode = useMemo(() => {
    if (!shareCode) {
      return null;
    }

    return `indiqueeganhe.smartrips.com.br/?share-code=${shareCode}`;
  }, [shareCode]);

  const handleGenerateShareCode = useCallback(async () => {
    if (isGeneratingShareCode) {
      return;
    }

    await generateShareCode();
  }, [generateShareCode, isGeneratingShareCode]);

  const handleOpenTermsAndConditionsDialog = useCallback(() => {
    setIsTermsAndConditionsDialogOpen(true);
  }, []);

  const handleCloseTermsAndConditionsDialog = useCallback(() => {
    setIsTermsAndConditionsDialogOpen(false);
  }, []);

  const handleClose = useCallback(
    (event: {}, reason: "backdropClick" | "escapeKeyDown" | "none") => {
      if (!onClose) {
        return;
      }

      if (reason === "none") {
        onClose(event, "backdropClick");

        return;
      }

      onClose(event, reason);
    },
    [onClose],
  );

  return (
    <>
      <Drawer
        anchor="right"
        disableRestoreFocus
        onClose={handleClose}
        {...props}
      >
        <DrawerHeader
          onClose={() => {
            handleClose({}, "none");
          }}
        >
          {transcription.header}
        </DrawerHeader>
        <div css={styles.root}>
          <div css={styles.body.root}>
            <img
              alt="Pessoa com megafone"
              css={styles.body.image}
              src={personWithMegaphone}
            />
            <span css={styles.body.title}>{transcription.body.title}</span>
            <div css={styles.body.referral.root}>
              {formattedShareCode ? (
                <>
                  <span css={styles.body.referral.label}>
                    {transcription.body.referral.label}
                  </span>
                  <div css={styles.body.referral.display.root}>
                    <span css={styles.body.referral.display.text}>
                      {formattedShareCode}
                    </span>
                    <CopyIconButtonWithTooltip
                      css={styles.body.referral.display.button}
                      text={formattedShareCode}
                    />
                  </div>
                </>
              ) : isLoadingShareCode ? (
                <>
                  <span css={styles.body.referral.label}>
                    {transcription.body.referral.label}
                  </span>
                  <div css={styles.body.referral.display.root}>
                    <Skeleton height="18px" width="222px" />
                    <CopyIconButtonWithTooltip
                      css={styles.body.referral.display.button}
                      disabled
                      text=""
                    />
                  </div>
                </>
              ) : (
                <Button
                  css={styles.body.referral.button}
                  onClick={async () => {
                    await handleGenerateShareCode();
                  }}
                >
                  {isGeneratingShareCode ? (
                    <CircularSpinner css={{ color: "white" }} size={18} />
                  ) : (
                    <Icon size={18} use="paper-clip" />
                  )}
                  {isGeneratingShareCode
                    ? transcription.body.referral.button.generating
                    : transcription.body.referral.button.generate}
                </Button>
              )}
            </div>
            <div css={styles.body.stepper.root}>
              <Stepper activeStep={-1} alternativeLabel>
                <Step>
                  <StepLabel
                    css={styles.body.stepper.step.label}
                    icon={() => (
                      <div css={styles.body.stepper.step.icon}>1</div>
                    )}
                  >
                    {transcription.body.steps.step1}
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel
                    css={styles.body.stepper.step.label}
                    icon={() => (
                      <div css={styles.body.stepper.step.icon}>2</div>
                    )}
                  >
                    {transcription.body.steps.step2}
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel
                    css={styles.body.stepper.step.label}
                    icon={() => (
                      <div css={styles.body.stepper.step.icon}>3</div>
                    )}
                  >
                    {transcription.body.steps.step3}
                  </StepLabel>
                </Step>
              </Stepper>
            </div>
            <div css={styles.body.explanation.root}>
              <span css={styles.body.explanation.title}>
                {transcription.body.explanation.title}
              </span>
              <p css={styles.body.explanation.paragraph}>
                {transcription.body.explanation.paragraph.map(
                  (paragraph, index) => (
                    <Fragment key={paragraph}>
                      {paragraph}
                      {index !==
                        transcription.body.explanation.paragraph.length - 1 && (
                        <>
                          <br />
                          <br />
                        </>
                      )}
                    </Fragment>
                  ),
                )}
              </p>
              <span css={styles.body.explanation.info}>
                {transcription.body.explanation.info}&nbsp;
                <button
                  css={styles.body.explanation.button}
                  onClick={() => {
                    handleOpenTermsAndConditionsDialog();
                  }}
                >
                  {transcription.body.explanation.learnMore}
                </button>
              </span>
            </div>
            <div css={styles.body.important.root}>
              <span css={styles.body.important.title}>
                {transcription.body.important.title}
              </span>
              <p css={styles.body.important.paragraph}>
                {transcription.body.important.items.map((item, index) => (
                  <Fragment key={item}>
                    {item}
                    {index !==
                      transcription.body.explanation.paragraph.length - 1 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                  </Fragment>
                ))}
              </p>
            </div>
            <div css={styles.body.terms.root}>
              <button
                css={styles.body.terms.button}
                onClick={() => {
                  handleOpenTermsAndConditionsDialog();
                }}
              >
                {transcription.body.termsAndConditions}
              </button>
            </div>
          </div>
        </div>
      </Drawer>
      <MemberGetMemberTermsAndConditionsDialog
        onClose={handleCloseTermsAndConditionsDialog}
        open={isTermsAndConditionsDialogOpen}
      />
    </>
  );
};
