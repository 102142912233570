import React, { createContext, useCallback, useEffect, useState } from "react";

import { CurrencyCode } from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import { useUser } from "./user.context";

interface Actions {
  changeCurrencyCode: (currencyCode: CurrencyCode) => Promise<void>;
}

type State = {
  currencyCode: CurrencyCode;
  isLoadingChangeCurrencyCode: boolean;
};

const initialState: State = {
  currencyCode: CurrencyCode.BRL,
  isLoadingChangeCurrencyCode: false,
};

type ContextProps = Actions & State;

const CurrencyContext = createContext<ContextProps>({
  ...initialState,
  changeCurrencyCode: async () => {
    return;
  },
});

export const CurrencyProvider: React.FC = ({ children }) => {
  const { updateUserPreferences, user } = useUser();

  const [state, setState] = useState(initialState);

  const changeCurrencyCode = useCallback(
    async (currencyCode: CurrencyCode) => {
      setState((prev) => ({
        ...prev,
        isLoadingChangeCurrencyCode: true,
      }));

      const updateUserPreferencesResponse = await updateUserPreferences({
        currency: currencyCode,
      });

      if (!updateUserPreferencesResponse) {
        setState((prev) => ({
          ...prev,
          isLoadingChangeCurrencyCode: false,
        }));

        return;
      }

      setState((prev) => ({
        ...prev,
        isLoadingChangeCurrencyCode: false,
        currencyCode: updateUserPreferencesResponse.currency,
      }));
    },
    [updateUserPreferences],
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const currencyCode = user.getCurrencyCode();

    if (!currencyCode) {
      return;
    }

    setState((prev) => ({
      ...prev,
      currencyCode,
    }));
  }, [user]);

  return (
    <CurrencyContext.Provider
      value={{
        ...state,
        changeCurrencyCode,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = useContextFactory(
  "CurrencyContext",
  CurrencyContext,
);
