import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

export enum CabinClass {
  BUSINESS = "business",
  ECONOMY = "economy",
  FIRST = "first",
  PREMIUM_ECONOMY = "premium_economy",
}

const constants = {
  [LanguageCode.EN_US]: {
    CABIN_CLASS_OPTIONS: {
      economy: {
        name: "Economy",
        value: CabinClass.ECONOMY,
      },
      premium_economy: {
        name: "Premium Economy",
        value: CabinClass.PREMIUM_ECONOMY,
      },
      business: {
        name: "Business",
        value: CabinClass.BUSINESS,
      },
      first: {
        name: "First Class",
        value: CabinClass.FIRST,
      },
    },
  },
  [LanguageCode.PT_BR]: {
    CABIN_CLASS_OPTIONS: {
      economy: {
        name: "Econômica",
        value: CabinClass.ECONOMY,
      },
      premium_economy: {
        name: "Econômica Premium",
        value: CabinClass.PREMIUM_ECONOMY,
      },
      business: {
        name: "Business",
        value: CabinClass.BUSINESS,
      },
      first: {
        name: "Primeira Classe",
        value: CabinClass.FIRST,
      },
    },
  },
} as const;

export const useFlightFormConstants = () => {
  const { languageCode } = useLanguage();

  const flightFormConstants = constants[languageCode as keyof typeof constants];

  return {
    flightFormConstants,
  };
};
