import React, { useEffect, useMemo } from "react";

import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { TripsTabsFilter, LanguageCode } from "~/apps/shared/constants/enums";

import { Button } from "@toolkit/v2";

import {
  useTravelsFilters,
  useTravelsFiltersActions,
  useTravelsList,
  useTravelsListActions,
} from "../trips.context";
import { styles } from "./styles";

const tabs = [
  {
    label: {
      [LanguageCode.EN_US]: "All",
      [LanguageCode.PT_BR]: "Todas",
    },
    value: TripsTabsFilter.ALL,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Draft",
      [LanguageCode.PT_BR]: "Rascunho",
    },
    value: TripsTabsFilter.DRAFT,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Upcoming",
      [LanguageCode.PT_BR]: "Em breve",
    },
    value: TripsTabsFilter.UPCOMING,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Now",
      [LanguageCode.PT_BR]: "Agora",
    },
    value: TripsTabsFilter.NOW,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Past",
      [LanguageCode.PT_BR]: "Passadas",
    },
    value: TripsTabsFilter.PAST,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Canceled",
      [LanguageCode.PT_BR]: "Canceladas",
    },
    value: TripsTabsFilter.CANCELED,
  },
] as const;

export const TripsTabs: React.FC = () => {
  const { languageCode } = useLanguage();

  const { pendingFilters, search, userToken } = useTravelsFilters();
  const { tabFilter, handleChangeTabFilter } = useTravelsFiltersActions();
  const { travelsCount } = useTravelsList();
  const { fetchTravelsCount } = useTravelsListActions();

  const isCountReset = useMemo(
    () => Object.values(travelsCount).every((count) => count === 0),
    [travelsCount],
  );

  useEffect(() => {
    void fetchTravelsCount({
      pendingFilters,
      search,
      userToken,
    });
  }, [fetchTravelsCount, pendingFilters, search, userToken]);

  return (
    <nav css={styles.root}>
      <ul css={styles.tabs.root}>
        {tabs.map((tab, index) => (
          <li css={styles.tabs.tab.root} key={tab.value}>
            {index !== 0 ? <div css={styles.tabs.tab.divider} /> : null}
            <Button
              css={styles.tabs.tab.button}
              fill={tabFilter === tab.value ? "filled" : "outlined"}
              onClick={() => {
                handleChangeTabFilter(tab.value);
              }}
              variant={tabFilter === tab.value ? "pink" : "default"}
            >
              {tab.label[languageCode]}{" "}
              {isCountReset
                ? null
                : `(${travelsCount[tab.value]
                    .toLocaleString()
                    .replace(",", ".")})`}
            </Button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
