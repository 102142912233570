import React from "react";

import { useUser } from "~/apps/corporate/contexts/user.context";
import { formatDisplayedPhone } from "~/apps/shared/utils/format-displayed-phone";

import { useUserProfileDrawer } from "../../../user-profile-drawer.context";
import { useUserProfileDrawerBasicInformationSectionTranscription } from "./basic-information-section.transcription";
import { styles } from "./styles";

export const UserProfileDrawerBasicInformationSection: React.FC = () => {
  const {
    transcription,
  } = useUserProfileDrawerBasicInformationSectionTranscription();

  const { user } = useUser();

  const { editBasicInformation } = useUserProfileDrawer();

  if (!user) {
    return null;
  }

  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <span css={styles.header.title}>{transcription.title}</span>
        <button
          css={styles.header.button}
          onClick={() => {
            editBasicInformation();
          }}
        >
          {transcription.edit}
        </button>
      </div>
      <div css={styles.basic.info.root}>
        <div css={styles.basic.info.row.root}>
          <span css={styles.basic.info.row.label}>
            {transcription.labels.name}
          </span>
          <span css={styles.basic.info.row.value}>{user.getFullName()}</span>
        </div>
        <div css={styles.basic.info.row.root}>
          <span css={styles.basic.info.row.label}>
            {transcription.labels.email}
          </span>
          <span css={styles.basic.info.row.value}>{user.getEmail()}</span>
        </div>
        <div css={styles.basic.info.row.root}>
          <span css={styles.basic.info.row.label}>
            {transcription.labels.phone}
          </span>
          <span css={styles.basic.info.row.value}>
            {formatDisplayedPhone(user.getPhone())}
          </span>
        </div>
      </div>
    </div>
  );
};
