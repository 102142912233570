import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    cabinClass: {
      label: "Class:",
      placeholder: "Select the cabin class...",
    },
    destination: {
      empty: "No place found...",
      label: "Destination*",
      placeholder: "Type in the destination location...",
    },
    endDate: {
      placeholder: "Return*",
    },
    origin: {
      empty: "No place found...",
      label: "From*",
      placeholder: "Type in the origin location...",
    },
    oneway: "Oneway",
    roundtrip: "Roundtrip",
    searchFlights: "Search flights",
    startDate: {
      placeholder: "Departure*",
    },
  },
  [LanguageCode.PT_BR]: {
    cabinClass: {
      label: "Classe:",
      placeholder: "Selecione a categoria...",
    },
    destination: {
      empty: "Nenhum local encontrado...",
      label: "Destino*",
      placeholder: "Digite o local de origem...",
    },
    endDate: {
      placeholder: "Volta*",
    },
    oneway: "Só ida",
    origin: {
      empty: "Nenhum local encontrado...",
      label: "Origem*",
      placeholder: "Digite o local de origem...",
    },
    roundtrip: "Ida e volta",
    searchFlights: "Pesquisar voos",
    startDate: {
      placeholder: "Ida*",
    },
  },
} as const;

export const useFlightFormTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
