import * as Sentry from "@sentry/react";
import React, { createContext, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { navigate } from "@reach/router";
import userActions from "~/actions/user.action";
import { ALERT_TYPES } from "~/apps/shared/constants";
import { CurrencyCode, LanguageCode } from "~/apps/shared/constants/enums";
import { ERROR } from "~/apps/shared/constants/errors";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { logger } from "~/apps/shared/utils/logger";
import { sleep } from "~/apps/shared/utils/sleep";

import { SaveUserBossSchema } from "../components/user-profile-drawer/views/save-user-boss/save-user-boss.schema";
import { EditUserProfileDto, LoginDto } from "../dtos/user.dto";
import {
  clearUserFromLocalStorage,
  getUserFromLocalStorage,
} from "../helpers/user.helper";
import {
  UserFromAccessToken,
  UserModel,
  UserPreferences,
} from "../models/user.model";
import { useApplication } from "./application.context";
import * as userService from "./user.service";

interface Actions {
  editUserProfile: (data: EditUserProfileDto) => Promise<boolean>;
  fetchUser: (userToken: string) => Promise<UserModel | null>;
  login: (data: LoginDto) => Promise<Either<Error, UserModel>>;
  logout: () => Promise<void>;
  saveUserBoss: (data: SaveUserBossSchema) => Promise<boolean>;
  trackContextOfChoiceDialogView: () => Promise<void>;
  updateUserPreferences: (
    userPreferences: Partial<UserPreferences>,
  ) => Promise<UserPreferences | null>;
}

type State = {
  isLoading: boolean;
  isLoadingEditUserProfile: boolean;
  isLoadingLogin: boolean;
  isLoadingLogout: boolean;
  isLoadingUpdateUserPreferences: boolean;
  isLoggedIn: boolean;
  user: UserModel | null;
};

const initialState: State = {
  isLoading: false,
  isLoadingEditUserProfile: false,
  isLoadingLogin: false,
  isLoadingLogout: false,
  isLoadingUpdateUserPreferences: false,
  isLoggedIn: !!getUserFromLocalStorage()?.userToken,
  user: null,
};

type ContextProps = Actions & State;

export const UserContext = createContext<ContextProps>({
  ...initialState,
  editUserProfile: async () => {
    return false;
  },
  fetchUser: async () => {
    return null;
  },
  login: async () => {
    return failure(ERROR.UNEXPECTED);
  },
  logout: async () => {
    return;
  },
  saveUserBoss: async () => {
    return false;
  },
  trackContextOfChoiceDialogView: async () => {
    return;
  },
  updateUserPreferences: async () => {
    return null;
  },
});

type Props = {
  editUser: typeof userActions.editUser;
  logout: typeof userActions.logout;
  setUser: typeof userActions.setUser;
};

const Provider: React.FC<Props> = ({
  children,
  editUser: editUserAction,
  logout: logoutAction,
  setUser: setUserAction,
}) => {
  const { showSnackMessage } = useApplication();

  const [state, setState] = useState(initialState);

  const editUserProfile = useCallback(
    async (data: EditUserProfileDto) => {
      const { user } = state;

      if (!user) {
        logger.error("user not found");

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingEditUserProfile: true,
      }));

      const editUserProfileResponse = await userService.editUserProfile(
        data,
        user.getUserToken(),
      );

      if (editUserProfileResponse.isFailure()) {
        const error = editUserProfileResponse.data;

        setState((prev) => ({
          ...prev,
          isLoadingEditUserProfile: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingEditUserProfile: false,
        user: user.upsertUser(editUserProfileResponse.data),
      }));

      // TODO: remover no futuro
      editUserAction(editUserProfileResponse.data);

      return true;
    },
    [editUserAction, showSnackMessage, state.user],
  );

  const fetchUser = useCallback(
    async (userToken: string) => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
      }));

      const getUserResponse = await userService.getUser(userToken);

      if (getUserResponse.isFailure()) {
        const error = getUserResponse.data;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));

        return null;
      }

      let user = getUserResponse.data;

      const userFromLocalStorage = getUserFromLocalStorage();

      if (userFromLocalStorage) {
        const definedUserFromLocalStorage = Object.entries(userFromLocalStorage)
          .filter(([_, value]) => value !== undefined)
          .reduce(
            (prev, [key, value]) => ({
              ...prev,
              [key]: value,
            }),
            {} as UserFromAccessToken,
          );

        user = user.upsertUser(definedUserFromLocalStorage);
      }

      setState((prev) => ({
        ...prev,
        isLoading: false,
        isLoggedIn: true,
        user,
      }));

      // TODO: remover no futuro
      setUserAction({
        ...user.toObject(),
        loggedIn: true,
        loggingIn: false,
        loginError: {},
      });

      return user;
    },
    [setUserAction, showSnackMessage],
  );

  const login = useCallback(
    async (data: LoginDto): Promise<Either<Error, UserModel>> => {
      setState((prev) => ({
        ...prev,
        isLoadingLogin: true,
      }));

      const loginResponse = await userService.login(data);

      if (loginResponse.isFailure()) {
        const error = loginResponse.data;

        setState((prev) => ({
          ...prev,
          isLoadingLogin: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return failure(error);
      }

      const { userToken } = loginResponse.data;

      const getUserResponse = await userService.getUser(userToken);

      if (getUserResponse.isFailure()) {
        const error = getUserResponse.data;

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return failure(error);
      }

      let user = getUserResponse.data;

      const userFromLocalStorage = getUserFromLocalStorage();

      if (userFromLocalStorage) {
        user = user.upsertUser(userFromLocalStorage);
      }

      setState((prev) => ({
        ...prev,
        isLoadingLogin: false,
        isLoggedIn: true,
        user,
      }));

      // TODO: remover no futuro
      setUserAction({
        ...user.toObject(),
        loggedIn: true,
        loggingIn: false,
        loginError: {},
      });

      return success(user);
    },
    [setUserAction, showSnackMessage],
  );

  const logout = useCallback(async () => {
    setState((prev) => ({
      ...prev,
      isLoadingLogout: true,
    }));

    const logoutResponse = await userService.logout();

    if (logoutResponse.error) {
      const error = logoutResponse.error;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);
    }

    setState((prev) => ({
      ...prev,
      isLoadingLogout: false,
      isLoggedIn: false,
      user: null,
    }));

    Sentry.setUser(null);

    clearUserFromLocalStorage();

    navigate("/login");

    // TODO: remover no futuro
    logoutAction();
  }, [logoutAction, showSnackMessage, state.user]);

  const saveUserBoss = useCallback(
    async (data: SaveUserBossSchema) => {
      const { user } = state;

      if (!user) {
        logger.error("user not found");

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingSaveUserBoss: true,
      }));

      const editUserResponse = await userService.editUser({
        ...user.toObject(),
        ...data,
      });

      if (editUserResponse.isFailure()) {
        const error = editUserResponse.data;

        setState((prev) => ({
          ...prev,
          isLoadingSaveUserBoss: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return false;
      }

      setState((prev) => ({
        ...prev,
        isLoadingSaveUserBoss: false,
        user: user.upsertUser(editUserResponse.data),
      }));

      return true;
    },
    [showSnackMessage, state.user],
  );

  const trackContextOfChoiceDialogView = useCallback(async () => {
    const { user } = state;

    if (!user) {
      return;
    }

    await userService.trackContextOfChoiceDialogView(user.getUserToken());
  }, [state.user]);

  const updateUserPreferences = useCallback(
    async (userPreferences: Partial<UserPreferences>) => {
      const { user } = state;

      if (!user) {
        logger.error("user not found");

        return null;
      }

      setState((prev) => ({
        ...prev,
        isLoadingUpdateUserPreferences: true,
      }));

      const [updateUserPreferencesResponse] = await Promise.all([
        userService.updateUserPreferences(
          {
            currency: user.getCurrencyCode() || CurrencyCode.BRL,
            language: user.getLanguageCode() || LanguageCode.PT_BR,
            ...userPreferences,
          },
          user.getUserToken(),
        ),
        sleep(1000),
      ]);

      if (updateUserPreferencesResponse.isFailure()) {
        const error = updateUserPreferencesResponse.data;

        setState((prev) => ({
          ...prev,
          isLoadingUpdateUserPreferences: false,
        }));

        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return null;
      }

      setState((prev) => ({
        ...prev,
        isLoadingUpdateUserPreferences: false,
        user: user.upsertUser({
          userPreferences: updateUserPreferencesResponse.data,
        }),
      }));

      return updateUserPreferencesResponse.data;
    },
    [showSnackMessage, state.user],
  );

  useEffect(() => {
    const userFromLocalStorage = getUserFromLocalStorage();

    if (!userFromLocalStorage) {
      return;
    }

    const { userToken } = userFromLocalStorage;

    void fetchUser(userToken);
  }, [fetchUser]);

  return (
    <UserContext.Provider
      value={{
        ...state,
        editUserProfile,
        fetchUser,
        login,
        logout,
        saveUserBoss,
        trackContextOfChoiceDialogView,
        updateUserPreferences,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserProvider = connect(null, {
  editUser: userActions.editUser,
  logout: userActions.logout,
  setUser: userActions.setUser,
})(Provider);

export const useUser = useContextFactory("UserContext", UserContext);
