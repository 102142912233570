import moment from "moment";

export const days = Array(31)
  .fill(null)
  .map((_, index) => index + 1)
  .map((day) => ({
    label: day.toString(),
    value: day.toString(),
  }));

export const months = Array(12)
  .fill(null)
  .map((_, index) => index)
  .map((month) => ({
    label: moment().month(month).format("MMM"),
    value: (month + 1).toString(),
  }));

export const yearsForBithDate = Array(119)
  .fill(null)
  .map((_, index) => moment().add(-index, "years").format("YYYY"))
  .map((year) => ({
    label: year,
    value: year,
  }));

export const yearsForExpiration = Array(11)
  .fill(null)
  .map((_, index) => moment().add(index, "years").format("YYYY"))
  .map((year) => ({
    label: year,
    value: year,
  }));

export const yearsForIssue = Array(30)
  .fill(null)
  .map((_, index) => moment().add(-index, "years").format("YYYY"))
  .map((year) => ({
    label: year,
    value: year,
  }));
