import azulFareDetailsJson from "~/apps/shared/assets/airlines-fare-details/azul-fare-details.json";
import golFareDetailsJson from "~/apps/shared/assets/airlines-fare-details/gol-fare-details.json";
import latamFareDetailsJson from "~/apps/shared/assets/airlines-fare-details/latam-fare-details.json";
import { LanguageCode } from "~/apps/shared/constants/enums";
import { getAllCombinations } from "~/apps/shared/utils/get-all-combinations";

export const AIRLINE_WEB_SITES = {
  AD: {
    name: "Azul",
    link: "https://www.voeazul.com.br/br/pt/sobreazul/para-a-sua-viagem",
  },
  G3: {
    name: "Gol Linhas Aéreas",
    link: "https://www.voegol.com.br/tarifas",
  },
  LA: {
    name: "LATAM Airlines",
    link:
      "https://www.latamairlines.com/us/pt/central-ajuda/perguntas/compras/assistencia/tarifas-passagem-domestico",
  },
} as const;

export function buildFlightData(
  flightsSearch: any,
  travelToken: string | null = null,
) {
  const flight: any = {};

  if (travelToken) {
    flight.travel_token = travelToken;
  }

  flight.origin_locale = flightsSearch.origin.PlaceId.split("-")[0];
  flight.origin_name = flightsSearch.origin.label;
  flight.origin_city_id = flightsSearch.origin.CityId;
  flight.origin_country = flightsSearch.origin.CountryName;
  //flight.origin_continent = flightsSearch.origin.continent;

  flight.destination_locale = flightsSearch.destination.PlaceId.split("-")[0];
  flight.destination_name = flightsSearch.destination.label;
  flight.destination_city_id = flightsSearch.destination.CityId;
  flight.destination_country = flightsSearch.destination.CountryName;
  //flight.destination_continent = flightsSearch.destination.continent;

  flight.oneway = flightsSearch.type === "oneway";
  flight.cabin_class = flightsSearch.cabinClass;

  flight.outbound_flight_start = "00:01";
  flight.outbound_flight_end = "23:59";
  flight.outbound_flight_date = flightsSearch.startDate.format("YYYY-MM-DD");
  flight.outbound_flight_type = "departure";

  // type === 0 significa ida e volta
  if (flightsSearch.type === "roundtrip") {
    flight.inbound_flight_start = "00:01";
    flight.inbound_flight_end = "23:59";
    flight.inbound_flight_date = flightsSearch.endDate.format("YYYY-MM-DD");
    flight.inbound_flight_type = "departure";
  }

  return flight;
}

export const extractTextFromFareFamilyDetailsInJson = (
  fareFamilyDetailsInJson: any,
  key: string,
  languageCode: LanguageCode,
) => {
  const object = fareFamilyDetailsInJson[key];

  if (!object) {
    return "";
  }

  return typeof fareFamilyDetailsInJson[key] === "string"
    ? object
    : object[languageCode];
};

function findMaxValueCombinationLowerThanPrice(
  allCombinations: any[],
  price: number,
) {
  function getCombinationSum(combination: any[]) {
    const sum = combination.reduce((acc, current) => {
      acc += current.value;
      return acc;
    }, 0);

    return sum;
  }

  const result = { index: -1, sum: 0 };
  allCombinations.forEach((combination, index) => {
    const sum = getCombinationSum(combination);
    if (sum <= price && sum > result.sum) {
      result.index = index;
      result.sum = sum;
    }
  });

  return result;
}

export const getAirlineWebSite = (airlineCode: string) => {
  if (airlineCode in AIRLINE_WEB_SITES) {
    return AIRLINE_WEB_SITES[airlineCode as keyof typeof AIRLINE_WEB_SITES];
  }

  return null;
};

export const getAllFareDetailsMappedByCarrierCode = (
  flightIsInternational: boolean,
) => {
  const latamFareDetails = latamFareDetailsJson;
  const golFareDetails = golFareDetailsJson;
  const azulFareDetails = azulFareDetailsJson;

  const keyToAccessInJson = flightIsInternational
    ? "internationalFares"
    : "nationalFares";

  // ! implementação temporária até confirmarmos as tarifas para voos internacionais.
  if (flightIsInternational) {
    return {};
  }

  const fareDetailsMap = {
    [latamFareDetails.code]: mapFareDetailsByFamilyName(
      latamFareDetails[keyToAccessInJson],
    ),
    [golFareDetails.code]: mapFareDetailsByFamilyName(
      golFareDetails[keyToAccessInJson],
    ),
    [azulFareDetails.code]: mapFareDetailsByFamilyName(
      azulFareDetails[keyToAccessInJson],
    ),
  };

  return fareDetailsMap;
};

export function getMaxCreditCombination(credits: any[], offerPrice: number) {
  let result;
  const creditsSum = credits.reduce((acc, current) => {
    acc += current.value;
    return acc;
  }, 0);

  if (creditsSum <= offerPrice) {
    result = credits;
  } else if (credits.length === 1) {
    result = [];
  } else {
    const allCombinations = getAllCombinations(credits);
    const maxCombinationIndexResult = findMaxValueCombinationLowerThanPrice(
      allCombinations,
      offerPrice,
    );
    result =
      maxCombinationIndexResult.index === -1
        ? []
        : allCombinations[maxCombinationIndexResult.index];
  }

  return result;
}

const mapFareDetailsByFamilyName = (fares: any[]) => {
  return fares.reduce((acc, curr) => {
    acc[curr.name.trim().toLocaleLowerCase()] = curr;
    return acc;
  }, {});
};
