import {
  CreateDocumentDto,
  CreateDocumentRequestBody,
  CreateDocumentResponse,
  EditDocumentDto,
  EditDocumentRequestBody,
  EditDocumentResponse,
  GetDocumentResponse,
  GetUserDocumentsResponse,
} from "../dtos/documents.dto";
import { Document } from "../models/documents.model";
import { removeSpecialCharacters } from "../utils/remove-special-characters";

export const parseCreateDocumentRequestBody = (dto: CreateDocumentDto) => {
  const createDocumentRequestBody: CreateDocumentRequestBody = {
    birth_date: dto.birthDate || undefined,
    document_number: removeSpecialCharacters(dto.documentNumber),
    expiration_date: dto.expirationDate || undefined,
    first_name: dto.firstName,
    issue_date: dto.issueDate || undefined,
    issuing_country: dto.issuingCountry || undefined,
    last_name: dto.lastName,
    nationality: dto.nationality || undefined,
    residence_country: dto.residenceCountry || undefined,
    sex: dto.sex || undefined,
    type: dto.type,
    user_token: dto.userToken,
  };

  return createDocumentRequestBody;
};

export const parseCreateDocumentResponse = (dto: CreateDocumentResponse) => {
  const document: Document = {
    birthDate: dto.birth_date,
    createdAt: dto.created_at,
    documentNumber: dto.document_number,
    documentToken: dto.document_token,
    expirationDate: dto.expiration_date,
    firstName: dto.first_name,
    issueDate: dto.issue_date,
    issuingCountry: dto.issuing_country,
    lastName: dto.last_name,
    nationality: dto.nationality,
    residenceCountry: dto.residence_country,
    sex: dto.sex,
    type: dto.type,
    updatedAt: dto.updated_at,
    userToken: dto.user_token,
  };

  return document;
};
export const parseDocumentResponse = (dto: GetDocumentResponse) => {
  const document: Document = {
    birthDate: dto.birth_date,
    createdAt: dto.created_at,
    documentNumber: dto.document_number,
    documentToken: dto.document_token,
    expirationDate: dto.expiration_date,
    firstName: dto.first_name,
    issueDate: dto.issue_date,
    issuingCountry: dto.issuing_country,
    lastName: dto.last_name,
    nationality: dto.nationality,
    residenceCountry: dto.residence_country,
    sex: dto.sex,
    type: dto.type,
    updatedAt: dto.updated_at,
    userToken: dto.user_token,
  };

  return document;
};

export const parseEditDocumentRequestBody = (dto: EditDocumentDto) => {
  const editDocumentRequestBody: EditDocumentRequestBody = {
    birth_date: dto.birthDate || undefined,
    document_number: removeSpecialCharacters(dto.documentNumber),
    ...(dto.expirationDate && {
      expiration_date: dto.expirationDate,
    }),
    first_name: dto.firstName,
    ...(dto.issueDate && {
      issue_date: dto.issueDate,
    }),
    issuing_country: dto.issuingCountry || undefined,
    last_name: dto.lastName,
    nationality: dto.nationality || undefined,
    ...(dto.residenceCountry && {
      residence_country: dto.residenceCountry,
    }),
    sex: dto.sex || undefined,
    type: dto.type,
    user_token: dto.userToken,
  };

  return editDocumentRequestBody;
};

export const parseEditDocumentResponse = (dto: EditDocumentResponse) => {
  const document: Document = {
    birthDate: dto.birth_date,
    createdAt: dto.created_at,
    documentNumber: dto.document_number,
    documentToken: dto.document_token,
    expirationDate: dto.expiration_date,
    firstName: dto.first_name,
    issueDate: dto.issue_date,
    issuingCountry: dto.issuing_country,
    lastName: dto.last_name,
    nationality: dto.nationality,
    residenceCountry: dto.residence_country,
    sex: dto.sex,
    type: dto.type,
    updatedAt: dto.updated_at,
    userToken: dto.user_token,
  };

  return document;
};

export const parseUserDocumentsResponse = (dto: GetUserDocumentsResponse) => {
  return dto.map((userDocument) => {
    const document: Document = {
      birthDate: userDocument.birth_date,
      createdAt: userDocument.created_at,
      documentNumber: userDocument.document_number,
      documentToken: userDocument.document_token,
      expirationDate: userDocument.expiration_date,
      firstName: userDocument.first_name,
      issueDate: userDocument.issue_date,
      issuingCountry: userDocument.issuing_country,
      lastName: userDocument.last_name,
      nationality: userDocument.nationality,
      residenceCountry: userDocument.residence_country,
      sex: userDocument.sex,
      type: userDocument.type,
      updatedAt: userDocument.updated_at,
      userToken: userDocument.user_token,
    };

    return document;
  });
};
