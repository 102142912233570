import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  autocomplete: {
    input: {
      clear: css({
        backgroundColor: "transparent",
        border: "none",
        color: theme.colors.gray[300],
        cursor: "pointer",
        outline: "none",
      }),
      icon: css({
        color: theme.colors.gray[300],
      }),
      input: css({}),
      loading: css({
        color: theme.colors.gray[300],
        padding: "1px",
      }),
      root: css({
        alignItems: "center",
        border: `1px solid ${theme.colors.gray[100]}`,
        borderRadius: "8px",
        backgroundColor: "transparent",
        display: "flex",
        height: "50px",
        gap: "8px",
        outline: "none",
        overflow: "hidden",
        padding: "0 16px",
        width: "fit-content",
      }),
    },
    item: {
      email: css({
        color: theme.colors.gray[300],
        fontSize: "0.75rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }),
      root: ({
        isDisabled,
        isHighlighted,
      }: {
        isDisabled?: boolean;
        isHighlighted: boolean;
      }) =>
        css({
          backgroundColor: isHighlighted
            ? theme.colors.background.gray
            : "transparent",
          color: theme.colors.gray[500],
          cursor: isDisabled ? "default" : "pointer",
          display: "flex",
          flexDirection: "column",
          fontSize: "0.875rem",
          gap: "4px",
          padding: "12px 16px",
        }),
    },
    root: css({
      backgroundColor: theme.colors.background.white,
      borderRadius: "8px",
      width: "100%",
    }),
  },
  root: css({
    flexShrink: 0,
  }),
};
