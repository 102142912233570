import {
  TravelIntegrationOption,
  ClientBookingPhoneConfig,
  UserBookingPhoneConfig,
} from "~/apps/shared/constants/enums";
import { SuccessResponse } from "~/apps/shared/types";
import { ConfigOptions } from "sm-types/common";

import { ApprovalConfig, GuestApprovalType } from "../models/client.model";
import { CustomFieldOptionsType } from "./custom-field-options.dto";

export type DownloadExternalApiDocResponse = {
  fileName: string;
  url: string;
};

export type GetClientBossesResponse = {
  boss_email: string;
  boss_token: string;
  employee_token: string;
};

export enum PreRequest {
  MANDATORY = "MANDATORY",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export enum PostRequest {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export type ApprovalPaySelection = {
  pre_request: PreRequest;
  post_request: PostRequest;
};

export type FeatureVisibilities = {
  analytics: boolean;
  events: boolean;
  expenses: boolean;
  search: { bus: boolean; car: boolean; hotel: boolean; flight: boolean };
};

export type GetClientConfigResponse = {
  approval_config: ApprovalConfig;
  approval_ranking: string;
  bank3_enabled?: boolean;
  booking_phone_config?: ClientBookingPhoneConfig;
  budgets_active: boolean;
  client_config_token: string;
  company_area_display: string;
  company_area_enabled: boolean;
  company_board_display: string;
  config: ConfigOptions;
  cost_center_active: boolean;
  custom_corporative_card_name: string;
  custom_message_link?: string;
  custom_message?: string;
  guest_approval_type: GuestApprovalType;
  project_enabling_state: "off" | "optional" | "required";
  support_channel: string;
  tags_admin_only: boolean;
  travel_integration: TravelIntegrationOption;
  travel_tags_required: boolean;
  approval_pay_selection: ApprovalPaySelection;
  feature_visibilities: FeatureVisibilities;
};

export type GetClientInfoResponse = {
  active: boolean;
  booking_fee_option: string;
  budgets_active: boolean;
  client_slug: string;
  client_token: string;
  cost_center_active: boolean;
  created_at: string;
  email: string;
  id: string;
  name: string;
};

enum GetClientPurposeFieldOptionsResponseVisibility {
  NONE = "NONE",
  OPTIONAL = "OPTIONAL",
  REQUIRED = "REQUIRED",
}

export type GetClientPurposeFieldOptionsResponse = {
  data: {
    label: string;
    options: {
      name: string;
      option_token: string;
    };
    travel_custom_field: CustomFieldOptionsType;
    type: string;
    visibility: GetClientPurposeFieldOptionsResponseVisibility;
  }[];
  status: string;
};

export type GetUsersRequestParams = {
  billingProfileToken?: string;
  search?: string;
  visibility?: "active" | "inactive";
};

export type GetUsersResponseClientUserDto = {
  active: boolean;
  agency_token: string;
  billing_profile_token: string | null;
  booking_phone_config?: UserBookingPhoneConfig;
  client_token: string;
  created_at: string;
  email: string;
  first_name: string;
  full_name: string;
  guest: boolean;
  last_login: string | null;
  last_name: string;
  phone: string;
  role: string;
  send_sms: boolean;
  user_token: string;
};

export type GetUsersResponse = GetUsersResponseClientUserDto[];

export type GetUsersSimplifiedResponse = {
  active: boolean;
  billing_profile_token: string | null;
  email: string;
  first_name: string;
  full_name: string;
  guest?: boolean | null;
  last_name: string;
  role?: string;
  user_token: string;
}[];

export interface IGetClientExternalCredentialsResponse {
  clientId: string;
  clientSecret: string;
}

export interface RemoveClientExternalCredentialsResponse {
  data: SuccessResponse;
  status: string;
}

export interface ResetClientExternalCredentialsResponse {
  data: SuccessResponse;
  status: string;
}

export interface UpdateClientRequest {
  budgets_active?: boolean;
  client_slug?: string;
  cost_center_active?: boolean;
  email?: string;
  name?: string;
}
