import React from "react";

import { UserTravelOffer } from "~/apps/corporate/models/travel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { CardOfferStatus } from "../card-offer-status/card-offer-status";
import { CardOfferTags } from "../card-offer-tags/card-offer-tags";
import { useTripCardFlightOfferTranscription } from "./card-flight-offer.transcription";
import { styles } from "./styles";

type Props = {
  offer: UserTravelOffer;
};

export const TripCardFlightOffer: React.FC<Props> = ({ offer }) => {
  const { transcription } = useTripCardFlightOfferTranscription();

  const { date, flight, status, tagStatus } = offer;

  if (!flight) {
    return null;
  }

  const { destination, oneway, origin } = flight;

  const formattedEndDate = moment(date.end).format("DD MMM YYYY");
  const formattedStartDate = moment(date.initial).format("DD MMM YYYY");

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <Icon css={styles.left.icon} size={16} use="airplane" />
        <div css={styles.left.right.root}>
          <div css={styles.left.right.top.root}>
            <span>
              {oneway ? transcription.oneWay : transcription.roundTrip}{" "}
              {transcription.from} <strong>{origin.code}</strong>{" "}
              <span css={styles.left.right.top.city}>{origin.city}</span>{" "}
              <Icon
                css={styles.left.right.top.icon}
                size={16}
                use="arrow-right"
              />{" "}
              <strong>{destination.code}</strong>{" "}
              <span css={styles.left.right.top.city}>{destination.city}</span>
            </span>
          </div>
          <div css={styles.left.right.bottom.root}>
            <CardOfferStatus status={status} />
            <span css={styles.left.right.bottom.date}>
              {oneway
                ? formattedStartDate
                : `${formattedStartDate} - ${formattedEndDate}`}
            </span>
          </div>
        </div>
      </div>
      <CardOfferTags tags={tagStatus} />
    </div>
  );
};
