import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    date: "Date",
    delete: "Delete",
    empty: "No offers added yet...",
    goToTrip: "Go to Trip",
    idLabel: "ID:",
    noOfferAdded: "No offers added yet...",
    traveler: "Traveler",
  },
  [LanguageCode.PT_BR]: {
    date: "Data",
    delete: "Excluir",
    empty: "Nenhuma oferta adicionada ainda...",
    goToTrip: "Ir para a viagem",
    idLabel: "ID:",
    noOfferAdded: "Nenhuma oferta adicionada ainda...",
    traveler: "Viajante",
  },
} as const;

export const useTripCardTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
