import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      confirm: "Remove",
    },
    fallbackMessage: "Do you really want to remove the loyalty program?",
    message: (loyaltyProgramName: string | null) =>
      `Do you really want to remove the loyalty program${
        loyaltyProgramName ? ` ${loyaltyProgramName}` : ""
      }?`,
    title: "Remove Loyalty Program",
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      confirm: "Remover",
    },
    fallbackMessage: "Deseja realmente remover o programa de fidelidade?",
    message: (loyaltyProgramName: string | null) =>
      `Deseja realmente remover o programa de fidelidade${
        loyaltyProgramName ? ` ${loyaltyProgramName}` : ""
      }?`,
    title: "Remover Programa de Fidelidade",
  },
} as const;

export const useRemoveLoyaltyProgramAlertDialogTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
