import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    logout: "Logout",
    profile: "My profile",
    reviews: "My reviews",
  },
  [LanguageCode.PT_BR]: {
    logout: "Sair",
    profile: "Meu perfil",
    reviews: "Minhas avaliações",
  },
} as const;

export const useNavBarDesktopProfileMenuTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
