import React from "react";

import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { Icon } from "~/apps/shared/components/icon/icon";
import { Input } from "~/apps/shared/components/input/input";

import { CircularSpinner } from "@toolkit/v2";

import { useTravelsFilters, useTravelsFiltersActions } from "../trips.context";
import * as tripsService from "../trips.service";
import { styles } from "./styles";
import { useTripsTravelerFilterTranscription } from "./trips-traveler-filter.transcription";

export const TripsTravelerFilter: React.FC = () => {
  const { transcription } = useTripsTravelerFilterTranscription();

  const { userToken } = useTravelsFilters();
  const { handleChangeUserFilter } = useTravelsFiltersActions();

  return (
    <div css={styles.root}>
      <AsyncAutocomplete
        css={styles.autocomplete.root}
        fetchItems={async (search) => {
          const getUsersResponse = await tripsService.getUsers({
            search,
          });

          if (getUsersResponse.isFailure()) {
            const error = getUsersResponse.data;

            return {
              error,
            };
          }

          const users = getUsersResponse.data
            .filter(
              (user) =>
                !!user.email &&
                !!user.firstName &&
                !!user.lastName &&
                !!user.fullName &&
                !!user.userToken,
            )
            .map((user) => ({
              ...user,
              label: user.fullName,
            }));

          return {
            data: users,
          };
        }}
        filterItems={(items) =>
          items.filter((item) => item.userToken !== userToken)
        }
        inputProps={{
          placeholder: transcription.placeholder,
        }}
        onClear={() => {
          handleChangeUserFilter("");
        }}
        onSelect={(item) => {
          handleChangeUserFilter(item.userToken);
        }}
        renderItem={({ isHighlighted, item, ...props }) => {
          if (item.error) {
            return (
              <li
                css={styles.autocomplete.item.root({
                  isDisabled: true,
                  isHighlighted: false,
                })}
                key={0}
                {...props}
              >
                {transcription.noResults}
              </li>
            );
          }

          return (
            <li
              css={styles.autocomplete.item.root({ isHighlighted })}
              key={JSON.stringify(item)}
              {...props}
            >
              <span>{item.label}</span>
              <span css={styles.autocomplete.item.email}>{item.email}</span>
            </li>
          );
        }}
        renderInput={({ loading, ...props }) => (
          <div css={styles.autocomplete.input.root}>
            {loading ? (
              <CircularSpinner
                css={styles.autocomplete.input.loading}
                size={18}
              />
            ) : (
              <Icon
                css={styles.autocomplete.input.icon}
                size={18}
                use="person"
              />
            )}
            <Input css={styles.autocomplete.input.input} {...props} />
          </div>
        )}
      />
    </div>
  );
};
