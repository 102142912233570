import React, { useCallback, useRef, useState } from "react";

import { Link } from "@reach/router";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import { Icon } from "~/apps/shared/components/icon/icon";
import {
  capabilities,
  CapabilitiesLiterals,
} from "~/apps/shared/constants/enums";
import { useLocation } from "~/apps/shared/hooks/use-location";
import { useOnClickOutside } from "~/apps/shared/hooks/use-on-click-outside";

import { useNavBarDesktopOthersMenuTranscription } from "./others-menu.transcription";
import { styles } from "./styles";

export const NavBarDesktopOthersMenu: React.VFC<
  React.HTMLAttributes<HTMLButtonElement>
> = ({ ...props }) => {
  const { transcription } = useNavBarDesktopOthersMenuTranscription();

  const {
    location: { pathname },
  } = useLocation();

  const { user } = useUser();
  const { clientConfig } = useClientConfig();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const handleOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const links: {
    capabilities?: CapabilitiesLiterals[];
    isVisible: boolean;
    label: string;
    links: {
      capabilities?: CapabilitiesLiterals[];
      isVisible: boolean;
      label: string;
      to: string;
    }[];
    to: string;
  }[] = [
    {
      capabilities: [capabilities.EXPENSES],
      label: transcription.expenses.label,
      isVisible:
        clientConfig?.isExpensesVisible() &&
        user?.hasCapability(capabilities.EXPENSES)
          ? true
          : false,
      links: [
        {
          label: transcription.expenses.links.expenses,
          to: "/expenses",
          isVisible: true,
        },
        {
          label: transcription.expenses.links.reports,
          to: "/expenses/reports",
          isVisible: true,
        },
        {
          label: transcription.expenses.links.advances,
          to: "/expenses/advanced",
          isVisible: true,
        },
        {
          capabilities: [capabilities.EXPENSE_FOR_OTHERS],
          label: transcription.expenses.links.others,
          to: "/expenses/others-reports",
          isVisible: true,
        },
      ],
      to: "/expenses",
    },
    {
      label: transcription.events.label,
      isVisible: clientConfig?.isEventsVisible() ?? false,
      links: [
        {
          label: transcription.events.links.nextEvents,
          to: "/events",
          isVisible: true,
        },
        {
          label: transcription.events.links.ongoingEvents,
          to: "/events",
          isVisible: true,
        },
        {
          label: transcription.events.links.pastEvents,
          to: "/events",
          isVisible: true,
        },
      ],
      to: "/events",
    },
    {
      capabilities: [capabilities.ANALYTICS, capabilities.FINANCIAL],
      label: transcription.analytics.label,
      isVisible: clientConfig?.isAnalyticsVisible() ?? false,
      links: [
        {
          capabilities: [capabilities.ANALYTICS],
          label: transcription.analytics.links.dataAnalytics,
          to: "/analytics",
          isVisible: true,
        },
        {
          capabilities: [capabilities.ANALYTICS],
          label: transcription.analytics.links.reportDownload,
          to: "/analytics/report-download",
          isVisible: true,
        },
        {
          label: transcription.analytics.links.realTimeTravels,
          to: "/analytics/real-time",
          isVisible: true,
        },
        {
          capabilities: [capabilities.FINANCIAL],
          label: transcription.analytics.links.financialControl,
          to: "/financial",
          isVisible: true,
        },
      ],
      to: "/analytics",
    },
    {
      capabilities: [
        capabilities.CONFIGURATIONS,
        capabilities.EXTERNAL_CREDENTIALS,
      ],
      label: transcription.configurations.label,
      isVisible: true,
      links: [
        {
          label: transcription.configurations.links.people,
          to: "/configurations/users",
          isVisible: true,
        },
        {
          label: transcription.configurations.links.tripRules,
          to: "/configurations/trips",
          isVisible: true,
        },
        {
          label: transcription.configurations.links.companyOrganization,
          to: "/configurations/company/cost-centers",
          isVisible: true,
        },
        {
          label: transcription.configurations.links.expenseRules,
          to: "/configurations/expenses",
          isVisible:
            clientConfig?.isExpensesVisible() &&
            user?.hasCapability(capabilities.EXPENSES)
              ? true
              : false,
        },
        {
          label: transcription.configurations.links.accountSettings,
          to: "/configurations/financial",
          isVisible: true,
        },
        {
          label: transcription.configurations.links.integrations,
          to: "/configurations/integrations",
          isVisible: true,
        },
        {
          label: transcription.configurations.links.featureControl,
          to: "/configurations/functionality-control/visibility-options",
          isVisible: true,
        },
      ],
      to:
        user && user.hasConfigurationsCapability()
          ? "/configurations/users"
          : "/configurations/developers",
    },
  ];

  const isActive = links.some((link) => {
    if (pathname === "/") {
      return false;
    }

    if (link.to.startsWith(pathname)) {
      return true;
    }

    return link.links.some((link) => {
      return link.to.startsWith(pathname);
    });
  });

  const othersMenuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = useCallback(() => {
    if (isMenuOpen) {
      handleCloseMenu();

      return;
    }

    handleOpenMenu();
  }, [handleCloseMenu, handleOpenMenu, isMenuOpen]);

  useOnClickOutside(handleCloseMenu, othersMenuRef);

  return (
    <>
      <div css={styles.menu.overlay({ open: isMenuOpen })} />
      <div
        onMouseEnter={handleOpenMenu}
        onMouseLeave={handleCloseMenu}
        ref={othersMenuRef}
      >
        <button
          css={styles.button.root({
            active: isActive || isMenuOpen,
          })}
          onClick={toggleMenu}
          {...props}
        >
          <span>{transcription.title}</span>
          <Icon css={styles.button.icon} size={16} use="chevron-down" />
        </button>
        <div
          css={styles.menu.root({
            open: isMenuOpen,
          })}
        >
          <div css={styles.menu.container}>
            {links.map((link) =>
              link.isVisible ? (
                link.capabilities ? (
                  <CapabilityProtectedRender
                    atLeastOne
                    capabilities={link.capabilities}
                    key={link.label}
                  >
                    <div css={styles.menu.section.root}>
                      <Link
                        css={styles.menu.section.label}
                        onClick={handleCloseMenu}
                        tabIndex={isMenuOpen ? 0 : -1}
                        to={link.to}
                      >
                        {link.label}
                      </Link>
                      <nav>
                        <ul css={styles.menu.links.root}>
                          {link.links.map((l) => {
                            if (!l.isVisible) {
                              return null;
                            }

                            return (
                              <li key={l.label}>
                                <Link
                                  css={styles.menu.links.link}
                                  onClick={handleCloseMenu}
                                  tabIndex={isMenuOpen ? 0 : -1}
                                  to={l.to}
                                >
                                  {l.label}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </nav>
                    </div>
                  </CapabilityProtectedRender>
                ) : (
                  <div css={styles.menu.section.root} key={link.label}>
                    <Link
                      css={styles.menu.section.label}
                      onClick={handleCloseMenu}
                      tabIndex={isMenuOpen ? 0 : -1}
                      to={link.to}
                    >
                      {link.label}
                    </Link>
                    <nav>
                      <ul css={styles.menu.links.root}>
                        {link.links.map((l) => {
                          if (!l.isVisible) {
                            return null;
                          }

                          return (
                            <li key={l.label}>
                              <Link
                                css={styles.menu.links.link}
                                onClick={handleCloseMenu}
                                tabIndex={isMenuOpen ? 0 : -1}
                                to={l.to}
                              >
                                {l.label}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </nav>
                  </div>
                )
              ) : null,
            )}
          </div>
        </div>
      </div>
    </>
  );
};
