import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    placeholder: "Filter by traveler...",
    noResults: "No results.",
  },
  [LanguageCode.PT_BR]: {
    placeholder: "Filtre por viajante...",
    noResults: "Nenhum resultado.",
  },
} as const;

export const useTripsTravelerFilterTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
