import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    currency: "Currency:",
    edit: "Edit",
    language: "Language:",
    title: "Preferences",
  },
  [LanguageCode.PT_BR]: {
    currency: "Moeda:",
    edit: "Editar",
    language: "Idioma:",
    title: "Preferências",
  },
} as const;

export const useUserProfileDrawerUserPreferencesSectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
