import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    empty: "No cards registered.",
    link: "Add payment method",
    menu: {
      edit: "Edit",
      remove: "Remove",
    },
    title: "Payment methods",
  },
  [LanguageCode.PT_BR]: {
    empty: "Nenhum cartão cadastrado.",
    link: "Adicionar meio de pagamento",
    menu: {
      edit: "Editar",
      remove: "Remover",
    },
    title: "Meios de pagamento",
  },
} as const;

export const useUserProfileDrawerCardsSectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
