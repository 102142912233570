import React from "react";

import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { OfferStatus } from "~/apps/shared/constants/enums";
import {
  OFFER_STATUS_COLOR,
  OFFER_STATUS_TRANSCRIPTION,
} from "~/apps/shared/constants/offers.constants";

import { styles } from "./styles";

type Props = {
  status: OfferStatus;
};

export const CardOfferStatus: React.FC<Props> = ({ status }) => {
  const { languageCode } = useLanguage();

  return (
    <span css={styles.root({ color: OFFER_STATUS_COLOR[status] })}>
      {OFFER_STATUS_TRANSCRIPTION[status][languageCode].toLocaleUpperCase()}
    </span>
  );
};
