import { APPROVAL_REVIEW_MESSAGES } from "~/apps/shared/constants";
import { LanguageCode } from "~/apps/shared/constants/enums";

import { BookingTarget } from "../models/booking-target.model";
import { ItineraryServiceModel } from "../models/itinerary/itinerary-service.model";
import { ItineraryServicePresenterFactory } from "../models/itinerary/itinerary-service.presenter";
import { UserModel } from "../models/user.model";

export const buildTravelData = (
  bookingTarget: BookingTarget,
  user: UserModel,
) => {
  const userToken = user.getUserToken();

  const travelData = {
    booker_token: userToken,
    travel_name: "",
    traveler_token: bookingTarget.userToken,
  };

  return travelData;
};

export const getNoAvailabilityDenyMessage = (
  notAvailableServices: ItineraryServiceModel[],
  languageCode?: LanguageCode,
) => {
  const noAvailabilityDenyMessage = notAvailableServices.reduce(
    (prev, curr) => {
      const servicePresenter = ItineraryServicePresenterFactory.create(curr);

      prev.push(
        `\n ${servicePresenter.getFormattedItineraryServiceHeaderName(
          languageCode,
        )};`,
      );

      return prev;
    },
    [APPROVAL_REVIEW_MESSAGES.NO_AVAILABILITY_DECLINE] as string[],
  );

  return noAvailabilityDenyMessage.join("");
};
