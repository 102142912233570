import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    header: "Refer and earn",
    body: {
      title: "Refer and earn up to R$ 10,000.00 in travel",
      referral: {
        label: "Share the link with colleagues from other companies:",
        button: {
          generating: "Generating...",
          generate: "Generate referral link",
        },
      },
      steps: {
        step1: "Share the link with colleagues from other companies.",
        step2: "Referred company becomes a Smartrips customer.",
        step3: "You earn up to R$ 10,000.00 to spend on personal travel.",
      },
      explanation: {
        title: "How to earn?",
        paragraph: [
          "- The Smartrips team, upon receiving the first contact from the referred company, will conduct an initial evaluation.",
          "- With the client signed, the referred company must travel the minimum amount of R$10,000.00 in the first month after the contract starts.",
          "- With the goal achieved, you earn 5% (maximum of R$ 10,000.00) of the client's spending in the first month.",
        ] as string[],
        info: "The more companies you refer, the more chances to earn!",
        learnMore: "Learn more",
      },
      important: {
        title: "Important:",
        items: [
          "- The referral is only valid for companies.",
          "- The referral is only valid if the company uses your generated link.",
          "- The company must pass our security criteria.",
        ] as string[],
      },
      termsAndConditions: "Terms and conditions",
    },
  },
  [LanguageCode.PT_BR]: {
    header: "Indique e ganhe",
    body: {
      title: "Indique e ganhe até R$ 10.000,00 em viagens",
      referral: {
        label: "Compartilhe o link com colegas de outras empresas:",
        button: {
          generating: "Gerando...",
          generate: "Gerar link de indicação",
        },
      },
      steps: {
        step1: "Compartilhe o link com colegas de outras empresas.",
        step2: "Empresa indicada vira cliente Smartrips.",
        step3: "Você ganha até R$ 10.000,00 para gastar em viagens pessoais.",
      },
      explanation: {
        title: "Como ganhar?",
        paragraph: [
          "- O time Smartrips, assim que receber o primeiro contato pela empresa indicada, fará a avaliação inicial.",
          "- Com o cliente fechado, a empresa indicada deve viajar o valor mínimo de R$10.000,00 no primeiro mês após o início do contrato.",
          "- Com a meta atingida, você ganha 5% (máximo de R$ 10.000,00) do valor gasto do cliente no primeiro mês.",
        ] as string[],
        info: "Quanto mais empresas indicar, mais chances de ganhar!",
        learnMore: "Saiba mais",
      },
      important: {
        title: "Importante:",
        items: [
          "- A indicação só é válida para empresas.",
          "- A indicação só é válida se a empresa utilizar seu link gerado.",
          "- A empresa deve passar em nossos critérios de segurança.",
        ] as string[],
      },
      termsAndConditions: "Termos e condições",
    },
  },
} as const;

export const useMemberGetMemberDrawerTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
