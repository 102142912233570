import React, { useCallback, useEffect } from "react";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";

import { useUserProfileDrawer } from "../user-profile-drawer.context";
import { useRemovePaymentMethodAlertDialogTranscription } from "./remove-payment-method-alert-dialog.transcription";

export const RemovePaymentMethodAlertDialog: React.FC = () => {
  const queryClient = useQueryClient();

  const {
    deleteCard,
    errorOnFetchDeleteCard,
    isLoadingDeleteCard,
  } = useCards();

  const {
    closeRemovePaymentMethodAlertDialog,
    isRemovePaymentMethodAlertDialogOpen,
    selectedPaymentMethod,
  } = useUserProfileDrawer();

  const { transcription } = useRemovePaymentMethodAlertDialogTranscription();

  const handleClose = useCallback(() => {
    closeRemovePaymentMethodAlertDialog();
  }, [closeRemovePaymentMethodAlertDialog]);

  const handleConfirm = useCallback(async () => {
    if (!selectedPaymentMethod) {
      closeRemovePaymentMethodAlertDialog();
      return;
    }

    await deleteCard(selectedPaymentMethod.token);

    void invalidateBillingProfilePayableCardsForTravel(queryClient);

    closeRemovePaymentMethodAlertDialog();
  }, [
    closeRemovePaymentMethodAlertDialog,
    deleteCard,
    invalidateBillingProfilePayableCardsForTravel,
    selectedPaymentMethod,
  ]);

  useEffect(() => {
    if (!errorOnFetchDeleteCard) {
      return;
    }
    handleClose();
  }, [errorOnFetchDeleteCard, handleClose]);

  return (
    <AlertDialog
      confirmLabel={transcription.buttons.confirm}
      isLoading={isLoadingDeleteCard}
      message={transcription.message(
        selectedPaymentMethod ? selectedPaymentMethod.description : "",
      )}
      onClose={handleClose}
      onConfirm={handleConfirm}
      open={isRemovePaymentMethodAlertDialogOpen}
      title={transcription.title}
    />
  );
};
