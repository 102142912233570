import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      cancel: "Cancel",
      save: "Save",
    },
    fields: {
      code: {
        label: "Code*",
        placeholder: "Enter code...",
      },
      program: {
        label: "Program*",
        placeholder: "Choose a loyalty program...",
      },
    },
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      cancel: "Cancelar",
      save: "Salvar",
    },
    fields: {
      code: {
        label: "Código*",
        placeholder: "Digite o código...",
      },
      program: {
        label: "Programa*",
        placeholder: "Escolha um programa de fidelidade...",
      },
    },
  },
} as const;

export const useUserProfileDrawerSaveLoyaltyProgramTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
