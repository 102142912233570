import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  Suggestion,
} from "react-places-autocomplete";

import { extractDetails } from "~/apps/shared/helpers/google.helper";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

import { CircularSpinner } from "@toolkit/v2";

import { styles } from "./styles";

type Props = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onSelect" | "value"
> & {
  icon?: React.ReactNode;
  locationComponentRestrictions?: any;
  locationType?: LocationType | Array<LocationType>;
  onSelect: (
    addressObject: {
      city: string;
      country: string;
      formattedAddress: string;
      latitude: number;
      longitude: number;
      placeId: string;
      state: string;
    } | null,
  ) => void;
  renderInput?: (
    props: React.InputHTMLAttributes<HTMLInputElement> & {
      loading?: boolean;
    },
  ) => React.ReactNode;
  value?: string;
};

export const LocationAutocomplete = ({
  icon,
  locationComponentRestrictions,
  locationType,
  onSelect,
  renderInput,
  value,
  ...props
}: Props) => {
  const [address, setAddress] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);

  const handleError = (status: string) => {
    switch (status) {
      case "ZERO_RESULTS":
        setError("ZERO_RESULTS");
        return;
      default:
        throw { description: status, title: "Erro desconhecido" };
    }
  };

  const handleChange = (address: string) => {
    if (address.length === 0) {
      onSelect(null);
    }

    if (error) {
      setError(null);
    }

    setAddress(address);
  };

  const handleSelect = async (address: string) => {
    setAddress(address);

    await geocodeByAddress(address)
      .then((results) => {
        const result = results[0];
        const { place_id, formatted_address } = result;
        const details = extractDetails(result);

        setAddress(formatted_address);

        return {
          formattedAddress: formatted_address,
          placeId: place_id,
          ...details,
        };
      })
      .then(onSelect)
      .catch(() => {
        setError("ZERO_RESULTS");
      });
  };

  const renderError = () => {
    return (
      <li css={styles.suggestions.item}>
        Nenhum local encontrado com: {address}
      </li>
    );
  };

  const renderLogo = () => {
    return (
      <li css={styles.suggestions.item}>
        <img
          css={styles.suggestions.google}
          src="https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png"
        />
      </li>
    );
  };

  const renderSuggestion = ({
    address,
    getSuggestionItemProps,
    index,
    suggestion,
  }: {
    address: string;
    getSuggestionItemProps: any;
    index: number;
    suggestion: Suggestion;
  }) => {
    const matches = match(suggestion.description, address);
    const parts = parse(suggestion.description, matches);

    return (
      <li key={index}>
        <button
          css={styles.suggestions.button}
          {...getSuggestionItemProps(suggestion)}
        >
          <div css={styles.suggestions.text}>
            {parts.map((part, index) => {
              return part.highlight ? (
                <span key={index} style={{ fontWeight: 500 }}>
                  {part.text}
                </span>
              ) : (
                <strong key={index} style={{ fontWeight: 300 }}>
                  {part.text}
                </strong>
              );
            })}
          </div>
        </button>
      </li>
    );
  };

  return (
    <PlacesAutocomplete
      debounce={500}
      onChange={handleChange}
      onError={handleError}
      onSelect={handleSelect}
      searchOptions={{
        componentRestrictions: locationComponentRestrictions,
        types: locationType
          ? Array.isArray(locationType)
            ? locationType
            : [locationType]
          : undefined,
      }}
      value={address ? address : value ? value : ""}
    >
      {({ getInputProps, getSuggestionItemProps, loading, suggestions }) => (
        <>
          <div css={styles.input.root}>
            {icon ? icon : null}
            {renderInput ? (
              renderInput({
                ...getInputProps({
                  ...props,
                }),
                loading,
              })
            ) : (
              <input
                css={styles.input.input}
                {...getInputProps({
                  ...props,
                })}
              />
            )}
            {loading ? <CircularSpinner size={16} /> : null}
          </div>
          {!!address && (!!error || suggestions.length > 0) ? (
            <ul css={styles.suggestions.list}>
              {error
                ? renderError()
                : suggestions.map((suggestion, index) =>
                    renderSuggestion({
                      address,
                      getSuggestionItemProps,
                      index,
                      suggestion,
                    }),
                  )}
              {!error && suggestions.length > 0 ? renderLogo() : null}
            </ul>
          ) : null}
        </>
      )}
    </PlacesAutocomplete>
  );
};
