import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    credit: {
      card: {
        expiration: "Expires on",
      },
    },
    empty: "No credits available.",
    link: "See details",
    title: "Credits",
  },
  [LanguageCode.PT_BR]: {
    credit: {
      card: {
        expiration: "Expira em",
      },
    },
    empty: "Nenhum crédito disponível.",
    link: "Ver detalhes",
    title: "Créditos",
  },
} as const;

export const useUserProfileDrawerCreditsSectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
