import React, { useCallback, useState } from "react";
import { useDebounce } from "react-use";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Input } from "~/apps/shared/components/input/input";

import { useTravelsFiltersActions } from "../trips.context";
import { styles } from "./styles";
import { useTripsSearchbarTranscription } from "./trips-searchbar.transcription";

export const TripsSearchbar: React.FC = () => {
  const { handleChangeSearchFilter } = useTravelsFiltersActions();

  const [search, setSearch] = useState("");

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [],
  );

  useDebounce(
    () => {
      void handleChangeSearchFilter(search);
    },
    300,
    [search],
  );

  const { transcription } = useTripsSearchbarTranscription();

  return (
    <div css={styles.root}>
      <Icon css={styles.icon} size={16} use="search-outline" />
      <Input
        css={styles.input}
        onChange={handleSearch}
        placeholder={transcription.placeholder}
        value={search}
      />
    </div>
  );
};
