import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      back: "Back",
      save: "Save",
    },
    fields: {
      currency: {
        label: "Currency",
        placeholder: "Choose a currency...",
      },
      language: {
        label: "Language",
        placeholder: "Choose a language...",
      },
    },
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      back: "Voltar",
      save: "Salvar",
    },
    fields: {
      currency: {
        label: "Moeda",
        placeholder: "Escolha uma moeda...",
      },
      language: {
        label: "Idioma",
        placeholder: "Escolha um idioma...",
      },
    },
  },
} as const;

export const useUserProfileDrawerEditUserPreferencesTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
