import { LanguageCode } from "~/apps/shared/constants/enums";
import { lighten } from "polished";

import { colors } from "../assets/styles/theme/constants/colors";
import { CardServiceType, CarPolicyCategory, SSOConfigProvider } from "./enums";
import TITLES from "./page-titles.constants";

// export * from "./continents.constant";

export const status = {
  scheduled: "Agendada",
  ongoing: "Em Andamento",
  concluded: "Concluída",
};

export const FRIEND_HOUSE_STATUS = {
  NONE: 0,
  SIGNALED: 1,
  CONFIRMED: 2,
  CANCELED: 3,
};

export const clientConfig = {
  id: "80d729571d9cf56cc53fc02a40c8ec54",
  secret: "6BD0fJPE8k6316lW31UN",
};

export const SERVICE_TYPES = {
  flight: "flight",
  hotel: "hotel",
  car: "car",
  bus: "bus",
  insurance: "insurance",
  none: "",
} as const;

export const ROLES = {
  traveler: "ROLE_TRAVELER",
  self_traveler: "ROLE_SELF_TRAVELER",
  user: "ROLE_USER",
  manager: "ROLE_MANAGER",
  admin: "ROLE_ADMIN",
  super: "ROLE_SUPERADMIN",
  customized: "CUSTOMIZED",
};

export const TRANSLATOR = {
  economy: "Econômica",
  premium_economy: "Econômica Premium",
  business: "Executiva",
  first: "Primeira Classe",
};

// BUDGET ERRORS
const BUDGET_ERRORS = {
  UNEXPECTED_OPERATIONS_LIST_ERROR: {
    title: "Erro inesperado.",
    description:
      "Não foi possível carregar o histórico de operações do centro de custo selecionado.",
  },
  UNEXPECTED_DEPOSIT_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível realizar o depósito.",
  },
  UNEXPECTED_WITHDRAWAL_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível realizar o saque.",
  },
  UNEXPECTED_TRANSFER_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível realizar a transferência.",
  },
  UNEXPECTED_BUDGET_LIST_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível carregar os orçamentos cadastrados.",
  },
  UNEXPECTED_BUDGET_CREATE_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível criar este orçamento.",
  },
  UNEXPECTED_BUDGET_EDIT_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível editar este orçamento.",
  },
  UNEXPECTED_BUDGET_DELETE_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível excluir este orçamento.",
  },
  UNEXPECTED_BUDGET_DETAIL_ERROR: {
    title: "Erro inesperado.",
    description:
      "Não foi possível carregar as informações do orçamento selecionado.",
  },
  UNEXPECTED_BUDGET_SETTINGS_STATUS_TOGGLE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível salvar as alterações feitas.",
  },
  UNEXPECTED_BUDGET_CHECK_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível checar os orçamentos dos centros de custo",
  },
  TRAVEL_VALUE_NOT_FOUND_BUDGET_CHECK_ERROR: {
    title: "Valor da viagem não encontrado",
    description:
      "Não foi possível estabelecer o valor da viagem. " +
      "Isso normalmente acontece quando os itens da viagem já foram ou estão sendo processados.",
  },
};

//FLIGHT LIST ERRORS

const FLIGHT_LIST_ERRORS = {
  UNEXPECTED_FLIGHT_LIST_ERROR: {
    title: "Erro inesperado.",
    description:
      "Não foi possível estabelecer a comunicação com nossos fornecedores. Por favor, entrar em contato pelo chat.",
  },
  TIMEOUT_FLIGHT_LIST_ERROR: {
    title: "Tempo limite atingido.",
    description:
      "O tempo limite para obtenção das ofertas disponíveis foi atingido. " +
      "Se o problema persistir, por favor, entrar em contato pelo chat.",
  },
};

const RECENT_SEARCHES_ERROR = {
  UNEXPECTED_FLIGHT_RECENT_SEARCH_ERROR: {
    title: "Erro ao buscar pesquisas recentes de vôo.",
    description: "Não foi possível carregar as pesquisas recentes de vôo.",
  },
};

// POLICY ERRORS
const POLICY_ERRORS = {
  UNEXPECTED_POLICY_LIST_ERROR: {
    title: "Erro inesperado.",
    description:
      "Não foi possível carregar as políticas de viagem cadastradas.",
  },
  UNEXPECTED_POLICY_CREATE_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível criar a política de viagem.",
  },
  UNEXPECTED_POLICY_EDIT_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível editar a política de viagem.",
  },
  UNEXPECTED_POLICY_DELETE_ERROR: {
    title: "Erro inesperado.",
    description: "Não foi possível excluir a política de viagem.",
  },
  UNEXPECTED_POLICY_DETAIL_ERROR: {
    title: "Erro inesperado.",
    description:
      "Não foi possível carregar as informações da política selecionada.",
  },
  UNEXPECTED_TRAVEL_POLICIES_LIST: {
    title: "Erro inesperado.",
    description:
      "Não foi possível carregar as políticas das ofertas da viagem.",
  },
  UNEXPECTED_POLICY_TARGETS_LIST: {
    title: "Erro inesperado.",
    description:
      "Não foi possível carregar os participantes da política selecionada.",
  },
  UNEXPECTED_POLICY_TARGETS_SAVE: {
    title: "Erro inesperado.",
    description:
      "Não foi possível salvar os participantes da política selecionada.",
  },
};

// COST CENTER ERRORS
const COST_CENTER_ERRORS = {
  UNEXPECTED_COST_CENTER_CREATE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o centro de custo",
  },
  UNEXPECTED_COST_CENTER_EDIT_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível editar o centro de custo selecionado",
  },
  UNEXPECTED_COST_CENTERS_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar os centros de custo, atualize a página e tente novamente",
  },
  UNEXPECTED_FETCH_COST_CENTER_BY_TOKEN_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível acessar os dados do centro de custo solicitado",
  },
  UNEXPECTED_COST_CENTER_ARCHIVE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível arquivar o centro de custo selecionado",
  },
  COST_CENTER_ALREADY_EXISTS_ERROR: {
    title: "Nome já utilizado",
    description: "Já existe um centro de custo ativo com o nome enviado",
  },
  UNEXPECTED_TOGGLE_COST_CENTERS_ACTIVE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível alterar o status de centros de custo",
  },
  UNEXPECTED_LIST_TRAVELER_COST_CENTERS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as informações de centros de custos, atualize a página",
  },
};

// PAYMENT METHODS ERRORS
const PAYMENT_METHODS_ERRORS = {
  UNEXPECTED_LIST_CARDS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível listar os cartões solicitados",
  },
  INVALID_CARD_CREATION_ERROR: {
    title: "Cartão Inválido",
    description: "Verifique seus dados e tente novamente.",
  },
  UNEXPECTED_CARD_CREATION_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível registrar o cartão desejado",
  },
  UNEXPECTED_CARD_DELETION_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível excluir o cartão solicitado",
  },
  UNEXPECTED_CARD_EDITION_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível editar o cartão desejado",
  },
  UNEXPECTED_LIST_USER_CARDS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar seus cartões de crédito, atualize a página",
  },
  UNEXPECTED_LIST_BILLING_PROFILE_VCNS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar os VCNs do perfil de cobrança, atualize a página",
  },
};

const PAYMENT_PAGE_ERRORS = {
  UNEXPECTED_APPROVAL_PAYMENT_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível aprovar a compra antes de processar o pagamento.",
  },
  UNEXPECTED_GET_COST_CENTER_BUDGETS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível acessar as informações dos centros de custo do viajante",
  },
  UNEXPECTED_GET_TRAVEL_FLIGHT_CREDITS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível verificar os créditos disponíveis para o viajante.",
  },
  UNEXPECTED_GET_PAYMENT_OPTIONS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar os meios de pagamento disponíveis para a viagem.",
  },
  UNEXPECTED_CHECK_PERMISSION_TO_PAY_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível verificar se o usuário tem permissão para efetuar o pagamento da viagem",
  },
};

// HOTEL SEARCH ERRORS
const HOTEL_SEARCH_ERRORS = {
  UNEXPECTED_LOAD_HOTEL_INFO_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível listar os hotéis para pesquisa solicitada.",
  },
  UNEXPECTED_LOAD_HOTEL_RECEPTION_HOURS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as horas de checkin e checkout do hotel selecionado.",
  },
  UNEXPECTED_LOAD_HOTEL_DETAILS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as informações do hotel selecionado.",
  },
  UNEXPECTED_LOAD_HOTEL_ROOMS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as informações dos quartos do hotel selecionado.",
  },
  UNEXPECTED_ADD_HOTEL_OFFER_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível adicionar o quarto selecionado ao seu itineário, entre em contato com o suporte",
  },
  UNEXPECTED_SET_HOTEL_FAVOURITE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível favoritar o hotel selecionado",
  },
  UNEXPECTED_REMOVE_HOTEL_FROM_FAVOURITE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível remover o hotel selecionado da lista de favoritos",
  },
};

// ANALYTICS_ERRORS
const ANALYTICS_ERRORS = {
  FILTERS_LOAD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar um ou mais filtros.",
  },
  FILE_DOWNLOAD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível fazer o download do relatório selecionado.",
  },
  UNEXPECTED_GET_BUDGETS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar os dados dos Centros de Custos.",
  },
  UNEXPECTED_GET_PLANNED_SPENT_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar os valores gastos e planejados ao longo do tempo.",
  },
  UNEXPECTED_GET_REAL_TIME_TRAVELS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as informações das viagens em tempo real.",
  },
};

// APPROVALS_ERRORS
const APPROVALS_ERRORS = {
  UNEXPECTED_APPROVALS_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Erro ao listar aprovações, atualize a página ou entre em contato o suporte",
  },
};

// REWARD ERRORS
const REWARD_ERRORS = {
  UNEXPECTED_REWARD_POLICY_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível obter a política de pontos do cliente.",
  },
};

// FINANCIAL ERRORS
const FINANCIAL_ERRORS = {
  UNEXPECTED_LIST_INVOICE_SERVICES_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar as notas do tipo de serviço selecionado",
  },
  UNEXPECTED_LIST_SMARTRIPS_INVOICE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível encontrar as notas para o período solicitado",
  },
  UNEXPECTED_TRANSACTIONS_INVOICES_DOWNLOAD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível baixar as notas selecionadas",
  },
  UNEXPECTED_FINACIAL_STATEMENT_DOWNLOAD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível baixar o extrato solicitado",
  },
};

// EXPENSE_APPROVAL_PROCESS_ERRORS
const EXPENSE_APPROVAL_PROCESS_ERRORS = {
  UNEXPECTED_EXPENSE_APPROVAL_RULES_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as políticas de aprovação de despesas",
  },
  UNEXPECTED_USER_SEARCH_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível o usuário desejado",
  },
  UNEXPECTED_CREATE_EXPENSE_APPROVAL_RULE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o processo de aprovação desejado.",
  },
  EXPENSE_RULE_ALREADY_EXISTS_ERROR: {
    title: "Política existente",
    description:
      "Já existe uma política cadastrada para o usuário selecionado.",
  },
  UNEXPECTED_EXPENSE_APPROVAL_RULES_DELETE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível excluir o processo selecionado.",
  },
  UNEXPECTED_EDIT_EXPENSE_APPROVAL_RULE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível editar o processo e aprovação desejado.",
  },
  UNEXPECTED_EXPENSE_REPORT_DENY_RESPONSE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível negar a solicitação enviada, tente novamente.",
  },
  UNEXPECTED_EXPENSE_REPORT_APPROVE_RESPONSE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível aprovar a solicição enviada, tente novamente.",
  },
  UNEXPECTED_GET_EXPENSE_REPORT_APPROVAL_STATUS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar os dados de aprovação do relatório",
  },
  USER_NOT_ALLOWED_TO_FETCH_REPORT_ERROR: {
    title: "Permissão negada",
    description: "Este usuário não pode acessar o relatório solicitado",
  },
  UNEXPECTED_GET_REPORT_EXPENSES_POLICIES_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível encontrar os dados de política do relatório solicitado",
  },
  UNEXPECTED_EXPENSE_REPORT_INVOICES_DOWNLOAD_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível baixar os comprovantes do relatório solicitado",
  },
};

// ADVANCE APPROVAL PROCESS
const ADVANCE_APPROVAL_PROCESS = {
  UNEXPECTED_ADVANCE_APPROVAL_RULES_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as políticas de aprovação de adiantamentos",
  },
  ADVANCE_RULE_ALREADY_EXISTS_ERROR: {
    title: "Política existente",
    description:
      "Já existe uma política cadastrada para o usuário selecionado.",
  },
  UNEXPECTED_CREATE_ADVANCE_APPROVAL_RULE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o processo de aprovação desejado.",
  },
  UNEXPECTED_EDIT_ADVANCE_APPROVAL_RULE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível editar o processo e aprovação desejado.",
  },
  UNEXPECTED_ADVANCE_APPROVAL_RULES_DELETE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível excluir o processo selecionado.",
  },
};

// ADVANCED EXPENSES
const ADVANCED_EXPENSE_ERRORS = {
  UNEXPECTED_FETCH_ADVANCED_EXPENSE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar a lista de adiantamentos",
  },
  UNEXPECTED_FETCH_ADVANCED_EXPENSE_DETAILS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar o status do adiantamento",
  },
  UNEXPECTED_CREATE_ADVANCED_EXPENSE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar uma nova solicitação de adiantamento",
  },
  UNEXPECTED_VINCULATE_ADVANCE_EXPENSE_REPORT: {
    title: "Erro inesperado",
    description:
      "Não foi vincular a solicitação de adiantamento a um relatório de despesa",
  },
};

// CREDITS ERRORS
const CREDITS_ERRORS = {
  UNEXPECTED_TRAVELER_CREDITS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível obter os créditos disponíveis do viajante.",
  },
  UNEXPECTED_USER_CREDITS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível obter os créditos disponíveis do usuário.",
  },
};

const TAG_ERRORS = {
  UNEXPECTED_CREATE_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar a etiqueta.",
  },
  UNEXPECTED_ADD_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível adicionar a etiqueta à viagem.",
  },
  UNEXPECTED_EDIT_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao editar etiqueta.",
  },
  UNEXPECTED_LOAD_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar as etiquetas de viagem.",
  },
  UNEXPECTED_UPDATE_CONFIG_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao atualizar configuração de tag",
  },
  UNEXPECTED_ARCHIVE_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao arquivar tag.",
  },
  UNEXPECTED_UNARCHIVE_TAG_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao desarquivar tag.",
  },
};

// NEW TRIP ERRORS
const NEW_TRIP_ERRORS = {
  UNEXPECT_NEW_TRIP_SEARCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível realizar a busca solicitada, revise os dados e tente novamente",
  },
  UNEXPECTED_GET_CAR_LOCATION_AUTOSUGGESION_ERROR: {
    title: "Erro inesperado",
    description: "Nenhum resultado encontrado",
  },
  UNEXPECTED_GET_BUS_AUTOSUGGEST_ERROR: {
    title: "Erro inesperado",
    description: "Nenhum resultado encontrado",
  },
};

// CAR SEARCH ERRORS
const CAR_SEARCH_ERRORS = {
  UNEXPECTED_LOAD_CARS_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar os carros para a pesquisa solicitada, tente novamente.",
  },
  UNEXPECTED_ADD_CAR_OFFER_TO_TRAVEL_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível adicionar a oferta selecionada a sua viagem",
  },
  UNEXPECTED_ADD_ADDITIONAL_TO_CAR_OFFER_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível alterar os adicionais da oferta selecionada.",
  },
  UNEXPECTED_GET_CAR_SUPPLIER_OFFER: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar o carro selecionado, tente novamente.",
  },
  UNEXPECTED_SEARCH_CONDUCTORS: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar os condutores disponíveis para o carro selecionado, tente novamente.",
  },
};

// EXPENSE POLICY ERRORS
const EXPENSE_POLICY_ERRORS = {
  UNEXPECTED_LIST_EXPENSE_POLICIES_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível recuperar a lista de políticas de despesas",
  },
  UNEXPECTED_DELETE_EXPENSE_POLICY_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível excluir a política solicitada",
  },
  UNEXPECTED_GET_EXPENSE_POLICY_INFO_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível recuperar os dados da política selecionada",
  },
  UNEXPECTED_GET_TARGET_SUGGESTIONS_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao procurar alvos para política",
  },
  UNEXPECTED_EXPENSE_POLICY_CREATION_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível criar a política solicitada, verifique os dados e tente novamente",
  },
  UNEXPECTED_EXPENSE_POLICY_EDITION_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível atualizar a política solicitada, verifique os dados e tente novamente",
  },
};

// BUS ERRORS

const BUS_ERRORS = {
  UNEXPECTED_BUS_LIST_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar a lista de ônibus disponíveis.",
  },
  UNEXPECTED_BUS_DETAILS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar a seleção de assentos.",
  },
  BUS_OFFER_INSERTION_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível inserir a oferta ao itinerário",
  },
};

const INSURANCE_ERRORS = {
  UNEXPECTED_INSURANCE_GETTING_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível adicionar a contratação do seguro",
  },
  UNEXPECTED_INSURANCE_SETTING_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível adicionar a contratação do seguro",
  },
};

const ITINERARY_ERRORS = {
  UNEXPECTED_TRAVEL_INVOICES_DONWLOAD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível baixar dos comprovantes da viagem",
  },
  UNEXPECTED_REQUEST_VOUCHER_EMAIL_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível solicitar email com vouchers",
  },
  UNEXPECTED_TRAVEL_ITINERARY_SHARE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível compartilhar seu itinerário.",
  },
  UNEXPECTED_USERS_FETCH_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível recuperar a lista de usuários",
  },
  UNEXPECTED_GET_HOTEL_SUGGESTION_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao buscar local solicitado",
  },
  UNEXPECTED_ADD_TRAVELER_TO_OFFER_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível adicionar o usuário a oferta",
  },
  UNEXPECTED_REMOVE_TRAVELER_FROM_OFFER_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível remover o usuário da oferta",
  },
  UNEXPECTED_CHANGE_CHECK_IN_STATUS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível alterar a opção selecionada",
  },
  UNEXPECTED_TRAVEL_INFO_UPDATE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível atualizar os dados da viagem.",
  },
  UNEXPECTED_TRAVEL_INFO_EDIT_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível editar as informações administrativas.",
  },
  UNEXPECTED_CANCEL_OWN_APPROVAL_REQUEST: {
    title: "Erro inesperado",
    description:
      "Não foi possível cancelar o seu pedido de aprovação de viagem",
  },
  UNEXPECTED_GET_JUSTIFICATION_POLICY_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível recuperar as políticas de justificativa da viagem.",
  },
};

const REPLICATE_TRAVEL_ERRORS = {
  UNEXPECTED_TRAVEL_REPLICATE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível realizar a cópia da viagem desejada, tente novamente.",
  },
};

const SUPPORT_EVALUATION_ERRORS = {
  UNEXPECTED_GET_EVALUATION_STATUS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível encontrar a avaliação de atendimento solicitada",
  },
  UNEXPECTED_SUBMIT_SUPPORT_EVALUATION_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível enviar a avaliação, tente novamente.",
  },
  USER_NOT_ALLOWED_TO_EVALUATE: {
    title: "Usuário sem permissão",
    description: "O usuário não possuí permissão para realizar essa avaliação.",
  },
};

const TRAVEL_CANCELATION_ERRORS = {
  UNEXPECTED_CANCEL_TRAVEL_OFFERS_ERROR: {
    title: "Erro inesperado",
    description:
      "Occoreu um erro ao tentar cancelar as ofertar selecionadas, entre em contato com nosso suporte",
  },
};

const CLIENT_REVIEW_ERRORS = {
  UNEXPECTED_LIST_CLIENT_REVIEWS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar as informações de client review solicitadas.",
  },
  UNEXPECTED_GET_CLIENT_REVIEW_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível acessar os detalhes do agendamento selecionado.",
  },
  UNEXPECTED_REMOVE_CLIENT_REVIEW_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível remover o agendamento solicitado.",
  },
  UNEXPECTED_CREATE_CLIENT_REVIEW_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o agendamento solicitado.",
  },
  UNEXPECTED_CREATE_CLIENT_REVIEW_SOURCE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível atrelar as informações ao client review solicitado",
  },
  UNEXPECTED_UPDATE_CLIENT_REVIEW_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível salvar as alterações do agendamento solicitado.",
  },
  UNEXPECTED_REMOVE_CLIENT_REVIEW_SOURCE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível atualizar as informações do client review solicitado",
  },
};

const USER_REVIEW_ERRORS = {
  UNEXPECTED_GET_USER_HOTEL_REVIEWS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível retornar as avaliações do usuário solicitado.",
  },
};

const HOTEL_REVIEWS_ERRORS = {
  UNEXPECTED_SUBMIT_HOTEL_REVIEW_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível salvar a avaliação enviada.",
  },
};

const TRAVELS_LIST_ERRORS = {
  UNEXPECTED_LIST_USER_TRAVELS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar a lista de viagens, atualize a página ou tente novamente mais tarde.",
  },
  UNEXPECTED_LIST_TRAVELS_USERS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carrega as informações dos viajantes, atualize a página.",
  },
  UNEXPECTED_LOAD_POINTS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar seus smartpoints.",
  },
  UNEXPECTED_FETCH_COPYING_AND_ORIGINAL_TRAVELS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as viagens sendo replicadas no momento.",
  },
  UNEXPECTED_CHECK_TRAVEL_COPY_STATUS_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível checar o status das viagens sendo replicadas.",
  },
  UNEXPECTED_TRAVEL_LIST_DELETE_TRAVEL_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao excluir viagem.",
  },
};

const BILLING_PROFILE_CONFIGURATION_ERRORS = {
  UNEXPECTED_LIST_CLIENT_BILLING_PROFILES_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível listar os perfis de cobrança.",
  },
  UNEXPECTED_GET_SINGLE_BILLING_PROFILE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar as informações do perfil de cobrança selecionado.",
  },
  UNEXPECTED_CREATE_BILLING_PROFILE_ERROR: {
    title: "Erro inesperado",
    description: "Erro inesperado ao criar perfil de cobrança.",
  },
  UNEXPECTED_UPDATE_BILLING_PROFILE_ERROR: {
    title: "Erro inesperado",
    description: "Erro inesperado ao atualizar perfil de cobrança.",
  },
  UNEXPECTED_DELETE_BILLING_PROFILE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível excluir o perfil de cobrança selecionado.",
  },
};

const AREAS_ERRORS = {
  UNEXPECTED_FETCH_CLIENTS_AREAS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível listar as informações solicitadas.",
  },
  UNEXPECTED_FETCH_AREA_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar as informações dessa área.",
  },
  UNEXPECTED_CREATE_AREA_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar uma nova área.",
  },
  UNEXPECTED_UPDATE_AREA_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao atualizar as informações da área.",
  },
  UNEXPECTED_UPDATE_DISPLAY_AREA_NAME_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao atualizar nome personalizado.",
  },
  UNEXPECTED_ADD_USER_TO_AREA_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao adicionar usuários a área.",
  },
  UNEXPECTED_REMOVE_USER_FROM_AREA_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao remover usuários da área.",
  },
  UNEXPECTED_GET_USER_AREAS_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao carregar areas do viajante",
  },
};

const BOARD_ERRORS = {
  UNEXPECTED_FETCH_CLIENTS_BOARDS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível listar as informações solicitadas.",
  },
  UNEXPECTED_FETCH_BOARD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar as informações dessa área.",
  },
  UNEXPECTED_CREATE_BOARD_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar uma nova diretoria.",
  },
  UNEXPECTED_UPDATE_BOARD_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao atualizar as informações de diretoria.",
  },
  UNEXPECTED_ADD_AREA_TO_BOARD_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao adicionar itens da diretoria.",
  },
  UNEXPECTED_REMOVE_AREA_FROM_BOARD_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao remover itens da diretoria.",
  },
};

const PROJECTS_CONFIGURATION_ERRORS = {
  PROJECT_NAME_ALREADY_TAKEN: {
    title: "Nome inválido",
    description: "Nome já utilizado em outro projeto.",
  },
  UNEXPECTED_PROJECT_CREATION_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível criar o projeto, revise os dados enviados e tente novamente.",
  },
  UNEXPECTED_PROJECT_EDITING_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível editar o projeto, revise os dados enviados e tente novamente.",
  },
  UNEXPECTED_PROJECT_EDIT_FETCH_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar os dados do projeto a ser editado.",
  },
  UNEXPECTED_PROJECT_LIST_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível listar os projetos, tente novamente",
  },
  UNEXPECTED_PROJECT_STATUS_CHANGE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível alterar o status deste projeto, tente novamente",
  },
  UNEXPTECT_UPDATE_PROJECT_ENABLING_STATE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível atualizar a opção selecionada",
  },
  UNEXPECTED_GET_USER_PROJECTS_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao carregar projetos do viajante",
  },
};

// EXPENSES CATEGORIES ERRORS

export const EXPENSES_CATEGORIES_ERRORS = {
  UNEXPECTED_GET_CLIENT_EXPENSES_CATEGORIES_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao carregar categorias de despesas",
  },
  UNEXPECTED_CREATE_CLIENT_EXPENSES_CATEGORIES_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao criar categoria de despesas",
  },
  UNEXPECTED_UPDATE_CLIENT_EXPENSES_CATEGORIES_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao atualizar categoria de despesas",
  },
};

// BOOKING CONFIRMATION ERRORS

export const BOOKING_CONFIRMATION_ERRORS = {
  UNEXPECTED_GET_HOTEL_NEGOTIATION_DATA_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao carregar dados da reserva, tente novamente.",
  },
  HOTEL_CONFIRMATION_NOT_ALLOWED_ERROR: {
    title: "Sem permissão",
    description: "A reserva acessada já foi aprovada, negada ou já expirou.",
  },
  UNEXPECTED_CONFIRM_HOTEL_BOOKING_REQUEST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível confirmar a reserva solicitada, tente novamente.",
  },
  UNEXPECTED_DENY_HOTEL_BOOKING_REQUEST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível negar a reserva solicitada, tente novamente.",
  },
};

// EXPENSE ADVANCES APPROVALS ERRORS

const EXPENSE_ADVANCES_APPROVALS_ERRORS = {
  UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVALS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar as aprovações pendentes",
  },
};

// EXPENSE ADVANCE APPROVAL REVIEW ERRORS

const EXPENSE_ADVANCE_APPROVAL_REVIEW_ERRORS = {
  UNEXPECTED_GET_EXPENSE_ADVANCE_DETAILS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar os dados do adiantamento.",
  },
  UNEXPECTED_GET_EXPENSE_ADVANCE_APPROVER_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar os aprovadores do adiantamento.",
  },
  UNEXPECTED_APPROVE_EXPENSE_ADVANCE_REQUEST_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao aprovar/pagar a solicitação de adiantamento.",
  },
  UNEXPECTED_DENY_EXPENSE_ADVANCE_REQUEST_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao negar a solicitação de adiantamento.",
  },
};

// LOYALTY PROGRAMS
const LOYALTY_PROGRAMS_ERRORS = {
  UNEXPECTED_GET_LOYALTY_PROGRAMS_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar os programas de fidelidade.",
  },
  UNEXPECTED_EDIT_LOYALTY_PROGRAM_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível editar o programa de fidelidade.",
  },
  UNEXPECTED_CREATE_LOYALTY_PROGRAM_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o programa de fidelidade.",
  },
  UNEXPECTED_DELETE_LOYALTY_PROGRAM_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível excluir o programa de fidelidade.",
  },
};

const TRAVEL_APPROVAL_ERRORS = {
  UNEXPECTED_DENY_OFFER_APPROVAL_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível sinalizar uma de suas ofertas como negada, atualize a página e tente novamente.",
  },
  UNEXPECTED_UNAVAILABLE_DENY_OFFER_APPROVAL_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível sinalizar uma de suas ofertas como indisponível, atualize a página e tente novamente.",
  },
  UNEXPECTED_UNDENY_OFFER_APPROVAL_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível sinalizar uma de suas ofertas como aprovada, atualize a página e tente novamente.",
  },
};

const EXPENSE_ERRORS = {
  UNEXPECTED_IMAGE_UPLOAD_URL_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível realizar o upload do arquivo desejado",
  },
  UNEXPECTED_EXPENSE_CREATION_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar a despesa solicitada, tente novamente",
  },
  UNEXPECTED_EXPENSE_UPDATE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível atualizar a despesa solicitada, verifique os dados e tente novamente",
  },
  UNEXPECTED_CALCULATE_ROUTE_DISTANCE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível calcular a rota e o valor do percurso informado, tente novamente",
  },
  UNEXPECTED_GENERATE_KILOMETER_RECEIPT_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao gerar comprovante para a rota selecionada",
  },
  UNEXPECTED_EXPENSE_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível retornar a lista de despesas, tente novamente",
  },
  UNEXPECTED_EXPENSE_DELETE_ERROR: {
    title: "Erro inesperado",
    description: "Não possível excluir a desepesa solicitada, tente novamente",
  },
  UNEXPECTED_EXPENSES_REPORTS_LIST_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível listar os relatórios solicitados, recarregue a página ou tente novamente",
  },
  UNEXPECTED_ADD_EXPENSES_TO_REPORT_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível adicionar as despesas ao relatório solictiado",
  },
  UNEXPECTED_REPORT_CREATE_WITH_EXPENSES_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o relatório, tente novamente",
  },
  UNEXPECTED_REPORT_CREATE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível criar o relatório solicitado",
  },
  UNEXPECTED_RECEIPT_IMAGE_FETCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível carregar o comprovante da desepesa selecionada",
  },
  UNEXPECTED_REPORT_FETCH_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível retornar os dados do relatório solicitado",
  },
  UNEXPECTED_REPORT_TRAVEL_FETCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível retornar os dados da viagem atrelada ao relatório, atualize sua página",
  },
  UNEXPECTED_FINISH_REPORT_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível enviar o relatório solicitado",
  },
  UNEXPECTECD_REMOVE_EXPENSE_FROM_REPORT_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível remover a despesa selecionada do relatório",
  },
  UNEXPECTED_REPORT_DESCRIPTION_UPDATE_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível atualizar a descrição da despesa",
  },
  UNEXPECTED_REPORT_SEND_APPROVAL_REQUEST_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível enviar o relatório para aprovação.",
  },
  UNEXPECTED_CHECK_USER_EXPENSES_PROCESSES_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao procurar processos de aprovação de despesas",
  },
  UNEXPECTED_SIGNAL_MULTIPLE_PAYMENTS_ERROR: {
    title: "Erro inesperado",
    description:
      "Erro ao realizar baixa de pagamento(s), atualize a página e tente novamente",
  },
  UNEXPECTED_CANCEL_REPORT_APPROVAL_REQUEST_ERROR: {
    title: "Erro inesperado",
    description:
      "Erro ao cancelar pedido de aprovação para reembolso de despesas, atualize a página tente novamente.",
  },
  UNEXPECTED_REMOVE_EXPENSE_REPORT_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao excluir o relatório de despesas.",
  },
};

const BOOKER_TARGET_ERRORS = {
  UNEXPECTED_BOOKER_TARGET_SEARCH_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao procurar usuarios, tente novamente.",
  },
  UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR: {
    title: "Erro inesperado",
    description: "Erro ao procurar usuarios, tente novamente.",
  },
};

const TRAVELS_COUNT_ERRORS = {
  UNEXPECTED_TRAVELS_COUNT_ERROR: {
    title: "Erro inesperado",
    description:
      "Ocorreu um erro ao tentar contabilizar as viagens. Por favor, tente novamente.",
  },
};

export const ERROR = {
  CONNECTION: {
    title:
      "Ops, parece que está havendo problemas de conexão com nosso servidor!",
    description:
      "Verifique a conectividade de sua internet. Recarregue a página ou tente novamente mais tarde. Se o problema persistir, por favor, entrar em contato com nosso suporte, podemos estar em manutenção.",
  },
  NOT_FOUND: {
    title: "Parece que você se perdeu!",
    description: "Não encontramos a página que você está buscando",
  },
  UNEXPECTED: {
    title: "Não é você. Sou eu!",
    description:
      "Estamos passando por alguns ajustes. Voltaremos em breve. Caso o problema persista, por favor entre em contato com nosso suporte.",
  },
  UNAUTHORIZED_ROUTE: {
    title: "Tem nome na lista?",
    description: "Desculpe, você não tem acesso a essa parte do sistema",
  },
  UNAUTHORIZED_ACCESS: {
    title: "Erro de permissão",
    description: "Você não tem permissão para realizar essa ação",
  },
  UNEXPECTED_PAYMENT_ERROR: {
    title: "Houve uma falha na sua reserva",
    description:
      "Houve uma falha inesperada antes mesmo do processamento da sua compra. Por favor, entre em contato com nosso suporte para que possamos rapidamente resolver o problema.",
  },
  UNEXPECTED_FUND_CHECK_ERROR: {
    title: "Houve uma falha na verificação da transação",
    description:
      "Por favor, entre em contato com nosso suporte para que possamos rapidamente resolver o problema.",
  },
  INVALID_CARD_PAYMENT_ERROR: {
    title: "Cartão Inválido",
    description: "Verifique os dados e tente novamente.",
  },
  INSUFFICIENT_FUNDS_PAYMENT_ERROR: {
    title: "Saldo de cartão insuficiente.",
    description: "Escolha outro cartão e tente novamente.",
  },
  DOCUMENTS_PAYMENT_ERROR: {
    title: "Documentos do viajante não encontrados",
    description:
      "Verifique se o viajante já possui todos os documentos cadastrados.",
  },
  HALF_INSUFFICIENT_FUNDS_PAYMENT_ERROR: {
    title:
      "O saldo do seu cartão era insuficente para completar a compra de todos os itens.",
    description:
      "Pode ficar tranquilo, " +
      "todos itens agora cobrados em seu cartão foram cancelados com sucesso e serão todos estornados. " +
      "Escolha outro cartão e tente novamente.",
  },
  HALF_INSUFFICIENT_FUNDS_NOT_CANCELED_PAYMENT_ERROR: {
    title:
      "O saldo do seu cartão era insuficente para completar a compra de todos os itens.",
    description:
      "ATENÇÃO: " +
      "Quando isso acontece, nosso procedimento padrão é automaticamente cancelar e estornar os itens comprados com sucesso. " +
      "Houve, porém, uma falha neste procedimento e já estamos trabalhando para cancelá-los manualmente. " +
      "Pode ficar tranquilo, entraremos em contato notificando o cancelamento. " +
      "Enquanto isso, fique à vontade para entrar em contato conosco.",
  },
  ERROR_VAULT_LIMIT_ERROR: {
    title: "Saldo do cofre insuficiente",
    description:
      "O saldo do cofre da empresa, no momento, é insuficiente para completar a compra dos itens selecionados. " +
      "Verifique com o usuário administrador responsável e tente novamente.",
  },
  ERROR_BUDGET_LIMIT_ERROR: {
    title: "Limite do centro de custo alcançado",
    description:
      "O valor de sua compra ultrapassará o valor planejado para o centro de custos selecionado no mês vigente. " +
      "Verifique com o usuário administrador responsável e tente novamente.",
  },
  UNEXPECTED_APPROVAL_LISTING_ERROR: {
    title: "Erro Inesperado",
    description: "Não foi possível carregar suas regras de aprovação",
  },
  UNEXPECTED_APPROVAL_CREATION_ERROR: {
    title: "Erro Inesperado",
    description: "Não foi possível criar a regra de aprovação",
  },
  UNEXPECTED_APPROVAL_EDITION_ERROR: {
    title: "Erro Inesperado",
    description: "Não foi possível editar a regra de aprovação",
  },
  UNEXPECTED_APPROVAL_TARGET_LISTING_ERROR: {
    title: "Erro Inesperado",
    description: "Não foi possível carregar os alvos de aprovação",
  },
  APPROVAL_RULE_DELETE_ERROR: {
    title: "Erro Inesperado",
    description: "Não foi possível excluir a regra de aprovação selecionada",
  },
  APPROVAL_RULE_PENDING_REQUESTS: {
    title: "Ação não permitida",
    description:
      "Não foi possível editar/excluir a regra de aprovação pois há aprovações pendentes",
  },
  UNEXPECTED_TRAVEL_FETCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível retornar os dados de sua viagem, tente novamente mais tarde",
  },
  UNEXPECTED_USER_FETCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível retornar as informações do usuário solicitado, tente novamente.",
  },
  UNEXPECTED_APPROVAL_STATUS_FETCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível retornar as informações sobre o processo de aprovação desta viagem, tente novamente.",
  },
  UNEXPECTED_APPROVAL_RESPONSE_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível atualizar o status da solicitação de aprovação conforme o desejado, tente novamente",
  },
  NO_PERMISSION_APPROVAL_RESPONSE_ERROR: {
    title: "Erro de permissão",
    description:
      "Não foi possível atualizar o status da solicitação. É possível que esta já tenha sido respondida por outra pessoa.",
  },
  UNEXPECTED_TRAVEL_AVAILABILITIES_FETCH_ERROR: {
    title: "Não foi possível verificar a disponibilidade",
    description:
      "Não foi possível se comunicar com os nossos fornecedores. Caso o problema persista, por favor, entrar em contato com o suporte.",
  },
  UNEXPECTED_PENDING_APPROVAL_TRAVEL_TOKENS_FETCH_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível resgatar a lista de solicitações de aprovação feitas para seu usuário.",
  },
  UNEXPECTED_APPROVER_USERS_LIST_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível encontrar o aprovador desejado",
  },
  UNEXPECTED_AUTOSUGGEST_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível retornar resultados para sua pesquisa",
  },
  UNEXPECTED_USER_LIST_FETCH_ERROR: {
    title: "Erro inesperado",
    description: "Não foi possível carregar as informações dos usuários",
  },
  UNEXPECTED_FETCH_CLIENT_INFO_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível recuperar as configurações de sua empresa, atualize a página",
  },
  GENERAL_CONNECTION_ERROR: {
    title: "Erro de Conexão",
    description:
      "Verifique a conectividade de sua internet. Recarregue a página ou tente novamente mais tarde. Se o problema persistir, por favor, entrar em contato com nosso suporte, podemos estar em manutenção.",
  },
  GENERAL_UNEXPECTED_ERROR: {
    title: "Erro Inesperado",
    description: "Erro Inesperado",
  },
  GENERAL_GETTING_VERIFICATION_ERROR: {
    title: "Erro inesperado",
    description:
      "Não foi possível verificar se o viajante já possui uma reserva, atualize a página",
  },
  EXPIRED_SEARCH_ERROR: {
    title: "Busca expirada",
    description: "Faça uma nova busca para preços atualizados.",
  },
  SEARCH_NOT_ALLOWED: {
    title: "Busca não permitida",
    description: "Você não pode pesquisar hotéis nesse país.",
  },
  ORDER_BOOKING_ERROR: (errorData: { message: string }) => ({
    description: errorData.message,
    title: "Erro de itinerário",
  }),
  ...POLICY_ERRORS,
  ...COST_CENTER_ERRORS,
  ...PAYMENT_METHODS_ERRORS,
  ...BUDGET_ERRORS,
  ...HOTEL_SEARCH_ERRORS,
  ...ANALYTICS_ERRORS,
  ...PAYMENT_PAGE_ERRORS,
  ...APPROVALS_ERRORS,
  ...FLIGHT_LIST_ERRORS,
  ...RECENT_SEARCHES_ERROR,
  ...REWARD_ERRORS,
  ...EXPENSE_APPROVAL_PROCESS_ERRORS,
  ...FINANCIAL_ERRORS,
  ...CREDITS_ERRORS,
  ...NEW_TRIP_ERRORS,
  ...TAG_ERRORS,
  ...CAR_SEARCH_ERRORS,
  ...EXPENSE_POLICY_ERRORS,
  ...BUS_ERRORS,
  ...INSURANCE_ERRORS,
  ...ITINERARY_ERRORS,
  ...REPLICATE_TRAVEL_ERRORS,
  ...SUPPORT_EVALUATION_ERRORS,
  ...TRAVEL_CANCELATION_ERRORS,
  ...CLIENT_REVIEW_ERRORS,
  ...USER_REVIEW_ERRORS,
  ...HOTEL_REVIEWS_ERRORS,
  ...TRAVELS_LIST_ERRORS,
  ...BILLING_PROFILE_CONFIGURATION_ERRORS,
  ...AREAS_ERRORS,
  ...BOARD_ERRORS,
  ...PROJECTS_CONFIGURATION_ERRORS,
  ...EXPENSES_CATEGORIES_ERRORS,
  ...BOOKING_CONFIRMATION_ERRORS,
  ...ADVANCE_APPROVAL_PROCESS,
  ...ADVANCED_EXPENSE_ERRORS,
  ...EXPENSE_ADVANCES_APPROVALS_ERRORS,
  ...EXPENSE_ADVANCE_APPROVAL_REVIEW_ERRORS,
  ...LOYALTY_PROGRAMS_ERRORS,
  ...TRAVEL_APPROVAL_ERRORS,
  ...EXPENSE_ERRORS,
  ...BOOKER_TARGET_ERRORS,
  ...TRAVELS_COUNT_ERRORS,
};

export const DOCUMENT_TYPES = {
  ID: "id",
  PASSPORT: "passport",
  RG: "rg",
};

export const DOCUMENT_TYPES_TRANSLATION = {
  [DOCUMENT_TYPES.ID]: "CPF",
  [DOCUMENT_TYPES.PASSPORT]: "passaporte",
  [DOCUMENT_TYPES.RG]: "RG",
};

export const ROLES_TEXT = {
  ADMIN:
    "Este usuário terá acesso as áreas de relatórios, financeiro e configurações de sua empresa, assim como poderá visualizar viagens agendadas e relatórios de despesas de outros usuários.",
  MANAGER:
    "Esse usuário poderá criar novas viagens para si e para outros, assim como acessar as áreas de relatórios e financeiro.",
  USER: "Esse usuário poderá criar novas viagens para si e para outros",
  TRAVELER: "Esse usuário poderá apenas visualizar viagens criadas para ele",
};

export const FLIGHT_TABS = {
  ONEWAY: 1,
  BACKANDFORTH: 0,
};

export const TRAVEL_NAME_PLACEHOLDERS = [
  "Visita ao cliente XYZ em SP",
  "Treinamento comercial no RJ",
  "Visita técnica urgente ao cliente XPTO",
  "Reunião com o cliente ABC",
];

export const BOUND_TYPES = {
  outbound: "outbound",
  inbound: "inbound",
};

export const BOUND_RANGE_TYPES = {
  departure: "departure",
  arrival: "arrival",
  duration: "duration",
};

export const APPROVAL_TYPES = {
  PROJECT: "PROJECT",
  USER: "USER",
  COST_CENTER: "COST-CENTER",
  AREA: "AREA",
  COMPANY: "COMPANY",
};

export const APPROVAL_RULES = {
  OUT_POLICY_APPROVAL: "conditional", // ACCORDING TO POLICY
  NEED_APPROVAL: "need_approval",
  ALL_NEED_APPROVAL: "all", //ALL TRIPS NEED APPROVAL
  NONE_NEED_APPROVAL: "none",
};

export const APPROVAL_POLICY_CONDITION = {
  ALL: "ALL",
  IN_POLICY: "IN",
  OUT_OF_POLICY: "OUT",
};

export const APPROVAL_TYPES_LABELS = {
  [APPROVAL_TYPES.PROJECT]: "Projeto",
  [APPROVAL_TYPES.USER]: "Usuário",
  [APPROVAL_TYPES.COST_CENTER]: "Centro de custo",
  [APPROVAL_TYPES.AREA]: "Área",
  [APPROVAL_TYPES.COMPANY]: "Empresa",
};

export const APPROVAL_POLICY_LABELS = {
  [APPROVAL_POLICY_CONDITION.OUT_OF_POLICY]: "fora",
  [APPROVAL_POLICY_CONDITION.IN_POLICY]: "dentro",
  [APPROVAL_POLICY_CONDITION.ALL]: "dentro ou fora",
};

export const APPROVAL_RULES_TERRITORIES = {
  NATIONAL: "NATIONAL",
  INTERNATIONAL: "INTERNATIONAL",
  ALL: "ALL",
};

export const APPROVAL_RULES_TERRITORIES_LABELS = {
  [APPROVAL_RULES_TERRITORIES.NATIONAL]: "nacional",
  [APPROVAL_RULES_TERRITORIES.INTERNATIONAL]: "internacional",
  [APPROVAL_RULES_TERRITORIES.ALL]: "nacional ou internacional",
};

export const EXPENSES_APPROVAL_LABELS = {
  [APPROVAL_RULES.OUT_POLICY_APPROVAL]:
    "Aprovação para relatórios fora da política",
  [APPROVAL_RULES.ALL_NEED_APPROVAL]:
    "Todos os relátorios precisam de aprovação",
  [APPROVAL_RULES.NONE_NEED_APPROVAL]: "Relatórios sem restrições",
};

export const ADVANCED_EXPENSES_APPROVAL_LABELS = {
  [APPROVAL_RULES.OUT_POLICY_APPROVAL]:
    "Aprovação para adiantamentos fora da política",
  [APPROVAL_RULES.ALL_NEED_APPROVAL]:
    "Todos os adiantamentos precisam de aprovação",
  [APPROVAL_RULES.NONE_NEED_APPROVAL]: "Adiantamentos sem restrições",
};

export const APPROVAL_PROCESS_TYPES = {
  APPROVE: "APPROVE",
  NOTIFY: "NOTIFY",
};

export const APPROVAL_PROCESS_TYPES_LABELS = {
  [APPROVAL_PROCESS_TYPES.APPROVE]: "Solicitar aprovação",
  [APPROVAL_PROCESS_TYPES.NOTIFY]: "Enviar notificação",
};

export const ALERT_TYPES = {
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
};

export const APPROVAL_STATUS = {
  NEED_APPROVAL: 0,
  PENDING_APPROVAL: 1,
  APPROVED: 2,
  DECLINED: 3,
  PENDING_APPROVAL_2: 99,
  DOES_NOT_NEED_APPROVAL: 100,
};

export const APPROVAL_STATUS_TRANSLATION = {
  [APPROVAL_STATUS.NEED_APPROVAL]: "Precisa de aprovação",
  [APPROVAL_STATUS.PENDING_APPROVAL]: "Pendente de aprovação",
  [APPROVAL_STATUS.APPROVED]: "Aprovado",
  [APPROVAL_STATUS.DECLINED]: "Negado",
  [APPROVAL_STATUS.PENDING_APPROVAL_2]: "Pendente de aprovação final",
  [APPROVAL_STATUS.DOES_NOT_NEED_APPROVAL]: "Não precisa de aprovação",
};

export const CAPABILITIES = {
  BASIC: "basic",
  SEARCH: "search",
  TRAVELS: "travels",
  REVIEWS: "reviews",
  APPROVALS: "approvals",
  EXPENSES: "expenses",
  CONFIGURATIONS: "configurations",
  FINANCIAL: "financial",
  ANALYTICS: "analytics",
  BATCH_ACTIONS: "batchActions",
  MANUAL_MANAGEMENT: "manualManagement",
  EXPENSE_FOR_OTHERS: "expenseForOthers",
  APPROVAL_ANY_TIME_FOR_OTHERS: "approvalAnyTimeForOthers",
};

export const CABINS = {
  economy: "economy",
  premiumEconomy: "premium_economy",
  business: "business",
  first: "first",
};

export const CABIN_CLASSES = [
  { name: "Econômica", value: "economy" },
  { name: "Econômica premium", value: "premium_economy" },
  { name: "Business", value: "business" },
  { name: "Primeira classe", value: "first" },
];

export const FLIGHT_TYPE = [
  { name: "Ida e volta", value: "roundtrip" },
  { name: "Só ida", value: "oneway" },
];

export const TRAVEL_FILTER_STATUS = {
  ALL: "all",
  DRAFT: "draft",
  SCHEDULED: "scheduled",
  ONGOING: "ongoing",
  CONCLUDED: "concluded",
  PENDING_APPROVAL: "pending",
  PROCESSING: "processing",
  PROCESSING_ERROR: "processingError",
  TRAVELED: "traveled",
  CANCELED: "canceled",
};

export const TRAVEL_STATUS_LINKS = [
  {
    name: "Todas",
    value: TRAVEL_FILTER_STATUS.ALL,
    title: TITLES.TRIPS,
  },
  {
    name: "Rascunhos",
    value: TRAVEL_FILTER_STATUS.DRAFT,
    title: TITLES.DRAFT_TRIPS,
  },
  {
    name: "Agendadas",
    value: TRAVEL_FILTER_STATUS.SCHEDULED,
    title: TITLES.SCHEDULED_TRIPS,
  },
  {
    name: "Em andamento",
    value: TRAVEL_FILTER_STATUS.ONGOING,
    title: TITLES.ONGOING_TRIPS,
  },
  {
    name: "Viajadas",
    value: TRAVEL_FILTER_STATUS.TRAVELED,
    title: TITLES.TRAVELED_TRIPS,
  },
  {
    name: "Processando pagamento",
    value: TRAVEL_FILTER_STATUS.PROCESSING,
    title: "Viagens em processamento",
  },
  {
    name: "Erro de compra",
    value: TRAVEL_FILTER_STATUS.PROCESSING_ERROR,
    title: "Viagens com erro de compra",
  },
  {
    name: "Cancelada",
    value: TRAVEL_FILTER_STATUS.CANCELED,
    title: "Viagens integralmente canceladas",
  },
  {
    name: "Aprovação em análise",
    value: TRAVEL_FILTER_STATUS.PENDING_APPROVAL,
    title: TITLES.PENDING_APPROVAL_TRIPS,
  },
];

export const APPROVAL_REVIEW_MESSAGES = {
  NO_AVAILABILITY_DECLINE:
    "Sua viagem foi negada automáticamente, por conta da falta de disponibilidade das seguintes ofertas:",
};

export const TRAVELS_PAGE_REDIRECT_MESSAGES = {
  APPROVAL_REQUEST_CANCELED: "A solicitação de aprovação foi cancelada",
  USER_NOT_APPROVER: "Você não possui permissão para revisar esta viagem.",
  USER_NOT_STAGE_APPROVER:
    "Você não possui permissão para revisar esta etapa. É possível que sua etapa já tenha sido revisada por outro aprovador",
  APPROVAL_REQUEST_NOT_PENDING:
    "A viagem selecionada já teve sua revisão realizada.",
  CANNOT_ACCESS_PAYMENT:
    "Você não possui permissão para processar o pagamento da viagem.",
  TRAVEL_STATUS_PAYMENT_NOT_ALLOWED:
    "Reservas não podem ser realizadas no status atual da viagem.",
};

export const EXPENSES_TYPES = {
  FLIGHT: "flight",
  HOTEL: "hotel",
  CAR: "car",
  MEAL: "meal",
  TRANSPORT: "transport",
  OTHER: "other",
  KILOMETER: "kilometer",
  FEE: "fee",
  SEAT: "seat",
  BAGGAGE: "baggage",
  CANCELING: "canceling",
  BUS: "bus",
  INSURANCE: "insurance",
};

export const EXPENSES_CATEGORIES = {
  FLIGHT: "flight",
  APARTMENT: "apartment",
  HOTEL: "hotel",
  KILOMETER: "kilometer",
  RIDE_HAILING: "ride-hailing",
  BREAKFAST: "breakfast",
  LUNCH: "lunch",
  DINNER: "dinner",
  CAR: "car",
  BUS: "bus",
  FEE: "fee",
  EXPENSE_FEE: "expense-fee",
  SEAT: "seat",
  BAGGAGE: "baggage",
  HOTEL_ADDITIONAL: "hotel-additional",
  INSURANCE: "insurance",
  TRANSPORT: "transport",
  CANCELING: "canceling",
  OTHER: "other",
  CUSTOM: "custom",
};
export const EXPENSES_FAMILIES = {
  AIR_TRANSPORT: "air-transport",
  ACCOMMODATION: "accommodation",
  GROUND_TRANSPORT: "ground-transport",
  MEAL: "meal",
  DOCUMENTATION: "documentation",
  OTHER: "other",
};

export const COLOR_BY_EXPENSE_CATEGORY = {
  [EXPENSES_CATEGORIES.FLIGHT]: colors.scooter,
  [EXPENSES_CATEGORIES.HOTEL]: colors.jungleGreen,
  [EXPENSES_CATEGORIES.CAR]: colors.cerulean,
  [EXPENSES_CATEGORIES.BREAKFAST]: colors.saffron,
  [EXPENSES_CATEGORIES.DINNER]: colors.saffron,
  [EXPENSES_CATEGORIES.LUNCH]: colors.saffron,
  [EXPENSES_CATEGORIES.TRANSPORT]: colors.brinkPink,
  [EXPENSES_CATEGORIES.OTHER]: colors.purple,
  [EXPENSES_CATEGORIES.FEE]: colors.fireBush,
  [EXPENSES_CATEGORIES.BAGGAGE]: colors.sunglo,
  [EXPENSES_CATEGORIES.SEAT]: colors.dodgerBlue,
  [EXPENSES_CATEGORIES.CANCELING]: colors.punch,
  [EXPENSES_CATEGORIES.KILOMETER]: colors.tangerine,
  [EXPENSES_CATEGORIES.BUS]: colors.moodyBlue,
  [EXPENSES_CATEGORIES.INSURANCE]: colors.pomegranate,
  [EXPENSES_CATEGORIES.APARTMENT]: colors.jungleGreen,
  [EXPENSES_CATEGORIES.HOTEL_ADDITIONAL]: colors.fern,
  [EXPENSES_CATEGORIES.CUSTOM]: lighten(0.2, colors.purple),
  [EXPENSES_CATEGORIES.RIDE_HAILING]: lighten(0.2, colors.brinkPink),
  [EXPENSES_CATEGORIES.EXPENSE_FEE]: lighten(0.1, colors.fireBush),
};

export const EXPENSE_CHARTS = Object.keys(EXPENSES_CATEGORIES).map((key) => {
  const type = EXPENSES_CATEGORIES[key as keyof typeof EXPENSES_CATEGORIES];
  return {
    type,
    color: COLOR_BY_EXPENSE_CATEGORY[type],
  };
});

export const EXPENSES_TYPES_TRANSLATION = {
  [EXPENSES_TYPES.FLIGHT]: "Voo",
  [EXPENSES_TYPES.HOTEL]: "Hospedagem",
  [EXPENSES_TYPES.CAR]: "Aluguel de Carro",
  [EXPENSES_TYPES.MEAL]: "Alimentação",
  [EXPENSES_TYPES.TRANSPORT]: "Transporte",
  [EXPENSES_TYPES.OTHER]: "Outros",
  [EXPENSES_TYPES.FEE]: "Smartrips",
  [EXPENSES_TYPES.CANCELING]: "Cancelamentos",
  [EXPENSES_TYPES.KILOMETER]: "Quilometragem",
  [EXPENSES_TYPES.SEAT]: "Assento",
  [EXPENSES_TYPES.BAGGAGE]: "Bagagem",
  [EXPENSES_TYPES.BUS]: "Ônibus",
  [EXPENSES_TYPES.INSURANCE]: "Seguro",
};

export const EXPENSES_FAMILIES_TRANSLATION = {
  [EXPENSES_FAMILIES.AIR_TRANSPORT]: "Aéreo",
  [EXPENSES_FAMILIES.ACCOMMODATION]: "Hospedagem",
  [EXPENSES_FAMILIES.GROUND_TRANSPORT]: "Transporte terrestre",
  [EXPENSES_FAMILIES.MEAL]: "Alimentação",
  [EXPENSES_FAMILIES.DOCUMENTATION]: "Documentação",
  [EXPENSES_FAMILIES.OTHER]: "Outros",
};

export const EXPENSES_CATEGORIES_TRANSLATION = {
  [EXPENSES_CATEGORIES.APARTMENT]: {
    [LanguageCode.EN_US]: "Apartment/airbnb",
    [LanguageCode.PT_BR]: "Apartamento/airbnb",
  },
  [EXPENSES_CATEGORIES.BAGGAGE]: {
    [LanguageCode.EN_US]: "Baggage",
    [LanguageCode.PT_BR]: "Bagagem",
  },
  [EXPENSES_CATEGORIES.BREAKFAST]: {
    [LanguageCode.EN_US]: "Breakfast",
    [LanguageCode.PT_BR]: "Café da manhã",
  },
  [EXPENSES_CATEGORIES.BUS]: {
    [LanguageCode.EN_US]: "Bus",
    [LanguageCode.PT_BR]: "Ônibus",
  },
  [EXPENSES_CATEGORIES.CANCELING]: {
    [LanguageCode.EN_US]: "Canceling",
    [LanguageCode.PT_BR]: "Cancelamentos",
  },
  [EXPENSES_CATEGORIES.CAR]: {
    [LanguageCode.EN_US]: "Car",
    [LanguageCode.PT_BR]: "Aluguel de Carro",
  },
  [EXPENSES_CATEGORIES.CUSTOM]: {
    [LanguageCode.EN_US]: "Custom",
    [LanguageCode.PT_BR]: "Personalizado",
  },
  [EXPENSES_CATEGORIES.DINNER]: {
    [LanguageCode.EN_US]: "Dinner",
    [LanguageCode.PT_BR]: "Jantar",
  },
  [EXPENSES_CATEGORIES.EXPENSE_FEE]: {
    [LanguageCode.EN_US]: "Expense fee",
    [LanguageCode.PT_BR]: "Despesas Smartrips",
  },
  [EXPENSES_CATEGORIES.FEE]: {
    [LanguageCode.EN_US]: "Fee",
    [LanguageCode.PT_BR]: "Smartrips",
  },
  [EXPENSES_CATEGORIES.FLIGHT]: {
    [LanguageCode.EN_US]: "Flight",
    [LanguageCode.PT_BR]: "Aéreo",
  },
  [EXPENSES_CATEGORIES.HOTEL_ADDITIONAL]: {
    [LanguageCode.EN_US]: "Hotel additional",
    [LanguageCode.PT_BR]: "Adicionais de hotel",
  },
  [EXPENSES_CATEGORIES.HOTEL]: {
    [LanguageCode.EN_US]: "Hotel",
    [LanguageCode.PT_BR]: "Hotel",
  },
  [EXPENSES_CATEGORIES.INSURANCE]: {
    [LanguageCode.EN_US]: "Insurance",
    [LanguageCode.PT_BR]: "Seguro",
  },
  [EXPENSES_CATEGORIES.KILOMETER]: {
    [LanguageCode.EN_US]: "Kilometer",
    [LanguageCode.PT_BR]: "Quilometragem",
  },
  [EXPENSES_CATEGORIES.LUNCH]: {
    [LanguageCode.EN_US]: "Lunch",
    [LanguageCode.PT_BR]: "Almoço",
  },
  [EXPENSES_CATEGORIES.OTHER]: {
    [LanguageCode.EN_US]: "Other",
    [LanguageCode.PT_BR]: "Outros",
  },
  [EXPENSES_CATEGORIES.RIDE_HAILING]: {
    [LanguageCode.EN_US]: "Uber/Cabify/99",
    [LanguageCode.PT_BR]: "Uber/Cabify/99",
  },
  [EXPENSES_CATEGORIES.SEAT]: {
    [LanguageCode.EN_US]: "Seat",
    [LanguageCode.PT_BR]: "Assento",
  },
  [EXPENSES_CATEGORIES.TRANSPORT]: {
    [LanguageCode.EN_US]: "Transport",
    [LanguageCode.PT_BR]: "Transporte",
  },
};

export const S3_BASE_URL =
  "https://s3.us-east-2.amazonaws.com/smartrips-receipts/";

export const STATIC_MAPS_URL = "https://maps.googleapis.com/maps/api/staticmap";

export const EXPENSE_STATUS = {
  CREATED_AUTO: 1,
  CREATED_MANUAL: 2,
  REPORT_CLOSED: 3,
};

export const EXPENSE_REPORT_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  DECLINED: 3,
  PENDING_APPROVAL: 99,
  PENDING_PAYMENT: 100,
};

export const EXPENSE_APPROVAL_STATUS = {
  NEED_APPROVAL: 0,
  PENDING_APPROVAL: 1,
  PAID: 2,
  DECLINED: 3,
  PENDING_PAYMENT: 99,
  DOES_NOT_NEED_APPROVAL: 100,
};

export const EXPENSE_REPORTS_STATUS_TRANSLATION = {
  1: "Rascunho",
  2: "Pago",
  3: "Negada",
  99: "Aprovação pendente",
  100: "Pagamento pendente",
};

export const COST_CENTERS_OPTIONS_LABELS = {
  SHOW_TO_ALL_USERS: "Tornar todos os centros de custo visíveis para usuários",
  TURN_MANDATORY: "Tornar centro de custo mandatório para todas as viagems",
};

export const APPROVER_LEVEL = {
  FIRST: 1,
  SECOND: 2,
};

export const TOGGLE_COST_CENTER_TEXTS = {
  activate: {
    title: "Tem certeza que deseja ativar a função de centro de custos?",
    message:
      "Ao ativar a função “Centro de custos”, esse campo aparecerá para os usuários e será obrigatório para a compra de viagem, e lançamento de despesas.",
    buttonLabel: "Ativar",
  },
  inactivate: {
    title: "Tem certeza que deseja desativar a função de centro de custos?",
    message:
      "Ao desativar a função “Centro de custos”, esse campo não aparecerá para os usuários, nem será registrado/vinculado a nenhuma compra de viagem, e lançamento de despesas.",
    buttonLabel: "Desativar",
  },
};

export const REQUEST_VOUCHER_EMAIL_SUCCESS_MESSAGE =
  "Requisição de email feita com sucesso. Em instantes você receberá um email com vouchers.";

export const CARD_OWNER_TYPES = {
  CLIENT: "client",
  USER: "user",
};

export const BUDGET_OPERATION_TYPES = {
  withdrawal: 1,
  deposit: 2,
  transfer: 3,
  automatic_expense: 4,
  manual_expense: 5,
};

export const MONTHS = [
  {
    value: 1,
    label: "Janeiro",
  },
  {
    value: 2,
    label: "Fevereiro",
  },
  {
    value: 3,
    label: "Março",
  },
  {
    value: 4,
    label: "Abril",
  },
  {
    value: 5,
    label: "Maio",
  },
  {
    value: 6,
    label: "Junho",
  },
  {
    value: 7,
    label: "Julho",
  },
  {
    value: 8,
    label: "Agosto",
  },
  {
    value: 9,
    label: "Setembro",
  },
  {
    value: 10,
    label: "Outubro",
  },
  {
    value: 11,
    label: "Novembro",
  },
  {
    value: 12,
    label: "Dezembro",
  },
];

export const BUDGETS_CONFIGURATION_TEXTS = {
  TOGGLE_ACTIVE_VAULT:
    "Ao ativar a função de “Budgets”, você deve colocar um valor virtual e simbólico no “Cofre da Empresa”." +
    "\nÉ desse cofre que cada centro de custo irá consumir ao longo das compras de viagem. Sem valor no cofre, ninguém consegue efetuar compra no sistema." +
    "\nVocê pode atribuir limites para cada centro de custo. Os limites podem bloquear o acesso ao cofre, e consequentemente a compra, ou apenas servirem de organização e planejamento, sem bloquear o acesso ao cofre.",
  TOGGLE_INACTIVE_VAULT:
    "\nAo desativar a função de “Budgets”, as compras na plataforma não terão mais qualquer relação com o “Cofre da Empresa”." +
    "\nIsso significa que não haverá limites orçamentários configurados na ferramenta, a nível da empresa e também centros de custos.",
  BUDGETS_LIMIT_ACTIVE:
    "Desejo bloquear as compras após o centro de custo atingir o budget determinado para o mês vigente.",
  BUDGETS_LIMIT_INACTIVE:
    "Compras podem ser realizadas mesmo após o centro de custo ultrapassar o budget determinado.",
};

export const HOTEL_SORT_OPTIONS = {
  PRICE: "PRICE",
  DISTANCE: "DISTANCE",
};

export const NATIONAL_TYPES = {
  NATIONAL: "national",
  INTERNATIONAL: "international",
};

export const CARD_BRANDS = {
  AMEX: "amex",
  VISA: "visa",
  MASTERCARD: "mastercard",
  DINERS: "diners",
  ELO: "elo",
  BANK3: "bank3",
  B2B: "b2b",
};

export const VCNS_TRANSLATIONS = {
  bank3: "BANK 3",
  b2b: "B2B PAY",
};

export const CREDIT_CARD_PROVIDERS = {
  REGULAR: "regular",
  BANK3: "bank3",
  B2B: "b2b",
};

export const AMENITIES_TRANSLATIONS = {
  air: "Ar-condicionado",
  breakfast: "Café da manhã",
  gym: "Academia",
  parking: "Estacionamento",
  reception: "Recepção 24h",
  refund: "Cancelamento grátis",
  restaurant: "Restaurante",
  wifi: "Wifi grátis",
};

export const FAVOURITE_TYPES = {
  GROUP: "g",
  USER: "u",
  COMPANY: "c",
};

export const MEDIA_QUERIES = {
  mobileBreakpoint: "@media (max-width: 767px)",
  tabletBreakpoint: "@media (max-width: 991px)",
  desktopBreakpoint: "@media (min-width: 992px)",
  tabletAndDesktopBreakpoint: "@media (min-width: 768px)",
};

export const CANCEL_TYPES = {
  CREDIT: "credit",
  REFUND: "refund",
  NOSHOW_REFUND: "noshow-refund",
  NOSHOW_CREDIT: "noshow-credit",
  ALTERED: "altered",
  PARTIALLY_ALTERED: "partially-altered",
};

export const CANCEL_TYPES_TRANSLATION = {
  [CANCEL_TYPES.REFUND]: "reembolso",
  [CANCEL_TYPES.CREDIT]: "crédito",
  [CANCEL_TYPES.NOSHOW_CREDIT]: "noshow",
  [CANCEL_TYPES.NOSHOW_REFUND]: "noshow",
  [CANCEL_TYPES.ALTERED]: "alteração",
  [CANCEL_TYPES.PARTIALLY_ALTERED]: "alteração parcial",
};

export const MEDIAS = {
  MOBILE: {
    WIDTH: "512px",
  },
  TABLET: {
    WIDTH: "768px",
  },
  SMALLDESKTOP: {
    WIDTH: "992px",
  },
  DESKTOP: {
    WIDTH: "1224px",
  },
};

export const FLIGHT_ORDER_TYPES = {
  RECOMMENDED: "recommended",
  CHEAPEST: "cheapest",
  FASTEST: "fastest",
  EARLIEST_OUTBOUND_DEPARTURE: "earliest_outbound_departure",
  EARLIEST_INBOUND_DEPARTURE: "earliest_inbound_departure",
  EARLIEST_OUTBOUND_ARRIVAL: "earliest_outbound_arrival",
  EARLIEST_INBOUND_ARRIVAL: "earliest_inbound_arrival",
};

export const FLIGHT_ORDER_TYPES_TRANSLATOR = {
  [FLIGHT_ORDER_TYPES.RECOMMENDED]: "Recomendado",
  [FLIGHT_ORDER_TYPES.CHEAPEST]: "Mais barato",
  [FLIGHT_ORDER_TYPES.FASTEST]: "Mais rápido",
  [FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_DEPARTURE]: "Partida mais cedo - Ida",
  [FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_DEPARTURE]: "Partida mais cedo - Volta",
  [FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_ARRIVAL]: "Chegada mais cedo - Ida",
  [FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_ARRIVAL]: "Chegada mais cedo - Volta",
};

export const FINANCIAL_TABS = {
  FLIGHTS: 1,
  HOTELS: 2,
  CARS: 3,
  BUSES: 4,
  SMARTRIPS: 10,
};

export const FINANCIAL_TYPES = {
  FLIGHT: "flight",
  HOTEL: "hotel",
  CAR: "car",
  BUS: "bus",
  SMARTRIPS: "smartrips",
};

export const OFFER_TRANSACTION_TYPES = {
  EMISSION: "emission",
  CHANGE: "change",
  BAGGAGE: "baggage",
  SEAT: "seat",
};

export const OFFER_TRANSACTION_LABELS = {
  [OFFER_TRANSACTION_TYPES.EMISSION]: "Emissão",
  [OFFER_TRANSACTION_TYPES.CHANGE]: "Alteração",
  [OFFER_TRANSACTION_TYPES.BAGGAGE]: "Bagagem extra",
  [OFFER_TRANSACTION_TYPES.SEAT]: "Escolha de assento antecipada",
};

export const SMARTRIPS_INVOICE_TYPES = {
  TRAVELS: "travels",
  EXPENSES: "expenses",
  REWARDS: "rewards",
  FIXED_MONTHLY_FEE: "fixed-monthly-fee",
};

export const NEW_TRIP_TABS_TRANSLATION = {
  flight: "voos",
  hotel: "hotéis",
};

export const CAR_TRANSMISSION_TYPES = {
  AUTOMATIC: "automatic",
  MANUAL: "manual",
};

export const MEAL_TYPES = {
  BREAKFAST: "b",
  LUNCH: "l",
  DINNER: "d",
};

export const MEAL_TYPES_LABELS = [
  { value: MEAL_TYPES.BREAKFAST, label: "Café da manhã" },
  { value: MEAL_TYPES.LUNCH, label: "Almoço" },
  { value: MEAL_TYPES.DINNER, label: "Jantar" },
];

export const MEAL_TYPES_TRANSLATIONS = {
  b: "Café da manhã",
  l: "Almoço",
  d: "Jantar",
};

export const TARGET_TYPES = {
  USER: "u",
  GROUP: "g",
  COMPANY: "c",
};

export const CAR_RENTAL_OPTIONS = {
  FOCO: "FOCO",
  MOVIDA: "MO",
  LOCALIZA: "LL",
  UNIDAS: "UN",
};

export const BUS_STRETCH_TYPE = {
  RETURN: "return",
  DEPARTURE: "departure",
};

export const INSURANCE_REGIONS = {
  NORTH_AMERICA: 1,
  CARIBBEAN_MEXICO: 3,
  SOUTH_AMERICA: 4,
  AFRICA: 5,
  ASIA: 6,
  OCEANIA: 7,
  MULTIDESTINY: 12,
  NATIONAL: 8,
};

export const COPY_STATUS = {
  PROCESSING: 1,
  SUCCESS: 2,
  PARTIAL_SUCCESS: 3,
  FAILURE: 4,
};

export const CARD_SERVICE_TYPES = {
  GENERAL: "general",
  FLIGHT: "flight",
  CAR: "car",
  HOTEL: "hotel",
  TERRESTRIAL: "terrestrial",
  BUS: "bus",
  FEE: "fee",
};

export const TRANSLATED_CARD_SERVICE_TYPES = {
  [CardServiceType.GENERAL]: "Geral",
  [CardServiceType.FLIGHT]: "Aéreo",
  [CardServiceType.HOTEL]: "Hotel",
  // [CardServiceType.TERRESTRIAL]: "Transporte terrestre (Carro e Ônibus)",
  [CardServiceType.CAR]: "Carro",
  [CardServiceType.BUS]: "Ônibus",
  [CardServiceType.OTHER]: "Outro",
  [CardServiceType.RIDE]: "Táxi",
};

export const TRANSLATED_CARD_SERVICE_TYPES_TAGS = {
  ...TRANSLATED_CARD_SERVICE_TYPES,
  // [CardServiceType.TERRESTRIAL]: "Terrestre"
};

export const SERVICE_TYPES_COLORS = {
  [CardServiceType.GENERAL]: "#B3B3B3",
  [CardServiceType.FLIGHT]: "#B3B3B3",
  [CardServiceType.HOTEL]: "#B3B3B3",
  // [CardServiceType.TERRESTRIAL]: "#FFA500",
  [CardServiceType.CAR]: "#B3B3B3",
  [CardServiceType.RIDE]: "#B3B3B3",
  [CardServiceType.OTHER]: "#B3B3B3",
  [CardServiceType.BUS]: "#B3B3B3",
};

export const FLIGHT_CHECK_IN_STATUS = {
  DOES_NOT_NEED_CHECK_IN: 0,
  NEED_AUTOMATIC_CHECK_IN: 1,
  AUTOMATIC_CHECK_IN_DONE: 2,
};

export const SUPPORT_EVALUATION_RESPONSE_TYPES = {
  SUPPORT_EVALUATION_ALREADY_ANSWERED: "SUPPORT_EVALUATION_ALREADY_ANSWERED",
  SUPPORT_EVALUATION_EXPIRED: "SUPPORT_EVALUATION_EXPIRED",
};

export const CLIENT_REVIEW_TYPES = {
  COMPLETE: "c",
  SPECIFIC: "s",
};

export const CLIENT_REVIEW_SOURCE_TYPES = {
  COST_CENTER: "cc",
};

export const COMPANY_AREA_TYPES = {
  AREA: "area",
  BOARD: "board",
};

export const DEFAULT_COMPANY_AREA_NAME = "Áreas";
export const DEFAULT_COMPANY_BOARD_NAME = "Diretorias";

export const PROJECT_ENABLING_STATE = {
  OFF: "off",
  OPTIONAL: "optional",
  REQUIRED: "required",
};

export const EXPENSE_REPORT_TYPE = {
  SMARTRIPS: "smartrips",
  TRAVEL: "travel",
  REGULAR: "regular",
};

export const EXPENSE_REPORT_TYPE_TRANSLATION = {
  [EXPENSE_REPORT_TYPE.SMARTRIPS]: "Viagem smartrips",
  [EXPENSE_REPORT_TYPE.TRAVEL]: "Viagem manual",
  [EXPENSE_REPORT_TYPE.REGULAR]: "Geral",
};

export const APPROVABLE_TYPES = {
  EXPENSE_REPORT: "expense-report",
  TRAVEL: "travel",
  EXPENSE_ADVANCE: "expense-advance",
};

export const ADVANCED_EXPENSE_STATUS = {
  OPEN: 1,
  CLOSED: 2,
  DECLINED: 3,
  PENDING_APPROVAL: 99,
  PENDING_PAYMENT: 100,
};

export const ADVANCED_EXPENSE_STATUS_LABEL = {
  1: "Solicitado",
  2: "Pago",
  3: "Recusado",
  99: "Aprovação pendente",
  100: "Pagamento pendente",
};

export const ADVANCED_EXPENSE_STATUS_COLOR = {
  1: colors.scooter,
  2: colors.fern,
  3: colors.redError,
  99: colors.brinkPink,
  100: colors.moodyBlue,
};

export const EXPENSES_APPROVAL_STAGES = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
  FOURTH: 4,
  PAYMENT: 99,
};

export const ADVANCED_EXPENSE_STAGE_TRANSLATION = {
  1: "Primeira etapa",
  2: "Segunda etapa",
  3: "Terceira etapa",
  4: "Quarta etapa",
  99: "Pagamento",
};

export const EXPENSE_PAYMENT_METHOD = {
  SMARTRIPS: "smartrips",
  PERSONAL_CREDIT_CARD: "personal",
  CORPORATIVE_CREDIT_CARD: "corporative",
  CASH: "cash",
};

export const EXPENSE_PAYMENT_METHOD_TRANSLATION = {
  [EXPENSE_PAYMENT_METHOD.SMARTRIPS]: "Smartrips",
  [EXPENSE_PAYMENT_METHOD.PERSONAL_CREDIT_CARD]: "Cartão de crédito pessoal",
  [EXPENSE_PAYMENT_METHOD.CORPORATIVE_CREDIT_CARD]:
    "Cartão de crédito corporativo",
  [EXPENSE_PAYMENT_METHOD.CASH]: "Dinheiro",
};

export const CLIENT_PLAN = {
  SMART: "smart",
  PREMIUM: "premium",
  PRO: "pro",
};

export const IMPERMISSIBLE_ANALYTICS_DATA = {
  ECONOMY: [{ total: 0, years: 0, months: 0 }],
  WASTE: [{ total: 0, years: 0, months: 0 }],
  ALTER_FEE: [{ quantity: 0, total: 0, years: 0, months: 0 }],
  ALTER_DIFFERENCE: [{ quantity: 0, total: 0, years: 0, months: 0 }],
  CANCEL_FEE: {
    noshowWastes: [{ quantity: 0, total: 0, years: 0, months: 0 }],
    regularWastes: [{ quantity: 0, total: 0, years: 0, months: 0 }],
  },
};

export const LOYALTY_PROGRAMS = {
  tudo_azul: "Tudo Azul",
  smiles: "Smiles",
  latam_pass: "Latam Pass",
};

export const FETCH_STATUS = {
  IDLE: "idle",
  FETCHING: "fetching",
  ERROR: "error",
  SUCCESS: "success",
};

export const EXPENSE_REPORT_STATUS_TYPE = {
  ALL: "all",
  OPEN: "open",
  PENDING_APPROVAL: "pending-approval",
  PENDING_PAYMENT: "pending-payment",
  PAID: "paid",
  DECLINED: "declined",
};

export const FLIGHT_CARRIERS = {
  AZUL: "azul airlines",
  GOL: "gol",
  LATAM: "latam airlines",
};

export const FLIGHT_CARRIERS_COLORS = {
  [FLIGHT_CARRIERS.AZUL]: "#19B3E9",
  [FLIGHT_CARRIERS.GOL]: "#FF5A00",
  [FLIGHT_CARRIERS.LATAM]: "#1B0088",
};

export const RESERVE_STATUS = {
  UNITIATED: "UNITIATED",
  PENDING: "PENDING",
  FINAL_PENDING: "FINAL_PENDING",
  RESERVED: "RESERVED",
  FAILED: "FAILED",
};

export const CAR_POLICY_CATEGORY_TRANSLATION = {
  [CarPolicyCategory.BASIC]: "Básica",
  [CarPolicyCategory.INTERMEDIATE]: "Intermediária",
  [CarPolicyCategory.ADVANCED]: "Avançada",
  [CarPolicyCategory.SPECIAL]: "Especial",
};

export const PAYMENT_TYPES = {
  CREDIT_CARD: "credit_card",
  DEBT_CARD: "debt_card",
  MONEY: "money",
  BILLED: "billed",
  BILLED_SUPPLIER: "supplier_billed",
};

export const SUPPLIERS = {
  ITERPEC: "ITERPEC",
  MOVIDA: "MOVIDA",
  LOCALIZA: "LOCALIZA",
  CLICKBUS: "CLICKBUS",
  TECH_TRAVEL: "TECH_TRAVEL",
  SKYTEAM: "SKYTEAM",
};

export const BOOKING_FAILED_REASON = {
  PRICE_CHANGE: "PRICE_CHANGE",
  PROCESSING_ERROR: "PROCESSING_ERROR",
  CARD_LIMIT: "CARD_LIMIT",
  CARD_BLOCKED: "CARD_BLOCKED",
  CUSTOMER_REQUEST: "CUSTOMER_REQUEST",
  COMPLETE_UNAVAILABILITY_SOLDOUT: "COMPLETE_UNAVAILABILITY_SOLDOUT",
  OFFER_INTERNAL_CONDITION_SOLDOUT: "OFFER_INTERNAL_CONDITION_SOLDOUT",
  HOTEL_UNAVAILABLE: "HOTEL_UNAVAILABLE",
  NEGOTIATION_UNAVAILABLE: "NEGOTIATION_UNAVAILABLE",
  OTHER: "OTHER",
};

const BOOKING_FAILED_REASON_TRANSLATED = {
  [BOOKING_FAILED_REASON.PRICE_CHANGE]: "Aumentou de preço.",
  [BOOKING_FAILED_REASON.PROCESSING_ERROR]: "Outro",
  [BOOKING_FAILED_REASON.CARD_LIMIT]: "Limite do cartão foi excedido",
  [BOOKING_FAILED_REASON.CARD_BLOCKED]:
    "Cartão nos foi indicado como bloqueado",
  [BOOKING_FAILED_REASON.CUSTOMER_REQUEST]: "O solicitante desistiu da compra",
  [BOOKING_FAILED_REASON.NEGOTIATION_UNAVAILABLE]:
    "Tarifa negociada não ofertada para data selecionada",
};

export const BOOKING_FAILED_REASON_FLIGHT_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "Não há mais disponibilidade no voo selecionado",
  [BOOKING_FAILED_REASON.OFFER_INTERNAL_CONDITION_SOLDOUT]:
    "Indisponibilidade das condições no fornecedor, mas o voo está disponível",
};

export const BOOKING_FAILED_REASON_HOTEL_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "O quarto selecionado não está mais disponível",
  [BOOKING_FAILED_REASON.OFFER_INTERNAL_CONDITION_SOLDOUT]:
    "Indisponibilidade das condições no fornecedor, mas o quarto ainda está disponível",
  [BOOKING_FAILED_REASON.HOTEL_UNAVAILABLE]: "Hotel sem disponibilidade",
};

export const BOOKING_FAILED_REASON_CAR_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "A categoria do carro não está mais disponível na agência selecionada",
};

export const BOOKING_FAILED_REASON_BUS_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "Não há mais disponibilidade no ônibus selecionado",
};

export const TRANSLATED_SSO_CONFIG_PROVIDER = {
  [SSOConfigProvider.MICROSOFT_AZURE]: "Microsoft Azure",
  [SSOConfigProvider.GOOGLE]: "Google",
  [SSOConfigProvider.PING_ID]: "Ping ID",
  [SSOConfigProvider.AUTH0]: "Auth0",
  [SSOConfigProvider.OKTA]: "Okta",
  [SSOConfigProvider.CUSTOM]: "Outro (Customizável)",
};

export const REQUEST_VOUCHER_EMAIL = "Requisição de email feita com sucesso";
