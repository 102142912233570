import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    confirmDelete: "Do you really want to delete the trip",
    delete: "Delete",
    title: "Delete Trip",
  },
  [LanguageCode.PT_BR]: {
    confirmDelete: "Deseja realmente excluir a viagem",
    delete: "Excluir",
    title: "Excluir Viagem",
  },
} as const;

export const useTripDeletionDialogTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
