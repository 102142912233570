import React, { useEffect } from "react";

import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Select } from "~/apps/shared/components/select/select";
import { ThreeWayDateSelect } from "~/apps/shared/components/three-way-date-select/three-way-date-select";
import { DOCUMENT_TYPES_TRANSCRIPTION } from "~/apps/shared/constants";
import { COUNTRY_LIST } from "~/apps/shared/constants/countries.constant";
import { DocumentType } from "~/apps/shared/constants/enums";
import { Option } from "~/apps/shared/types";

import { Button } from "@toolkit/v2";

import { useLanguage } from "../../contexts/language.context";
import { styles } from "./styles";
import { mapUserDocumentFormDefaultValues } from "./user-document-form.helper";
import {
  userDocumentFormSchema,
  UserDocumentFormSchema,
} from "./user-document-form.schema";
import { useUserDocumentFormTranscription } from "./user-document-form.transcription";

const countries = COUNTRY_LIST.map((country) => ({
  label: country.name,
  value: country.code,
}));

type Props = {
  availableDocumentTypes?: Option<DocumentType>[];
  defaultValues?: UserDocumentFormSchema;
  onCancel: () => void;
  onSubmit: (values: UserDocumentFormSchema) => Promise<void>;
};

export const UserDocumentForm: React.FC<Props> = ({
  availableDocumentTypes,
  defaultValues = mapUserDocumentFormDefaultValues(),
  onCancel,
  onSubmit,
}) => {
  const { transcription } = useUserDocumentFormTranscription();

  const { languageCode } = useLanguage();

  const form = useForm<UserDocumentFormSchema>({
    defaultValues,
    onSubmit: async () => {
      const values = form.getValues();

      await onSubmit(values as UserDocumentFormSchema);
    },
    schema: userDocumentFormSchema,
  });

  const { type } = form.watch(["type"]);

  useEffect(() => {
    if (!availableDocumentTypes || availableDocumentTypes.length === 0) {
      return;
    }

    form.setValue("type", availableDocumentTypes[0].value);
  }, [availableDocumentTypes]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        {availableDocumentTypes && availableDocumentTypes.length > 0 ? (
          <div css={styles.body.type.root}>
            {availableDocumentTypes.map((document) => (
              <label css={styles.body.type.radio} key={document.value}>
                <Checkbox
                  checked={form.watch("type") === document.value}
                  onChange={() => {
                    form.reset();
                    form.setValue("type", document.value);
                  }}
                  variant="pink"
                />
                <span css={styles.body.type.label}>{document.label}</span>
              </label>
            ))}
          </div>
        ) : null}
        <Form.Field
          name="firstName"
          render={({ onChange, value }) => (
            <div css={styles.body.input.root}>
              <span css={styles.body.input.label}>
                {transcription.fields.firstName.label}
              </span>
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.firstName.placeholder}
                value={value}
              />
              {form.errors["firstName"] ? (
                <InputErrorMessage>
                  {form.errors["firstName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.body.input.info}>
                  {transcription.fields.firstName.info}
                </span>
              )}
            </div>
          )}
        />
        <Form.Field
          name="lastName"
          render={({ onChange, value }) => (
            <div css={styles.body.input.root}>
              <span css={styles.body.input.label}>
                {transcription.fields.lastName.label}
              </span>
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.lastName.placeholder}
                value={value}
              />
              {form.errors["lastName"] ? (
                <InputErrorMessage>
                  {form.errors["lastName"].message}
                </InputErrorMessage>
              ) : (
                <span css={styles.body.input.info}>
                  {transcription.fields.lastName.info}
                </span>
              )}
            </div>
          )}
        />
        <div css={styles.body.select.root}>
          <span css={styles.body.select.label}>
            {transcription.fields.sex.label}
          </span>
          <Select
            id="sex"
            name="sex"
            onChange={({ value }) => {
              form.setValue("sex", value);
            }}
            options={transcription.fields.sex.options}
            placeholder={transcription.fields.sex.placeholder}
            value={transcription.fields.sex.options.find(
              (sex) => sex.value === form.watch("sex"),
            )}
          />
          <InputErrorMessage>{form.errors["sex"]?.message}</InputErrorMessage>
        </div>
        <div css={styles.body.select.root}>
          <ThreeWayDateSelect
            label={transcription.fields.birthDate}
            onChange={(value) => {
              form.setValue("birthDate", value);
            }}
            type="birthDate"
            value={form.watch("birthDate")}
          />
          <InputErrorMessage>
            {form.errors["birthDate"]?.message}
          </InputErrorMessage>
        </div>
        <hr css={styles.divisor} />
        {type === DocumentType.CPF ||
        type === DocumentType.RG ||
        type === DocumentType.PASSPORT ? (
          <Form.Field
            name="documentNumber"
            render={({ onChange, value }) => (
              <div css={styles.body.input.root}>
                <span css={styles.body.input.label}>
                  {
                    DOCUMENT_TYPES_TRANSCRIPTION[
                      type as keyof typeof DOCUMENT_TYPES_TRANSCRIPTION
                    ][languageCode]
                  }
                </span>
                <Input
                  css={styles.body.input.input}
                  onChange={onChange}
                  placeholder={transcription.fields.documentNumber}
                  value={value}
                />
                <InputErrorMessage>
                  {form.errors["documentNumber"]?.message}
                </InputErrorMessage>
              </div>
            )}
          />
        ) : null}
        {type === DocumentType.PASSPORT && (
          <>
            <div css={styles.body.select.root}>
              <span css={styles.body.select.label}>
                {transcription.fields.issuingCountry.label}
              </span>
              <Select
                id="issuingCountry"
                isSearchable
                name="issuingCountry"
                onChange={({ value }) => {
                  form.setValue("issuingCountry", value);
                }}
                options={countries}
                placeholder={transcription.fields.issuingCountry.placeholder}
                value={countries.find(
                  (country) => country.value === form.watch("issuingCountry"),
                )}
              />
              <InputErrorMessage>
                {form.errors["issuingCountry"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.body.select.root}>
              <ThreeWayDateSelect
                label={transcription.fields.issueDate}
                onChange={(value) => form.setValue("issueDate", value)}
                type="issueDate"
                value={form.watch("issueDate")}
              />
              <InputErrorMessage>
                {form.errors["issueDate"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.body.select.root}>
              <ThreeWayDateSelect
                label={transcription.fields.expirationDate}
                onChange={(value) => form.setValue("expirationDate", value)}
                type="expirationDate"
                value={form.watch("expirationDate")}
              />
              <InputErrorMessage>
                {form.errors["expirationDate"]?.message}
              </InputErrorMessage>
            </div>
            <hr css={styles.divisor} />
            <div css={styles.body.select.root}>
              <span css={styles.body.select.label}>
                {transcription.fields.nationality.label}
              </span>
              <Select
                id="nationality"
                isSearchable
                name="nationality"
                onChange={({ value }) => {
                  form.setValue("nationality", value);
                }}
                options={countries}
                placeholder={transcription.fields.nationality.placeholder}
                value={countries.find(
                  (country) => country.value === form.watch("nationality"),
                )}
              />
              <InputErrorMessage>
                {form.errors["nationality"]?.message}
              </InputErrorMessage>
            </div>
            <div css={styles.body.select.root}>
              <span css={styles.body.select.label}>
                {transcription.fields.residenceCountry.label}
              </span>
              <Select
                id="residenceCountry"
                isSearchable
                name="residenceCountry"
                onChange={({ value }) => {
                  form.setValue("residenceCountry", value);
                }}
                options={countries}
                placeholder={transcription.fields.residenceCountry.placeholder}
                value={countries.find(
                  (country) => country.value === form.watch("residenceCountry"),
                )}
              />
              <InputErrorMessage>
                {form.errors["residenceCountry"]?.message}
              </InputErrorMessage>
            </div>
          </>
        )}
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={form.formState.isSubmitting}
          fill="outlined"
          onClick={() => {
            onCancel();
          }}
          type="button"
        >
          {transcription.buttons.cancel}
        </Button>
        <Button disabled={form.formState.isSubmitting} variant="pink">
          {transcription.buttons.save}
        </Button>
      </div>
    </Form>
  );
};
