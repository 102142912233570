import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    empty: "No loyalty program registered.",
    link: "Add loyalty program",
    menu: {
      edit: "Edit",
      remove: "Remove",
    },
    title: "Loyalty",
  },
  [LanguageCode.PT_BR]: {
    empty: "Nenhum programa de fidelidade cadastrado.",
    link: "Adicionar programa de fidelidade",
    menu: {
      edit: "Editar",
      remove: "Remover",
    },
    title: "Fidelidade",
  },
} as const;

export const useUserProfileDrawerLoyaltySectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
