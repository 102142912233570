import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    dates: {
      checkIn: {
        placeholder: "Check-in*",
      },
      checkOut: {
        placeholder: "Check-out*",
      },
    },
    guests: {
      label: "Guests:",
      placeholder: "Number of people...",
    },
    location: {
      empty: "No place found...",
      label: "Accommodation location*",
      placeholder: "Type in the accommodation location...",
    },
    searchHotels: "Search hotels",
  },
  [LanguageCode.PT_BR]: {
    dates: {
      checkIn: {
        placeholder: "Check-in*",
      },
      checkOut: {
        placeholder: "Check-out*",
      },
    },
    guests: {
      label: "Hóspedes:",
      placeholder: "Quantidade de pessoas...",
    },
    location: {
      empty: "Nenhum local encontrado...",
      label: "Local da hospedagem*",
      placeholder: "Digite o local da hospedagem...",
    },
    searchHotels: "Pesquisar hotéis",
  },
} as const;

export const useHotelFormTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
