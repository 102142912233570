import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    noResults: "No results found for your search...",
  },
  [LanguageCode.PT_BR]: {
    noResults: "Não foi encontrado nenhum resultado para sua busca...",
  },
} as const;

export const useTripsListTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
