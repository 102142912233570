import React, { useCallback, useEffect, useState } from "react";

import { useCurrency } from "~/apps/corporate/contexts/currency.context";
import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LoadingOverlay } from "~/apps/shared/components/loading-overlay/loading-overlay";
import { Select } from "~/apps/shared/components/select/select";
import { CURRENCY_OPTIONS, LANGUAGE_OPTIONS } from "~/apps/shared/constants";
import { CurrencyCode, LanguageCode } from "~/apps/shared/constants/enums";

import { Button } from "@toolkit/v2";

import { useUserProfileDrawerEditUserPreferencesTranscription } from "./edit-user-preferences.transcription";
import { styles } from "./styles";

type Props = {
  back: () => void;
};

export const UserProfileDrawerEditUserPreferences: React.FC<Props> = ({
  back,
}) => {
  const {
    transcription,
  } = useUserProfileDrawerEditUserPreferencesTranscription();

  const {
    changeCurrencyCode,
    currencyCode,
    isLoadingChangeCurrencyCode,
  } = useCurrency();
  const {
    changeLanguageCode,
    isLoadingChangeLanguageCode,
    languageCode,
  } = useLanguage();

  const [
    selectedCurrencyCode,
    setSelectedCurrencyCode,
  ] = useState<CurrencyCode | null>(null);
  const [
    selectedLanguageCode,
    setSelectedLanguageCode,
  ] = useState<LanguageCode | null>(null);

  const handleSubmit = useCallback(async () => {
    if (selectedCurrencyCode) {
      await changeCurrencyCode(selectedCurrencyCode);
    }

    if (selectedLanguageCode) {
      await changeLanguageCode(selectedLanguageCode);
    }
  }, [
    changeCurrencyCode,
    changeLanguageCode,
    selectedCurrencyCode,
    selectedLanguageCode,
  ]);

  useEffect(() => {
    // setSelectedCurrencyCode(currencyCode);
    setSelectedLanguageCode(languageCode);
  }, [currencyCode, languageCode]);

  return (
    <>
      {isLoadingChangeCurrencyCode || isLoadingChangeLanguageCode ? (
        <LoadingOverlay open />
      ) : null}
      <div css={styles.root}>
        <div css={styles.body.root}>
          {/* <div css={styles.body.select.root}>
            <span css={styles.body.select.label}>
              {transcription.fields.currency.label}
            </span>
            <Select
              css={styles.body.select.select}
              isDisabled={isLoadingChangeCurrencyCode}
              onChange={({ value }) => {
                setSelectedCurrencyCode(value as CurrencyCode);
              }}
              options={CURRENCY_OPTIONS}
              placeholder={transcription.fields.currency.placeholder}
              value={CURRENCY_OPTIONS.find(
                (option) => option.value === selectedCurrencyCode,
              )}
            />
          </div> */}
          <div css={styles.body.select.root}>
            <span css={styles.body.select.label}>
              {transcription.fields.language.label}
            </span>
            <Select
              css={styles.body.select.select}
              isDisabled={isLoadingChangeLanguageCode}
              onChange={({ value }) => {
                setSelectedLanguageCode(value as LanguageCode);
              }}
              options={LANGUAGE_OPTIONS}
              placeholder={transcription.fields.language.placeholder}
              value={LANGUAGE_OPTIONS.find(
                (option) => option.value === selectedLanguageCode,
              )}
            />
          </div>
        </div>
        <div css={styles.footer.root}>
          <Button
            fill="outlined"
            onClick={() => {
              back();
            }}
            type="button"
          >
            {transcription.buttons.back}
          </Button>
          <Button
            onClick={async () => {
              await handleSubmit();
            }}
          >
            {transcription.buttons.save}
          </Button>
        </div>
      </div>
    </>
  );
};
