import React from "react";
import Skeleton from "react-loading-skeleton";

import { AsyncAutocomplete } from "~/apps/shared/components/async-autocomplete/async-autocomplete";
import { Icon } from "~/apps/shared/components/icon/icon";
import _ from "lodash";

import { CircularSpinner } from "@toolkit/v2";

import { useNewTrip } from "../../new-trip.context";
import * as newTripService from "../../new-trip.service";
import { styles } from "./styles";
import { useNewTripOfferSearcherTravelerAutocompleteTranscription } from "./traveler-autocomplete.transcription";

type Props = Omit<
  React.ComponentProps<typeof AsyncAutocomplete>,
  "fetchItems"
> & {
  showErrorMessage?: boolean;
};

export const NewTripOfferSearcherTravelerAutocomplete: React.FC<Props> = ({
  showErrorMessage,
  ...props
}) => {
  const { value } = props;

  const {
    transcription,
  } = useNewTripOfferSearcherTravelerAutocompleteTranscription();

  const { defaultBookingTarget } = useNewTrip();

  const error = showErrorMessage && !value;

  return (
    <div css={styles.root({ error })}>
      <AsyncAutocomplete
        css={styles.autocomplete}
        defaultItems={
          defaultBookingTarget
            ? [
                {
                  label: defaultBookingTarget.fullName,
                  email: defaultBookingTarget.email,
                  userToken: defaultBookingTarget.userToken,
                },
              ]
            : undefined
        }
        fetchItems={async (search) => {
          try {
            const bookingTargets = await newTripService.searchBookingTargets(
              search,
            );

            if (bookingTargets.error || !bookingTargets.data) {
              return {
                error: bookingTargets.error,
              };
            }

            const sortedBookingTargets = bookingTargets.data
              .map((bookingTarget) => ({
                ...bookingTarget,
                disabled: !bookingTarget.allowed,
                label: bookingTarget.fullName,
              }))
              .sort((a, b) => {
                if (a.allowed > b.allowed) {
                  return -1;
                }

                if (a.allowed < b.allowed) {
                  return 1;
                }

                return 0;
              });

            return {
              data: sortedBookingTargets,
            };
          } catch (error) {
            return {
              error,
            };
          }
        }}
        inputProps={{
          placeholder: transcription.placeholder,
        }}
        openOnInputClick
        renderInput={({ customOnClick, loading, onClick, ...props }) => (
          <div
            css={styles.input.root}
            onClick={(e) => {
              customOnClick?.();

              onClick?.(e as any);
            }}
          >
            <div css={styles.input.container}>
              {loading ? (
                <CircularSpinner css={styles.input.loading} size={16} />
              ) : null}
              <span css={styles.input.label}>{transcription.label}</span>
              <input css={styles.input.input} {..._.omit(props, "loading")} />
              <Icon css={styles.input.icon} size={14} use="chevron-down" />
            </div>
          </div>
        )}
        renderItem={({ isHighlighted, item, ...props }) => {
          if (item.error) {
            return (
              <li css={styles.item.item} key={0} {...props}>
                {transcription.error}
              </li>
            );
          }

          const isDisabled = item.disabled;

          return (
            <li key={JSON.stringify(item)} {...props}>
              <button
                css={styles.item.button.root({ isDisabled, isHighlighted })}
                disabled={isDisabled}
              >
                <span css={styles.item.button.name}>{item.label}</span>
                <span css={styles.item.button.email}>{item.email}</span>
              </button>
            </li>
          );
        }}
        {...(props as any)}
        onSelect={(traveler) => {
          const isDisabled = traveler.disabled;

          if (isDisabled) {
            return;
          }

          props.onSelect(traveler);
        }}
      />
    </div>
  );
};

export const NewTripOfferSearcherTravelerAutocompleteSkeleton: React.FC = () => {
  return (
    <div css={styles.skeleton}>
      <Skeleton height="16px" width="65px" />
      <Skeleton height="16px" width="186px" />
    </div>
  );
};
