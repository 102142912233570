import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      close: "Close",
    },
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      close: "Fechar",
    },
  },
} as const;

export const useAlertDialogTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
