import React, { useMemo } from "react";
import { useQueryClient } from "react-query";

import { useCards } from "~/apps/corporate/contexts/cards.context";
import { useClientConfig } from "~/apps/corporate/contexts/client-config.context";
import { Card } from "~/apps/corporate/models/card.model";
import { ClientConfigModel } from "~/apps/corporate/models/client.model";
import { invalidateBillingProfilePayableCardsForTravel } from "~/apps/corporate/pages/travels/payment/payment-content/payment-decision/payment-payment.hooks";
import { Checkbox } from "~/apps/shared/components/checkbox-group/checkbox/checkbox";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";

import { Button } from "@toolkit/v2";

import {
  editPaymentMethodSchema,
  EditPaymentMethodSchema,
} from "./edit-payment-method.schema";
import { useEditPaymentMethodTranscription } from "./edit-payment-method.transcription";
import { styles } from "./styles";

type Transcription = ReturnType<
  typeof useEditPaymentMethodTranscription
>["transcription"];

const createServiceTypeOptions = ({
  clientConfigModel,
  transcription,
}: {
  clientConfigModel: ClientConfigModel;
  transcription: Transcription;
}) => {
  const individualCreditCards = clientConfigModel.getIndividualCreditCards();

  return [
    {
      enabled: individualCreditCards.bus,
      key: "enableToBus",
      label: transcription.services.bus,
    },
    {
      enabled: individualCreditCards.car,
      key: "enableToCar",
      label: transcription.services.car,
    },
    {
      enabled: individualCreditCards.flight,
      key: "enableToFlight",
      label: transcription.services.flight,
    },
    {
      enabled: individualCreditCards.hotel,
      key: "enableToHotel",
      label: transcription.services.hotel,
    },
    {
      enabled: individualCreditCards.ride,
      key: "enableToRide",
      label: transcription.services.ride,
    },
    {
      enabled: individualCreditCards.other,
      key: "enableToOther",
      label: transcription.services.other,
    },
  ] as const;
};

type Props = {
  back: () => void;
  card: Card;
};

export const EditPaymentMethod: React.FC<Props> = ({ back, card }) => {
  const queryClient = useQueryClient();

  const { transcription } = useEditPaymentMethodTranscription();

  const { editCard, isLoadingCreateCard, isLoadingEditCard } = useCards();
  const { clientConfig } = useClientConfig();

  const form = useForm<EditPaymentMethodSchema>({
    defaultValues: {
      allowApprovers: card.allowApprovers,
      description: card.description,
      enableToBus: card.serviceTypes.bus,
      enableToCar: card.serviceTypes.car,
      enableToClient: !!card.billingProfileToken,
      enableToFlight: card.serviceTypes.flight,
      enableToHotel: card.serviceTypes.hotel,
      enableToOther: card.serviceTypes.other,
      enableToRide: card.serviceTypes.ride,
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await editCard({
        allowApprovers: values.allowApprovers,
        description: values.description,
        enableToBus: values.enableToBus,
        enableToCar: values.enableToCar,
        enableToClient: values.enableToClient,
        enableToFlight: values.enableToFlight,
        enableToHotel: values.enableToHotel,
        enableToOther: values.enableToOther,
        enableToRide: values.enableToRide,
        token: card.token,
      });

      if (!sucess) {
        return;
      }

      void invalidateBillingProfilePayableCardsForTravel(queryClient);

      back();
    },
    schema: editPaymentMethodSchema,
  });

  const serviceTypeOptions = useMemo(() => {
    if (!clientConfig) {
      return [];
    }

    return createServiceTypeOptions({
      clientConfigModel: clientConfig,
      transcription: transcription,
    });
  }, [clientConfig, transcription]);

  const unavailableServiceTypeOptions = useMemo(() => {
    return serviceTypeOptions.filter((option) => !option.enabled);
  }, [serviceTypeOptions]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.cardNickname.label}
          </span>
          <Form.Field
            name="description"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.cardNickname.placeholder}
                value={value}
              />
            )}
          />
          <InputErrorMessage>
            {form.errors["description"]?.message}
          </InputErrorMessage>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.cardPurposes}
          </span>
          <div css={styles.body.services.root}>
            {serviceTypeOptions.map((option) => (
              <label css={styles.body.services.checkbox} key={option.key}>
                <Checkbox
                  checked={form.watch(option.key)}
                  disabled={!option.enabled}
                  onChange={(e) => {
                    form.setValue(option.key, e.target.checked);
                  }}
                  variant="pink"
                />
                <span
                  css={styles.body.services.label({
                    isDisabled: !option.enabled,
                  })}
                >
                  {option.label}
                </span>
              </label>
            ))}
          </div>
          {form.errors["at-least-one-service-enabled"] ? (
            <InputErrorMessage>
              {form.errors["at-least-one-service-enabled"].message}
            </InputErrorMessage>
          ) : unavailableServiceTypeOptions.length > 0 ? (
            <span css={styles.body.services.info}>
              {transcription.unavailableServiceTypeOptionsInfo(
                unavailableServiceTypeOptions.map(
                  (unavailableServiceTypeOption) =>
                    unavailableServiceTypeOption.label.toLowerCase(),
                ),
              )}
            </span>
          ) : null}
        </div>
        <label css={styles.body.services.checkbox}>
          <Checkbox
            checked={form.watch("allowApprovers")}
            onChange={(e) => {
              form.setValue("allowApprovers", e.target.checked);
            }}
            variant="pink"
          />
          <span
            css={styles.body.services.label({
              isDisabled: false,
            })}
          >
            {transcription.fields.allowApprovers}
          </span>
        </label>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          {transcription.buttons.cancel}
        </Button>
        <Button
          disabled={
            form.formState.isSubmitting ||
            isLoadingCreateCard ||
            isLoadingEditCard
          }
          variant="pink"
        >
          {transcription.buttons.save}
        </Button>
      </div>
    </Form>
  );
};
