import React from "react";

import { Link } from "@reach/router";
import { useApplication } from "~/apps/corporate/contexts/application.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CapabilityProtectedRender } from "~/apps/shared/components/capability-protected-render/capability-protected-render";
import { Logo } from "~/apps/shared/components/logo/logo";
import { capabilities } from "~/apps/shared/constants/enums";
import { useLocation } from "~/apps/shared/hooks/use-location";

import { useNavBarDesktopTranscription } from "./desktop.transcription";
import { NavBarDesktopHelpButton } from "./help-button/help-button";
import { NavBarDesktopMemberGetMemberButton } from "./member-get-member-button/member-get-member-button";
import { NavBarDesktopOthersMenu } from "./others-menu/others-menu";
import { NavBarDesktopProfileMenu } from "./profile-menu/profile-menu";
import { styles } from "./styles";

type Props = {
  handleOpenMemberGetMemebrDrawer: () => void;
  handleOpenUserProfileDrawer: () => void;
};

export const NavBarDesktop: React.FC<Props> = ({
  handleOpenMemberGetMemebrDrawer,
  handleOpenUserProfileDrawer,
}) => {
  const { transcription } = useNavBarDesktopTranscription();

  const { approvalsCount } = useApplication();
  const { user } = useUser();

  const logoClickDestination = user ? (user.canSearch() ? "/" : "/trips") : "/";

  const showLoggedInClientName = user ? user.isHidden() : false;

  return (
    <>
      {showLoggedInClientName && user ? (
        <div css={styles.warning.root}>
          <strong css={styles.warning.strong}>{user.getClientName()}</strong>-{" "}
          {transcription.warning}
        </div>
      ) : null}
      <div css={styles.root}>
        <div css={styles.container}>
          <div css={styles.left.root}>
            <Link css={styles.left.link} to={logoClickDestination}>
              <Logo showName />
            </Link>
          </div>
          <div css={styles.right.root}>
            <nav css={styles.right.nav.root}>
              <ul css={styles.right.nav.links.root}>
                <CapabilityProtectedRender
                  atLeastOne
                  capabilities={[capabilities.SEARCH, capabilities.SELF_SEARCH]}
                >
                  <li css={styles.right.nav.links.item}>
                    <NavBarDesktopLink to="/">
                      {transcription.links.home}
                    </NavBarDesktopLink>
                  </li>
                </CapabilityProtectedRender>
                <li css={styles.right.nav.links.item}>
                  <NavBarDesktopLink to="/trips">
                    {transcription.links.trips}
                  </NavBarDesktopLink>
                </li>
                <CapabilityProtectedRender
                  capabilities={[capabilities.APPROVALS]}
                >
                  <li css={styles.right.nav.links.item}>
                    <NavBarDesktopLink to="/approvals">
                      {transcription.links.approvals}
                      {approvalsCount > 0 ? (
                        <span css={styles.right.nav.links.approvals}>
                          {" "}
                          {approvalsCount}
                        </span>
                      ) : null}
                    </NavBarDesktopLink>
                  </li>
                </CapabilityProtectedRender>
                <li css={styles.right.nav.links.item}>
                  <NavBarDesktopOthersMenu />
                </li>
              </ul>
            </nav>
            <NavBarDesktopHelpButton />
            <NavBarDesktopMemberGetMemberButton
              handleOpenMemberGetMemebrDrawer={handleOpenMemberGetMemebrDrawer}
            />
            <NavBarDesktopProfileMenu
              handleOpenUserProfileDrawer={handleOpenUserProfileDrawer}
            />
          </div>
        </div>
      </div>
    </>
  );
};

type NavBarDesktopLinkProps = {
  aliases?: string[];
  to: string;
};

export const NavBarDesktopLink: React.FC<NavBarDesktopLinkProps> = ({
  aliases,
  children,
  to,
}) => {
  const { location } = useLocation();

  const isCurrent = location.pathname === to;
  const isPartiallyCurrent =
    location.pathname.startsWith(to) ||
    (aliases
      ? aliases.some((alias) => location.pathname.startsWith(alias))
      : false);

  const active = isCurrent || (isPartiallyCurrent && to !== "/");

  return (
    <Link
      css={styles.right.nav.links.link({
        active,
      })}
      to={to}
    >
      {children}
    </Link>
  );
};
