import React from "react";

import { RouteComponentProps } from "@reach/router";

import { Layout } from "../../components/layout/layout";
import { PageTitle } from "../../components/page-title/page-title";
import { pageTitles } from "../../constants/page-titles";
import { styles } from "./styles";
import { TripsList } from "./trips-list/trips-list";
import { TripsSearchbar } from "./trips-searchbar/trips-searchbar";
import { TripsStatusFilter } from "./trips-status-filter/trips-status-filter";
import { TripsTabs } from "./trips-tabs/trips-tabs";
import { TripsTravelerFilter } from "./trips-traveler-filter/trips-traveler-filter";
import { TravelsListProvider } from "./trips.context";
import { useTripsTranscription } from "./trips.transcription";

const Component: React.FC<RouteComponentProps> = () => {
  const { transcription } = useTripsTranscription();

  return (
    <>
      <PageTitle title={pageTitles.TRIPS} />
      <Layout css={styles.layout}>
        <div css={styles.root}>
          <h1 css={styles.title}>{transcription.title}</h1>
          <div css={styles.controls.root}>
            <TripsSearchbar />
            <div css={styles.controls.right}>
              <TripsTravelerFilter />
              <TripsStatusFilter />
            </div>
          </div>
          <TripsTabs />
          <TripsList />
        </div>
      </Layout>
    </>
  );
};

export const Trips: React.FC = () => {
  return (
    <TravelsListProvider>
      <Component />
    </TravelsListProvider>
  );
};
