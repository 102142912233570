import * as bookerTargetApi from "~/apps/corporate/apis/booking-target.api";
import * as flightApi from "~/apps/corporate/apis/flight.api";
import * as hotelApi from "~/apps/corporate/apis/hotel.api";
import { ERROR } from "~/apps/shared/constants/errors";
import { Error } from "~/apps/shared/types";
import { Either, failure, success } from "~/apps/shared/utils/either";
import { formatServiceError } from "~/apps/shared/utils/errors";

import { BookingTarget } from "../../models/booking-target.model";
import { RecentFlightsSearch, RecentHotelsSearch } from "./new-trip.types";

export const getUserRecentFlights = async (
  userToken: string,
): Promise<Either<Error, RecentFlightsSearch[]>> => {
  try {
    return success(await flightApi.getUserRecentFlights(userToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_RECENT_SEARCHES_ERROR,
      }),
    );
  }
};

export const getUserRecentHotels = async (
  userToken: string,
): Promise<Either<Error, RecentHotelsSearch[]>> => {
  try {
    return success(await hotelApi.getUserRecentHotels(userToken));
  } catch (error) {
    return failure(
      formatServiceError({
        error,
        fallback: ERROR.UNEXPECTED_RECENT_SEARCHES_ERROR,
      }),
    );
  }
};

export const searchBookingTargets = async (search: string) => {
  const result: { data?: BookingTarget[]; error?: Error } = {};

  try {
    result.data = await bookerTargetApi.searchTravelBookingTargets(search);
  } catch (error) {
    if (error.response) {
      result.error = ERROR.UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR;
    } else if (error.request) {
      result.error = ERROR.GENERAL_CONNECTION_ERROR;
    } else {
      result.error = ERROR.UNEXPECTED_SEARCH_BOOKING_TARGETS_ERROR;
    }
  }

  return result;
};
