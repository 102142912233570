import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    error: "No traveler found...",
    label: "Traveler:",
    placeholder: "Add traveler...",
  },
  [LanguageCode.PT_BR]: {
    error: "Nenhum viajante encontrado...",
    label: "Viajante:",
    placeholder: "Escolha o viajante...",
  },
} as const;

export const useNewTripOfferSearcherTravelerAutocompleteTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
