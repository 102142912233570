import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      cancel: "Cancel",
      save: "Save",
    },
    fields: {
      birthDate: "Birth date",
      documentNumber: "Enter document number...",
      expirationDate: "Expiration date",
      firstName: {
        info: "Exactly as shown on the traveler's document.",
        label: "First name",
        placeholder: "Enter first name...",
      },
      issueDate: "Issue date",
      issuingCountry: {
        label: "Issuing country",
        placeholder: "Choose issuing country...",
      },
      lastName: {
        info: "Exactly as shown on the traveler's document.",
        label: "Last name",
        placeholder: "Enter last name...",
      },
      nationality: {
        label: "Nationality",
        placeholder: "Choose your nationality...",
      },
      residenceCountry: {
        label: "Country of residence",
        placeholder: "Choose your country of residence...",
      },
      sex: {
        label: "Sex",
        options: [
          { label: "Male", value: "M" },
          { label: "Female", value: "F" },
        ],
        placeholder: "Choose sex...",
      },
    },
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      cancel: "Cancelar",
      save: "Salvar",
    },
    fields: {
      birthDate: "Data de nascimento",
      documentNumber: "Digite o número do documento...",
      expirationDate: "Data de expiração",
      firstName: {
        info: "Exatamente igual ao documento do viajante.",
        label: "Nome",
        placeholder: "Digite o nome...",
      },
      issueDate: "Data de emissão",
      issuingCountry: {
        label: "País emissor",
        placeholder: "Escolha um país emissor...",
      },
      lastName: {
        info: "Exatamente igual ao documento do viajante.",
        label: "Sobrenome",
        placeholder: "Digite o sobrenome...",
      },
      nationality: {
        label: "Nacionalidade",
        placeholder: "Escolha a sua nacionalidade...",
      },
      residenceCountry: {
        label: "País de residência",
        placeholder: "Escolha o seu país de residência...",
      },
      sex: {
        label: "Sexo",
        options: [
          { label: "Masculino", value: "M" },
          { label: "Feminino", value: "F" },
        ],
        placeholder: "Escolha um sexo...",
      },
    },
  },
};

export const useUserDocumentFormTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
