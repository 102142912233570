import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    date: {
      label: "Check-in/check-out",
    },
    guests: "Guests",
    title: "Latest searches",
    traveler: "Traveler",
  },
  [LanguageCode.PT_BR]: {
    date: {
      label: "Entrada e saída",
    },
    guests: "Hóspedes",
    title: "Buscas recentes",
    traveler: "Viajante",
  },
} as const;

export const useNewTripRecentHotelsTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
