import React, { useEffect, useState } from "react";

import {
  hotelFormSchema,
  HotelFormSchema,
} from "~/apps/corporate/components/offer-searcher/hotel-form/hotel-form.schema";
import { HotelsSearch } from "~/apps/corporate/components/offer-searcher/offer-searcher.types";
import { BookingTarget } from "~/apps/corporate/models/booking-target.model";
import { DatePicker } from "~/apps/shared/components/date-picker/date-picker";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { Icon } from "~/apps/shared/components/icon/icon";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { LocationAutocomplete } from "~/apps/shared/components/location-autocomplete/location-autocomplete";
import { TextField } from "~/apps/shared/components/text-field/text-field";
import { ServiceType } from "~/apps/shared/constants/enums";

import { Button, CircularSpinner } from "@toolkit/v2";

import { useHotelFormTranscription } from "../../../../components/offer-searcher/hotel-form/hotel-form.transcription";
import { useNewTrip } from "../../new-trip.context";
import { NewTripOfferSearcherTravelerAutocomplete } from "../traveler-autocomplete/traveler-autocomplete";
import { styles } from "./styles";

type Props = {
  handleSearchHotels: (hotelsSearch: HotelsSearch) => Promise<void>;
};

export const NewTripOfferSearcherHotelForm: React.FC<Props> = ({
  handleSearchHotels,
}) => {
  const { selectedSearch } = useNewTrip();
  const { transcription } = useHotelFormTranscription();

  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [rangeDateFocus, setRangeFocus] = useState<
    "endDate" | "startDate" | null
  >(null);
  const [traveler, setTraveler] = useState<BookingTarget | null>(null);

  const form = useForm<HotelFormSchema>({
    defaultValues: {
      endDate: null,
      location: null,
      startDate: null,
      totalGuests: 1,
    } as HotelFormSchema,
    onSubmit: async () => {
      if (!traveler) {
        return;
      }

      const values = form.getValues();

      await handleSearchHotels({
        endDate: values.endDate,
        location: values.location,
        startDate: values.startDate,
        totalGuests: values.totalGuests,
        traveler,
      });
    },
    schema: hotelFormSchema,
  });

  const showTravelerErrorMessage =
    (hasTriedToSubmit && !traveler) || (!!traveler && !traveler.allowed);

  useEffect(() => {
    if (!selectedSearch || selectedSearch.type !== ServiceType.HOTEL) {
      return;
    }

    form.setValue("endDate", selectedSearch.data.endDate);
    form.setValue("location", selectedSearch.data.location);
    form.setValue("startDate", selectedSearch.data.startDate);
    form.setValue("totalGuests", selectedSearch.data.totalGuests);

    if (selectedSearch.data.traveler) {
      setTraveler(selectedSearch.data.traveler);
    }

    void form.trigger(["endDate", "location", "startDate", "totalGuests"]);
  }, [selectedSearch]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.top.root}>
        <NewTripOfferSearcherTravelerAutocomplete
          onChange={(value) => {
            if (!value) {
              setTraveler(null);

              return;
            }

            setTraveler((prev) => {
              if (!prev) {
                return {
                  fullName: value,
                } as BookingTarget;
              }

              return {
                ...prev,
                fullName: value,
              };
            });
          }}
          onSelect={(value) => {
            setTraveler({
              allowed: value.allowed,
              email: value.email,
              fullName: value.label,
              userToken: value.userToken,
            });
          }}
          showErrorMessage={showTravelerErrorMessage}
          value={traveler ? traveler.fullName : undefined}
        />
        <div css={styles.top.control.root}>
          <span css={styles.top.control.label}>
            {transcription.guests.label}&nbsp;
          </span>
          <Form.Field<HotelFormSchema["totalGuests"]>
            name="totalGuests"
            render={({ value }) => (
              <div css={styles.top.control.guests.root}>
                <Button
                  css={styles.top.control.guests.button}
                  disabled={value === 1}
                  fill="outlined"
                  onClick={() => {
                    form.setValue(
                      "totalGuests",
                      Number(value) - 1 <= 0 ? 1 : Number(value) - 1,
                    );
                  }}
                  type="button"
                  variant="pink"
                >
                  <Icon size={12} use="minus" />
                </Button>
                <span css={styles.top.control.guests.value}>{value}</span>
                <Button
                  css={styles.top.control.guests.button}
                  onClick={() => {
                    form.setValue("totalGuests", Number(value) + 1);
                  }}
                  type="button"
                  variant="pink"
                >
                  <Icon size={14} use="plus" />
                </Button>
              </div>
            )}
          />
          <InputErrorMessage css={styles.top.control.error}>
            {form.errors["totalGuests"]?.message}
          </InputErrorMessage>
        </div>
      </div>
      <div css={styles.center.root}>
        <div css={styles.center.left.root}>
          <div css={styles.center.control.root}>
            <div css={styles.center.left.location.container}>
              <div css={styles.center.left.location.root}>
                <Form.Field<HotelFormSchema["location"] | undefined>
                  name="location"
                  render={({ value }) => (
                    <LocationAutocomplete
                      css={styles.center.left.location.location}
                      placeholder="Digite o local da hospedagem..."
                      onSelect={(location) => {
                        if (!location) {
                          form.setValue("location", null);

                          return;
                        }

                        form.setValue("location", {
                          latitude: location.latitude,
                          longitude: location.longitude,
                          search: location.formattedAddress,
                        });
                      }}
                      renderInput={({ loading, ...props }) => (
                        <div css={styles.center.left.location.input.root}>
                          {loading ? (
                            <CircularSpinner
                              css={styles.center.left.location.input.loading}
                              size={20}
                            />
                          ) : (
                            <Icon size={20} use="building" />
                          )}
                          <TextField
                            css={styles.center.left.location.input.input}
                            inputProps={{ ...props }}
                            label={transcription.location.label}
                            variant="outlined"
                          />
                        </div>
                      )}
                      value={value?.search}
                    />
                  )}
                />
              </div>
            </div>
            <InputErrorMessage css={styles.top.control.error}>
              {form.errors["location"]?.message ||
                form.errors["location"]?.["search"]?.message}
            </InputErrorMessage>
          </div>
        </div>
        <div css={styles.center.right.root}>
          <div css={styles.center.control.root}>
            <div css={styles.center.right.dates.container}>
              <div css={styles.center.right.dates.root}>
                <DatePicker
                  allowSameDay
                  anchorDirection="right"
                  customArrowIcon={
                    <div css={styles.center.right.dates.arrow.root}>
                      <span css={styles.center.right.dates.arrow.divisor}>
                        |
                      </span>
                      <Icon size={16} use="calendar" />
                    </div>
                  }
                  endDate={form.watch("endDate")}
                  endDatePlaceholderText={
                    transcription.dates.checkOut.placeholder
                  }
                  focusedInput={rangeDateFocus}
                  onDatesChange={(date) => {
                    form.setValue("startDate", date.startDate);

                    if (date.startDate) {
                      form.clearErrors("startDate");
                    }

                    form.setValue("endDate", date.endDate);

                    if (date.endDate) {
                      form.clearErrors("endDate");
                    }
                  }}
                  onFocusChange={(focused) => {
                    setRangeFocus(focused);
                  }}
                  range
                  renderCalendarInfo={undefined}
                  startDate={form.watch("startDate")}
                  startDatePlaceholderText={
                    transcription.dates.checkIn.placeholder
                  }
                />
              </div>
            </div>
            <InputErrorMessage css={styles.top.control.error}>
              {form.errors["startDate"]?.message ||
                form.errors["endDate"]?.message}
            </InputErrorMessage>
          </div>
          <Button
            css={styles.button}
            onClick={() => {
              setHasTriedToSubmit(true);
            }}
            variant="pink"
          >
            <Icon use="search-outline" />
          </Button>
        </div>
      </div>
    </Form>
  );
};
