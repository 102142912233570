import React from "react";

import { useCurrency } from "~/apps/corporate/contexts/currency.context";
import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { CURRENCY_OPTIONS, LANGUAGE_OPTIONS } from "~/apps/shared/constants";

import { useUserProfileDrawer } from "../../../user-profile-drawer.context";
import { styles } from "./styles";
import { useUserProfileDrawerUserPreferencesSectionTranscription } from "./user-preferences-section.transcription";

export const UserProfileDrawerUserPreferencesSection: React.FC = () => {
  const { currencyCode } = useCurrency();
  const { languageCode } = useLanguage();
  const { user } = useUser();

  const { editUserPreferences } = useUserProfileDrawer();
  const {
    transcription,
  } = useUserProfileDrawerUserPreferencesSectionTranscription();

  if (!user) {
    return null;
  }

  const selectedCurrency = CURRENCY_OPTIONS.find(
    (option) => option.value === currencyCode,
  );
  const selectedLanguage = LANGUAGE_OPTIONS.find(
    (option) => option.value === languageCode,
  );

  return (
    <div css={styles.root}>
      <div css={styles.header.root}>
        <span css={styles.header.title}>{transcription.title}</span>
        <button
          css={styles.header.button}
          onClick={() => {
            editUserPreferences();
          }}
        >
          {transcription.edit}
        </button>
      </div>
      <div css={styles.basic.info.root}>
        {/* {selectedCurrency ? (
          <div css={styles.basic.info.row.root}>
            <span css={styles.basic.info.row.label}>
              {transcription.currency}
            </span>
            <span css={styles.basic.info.row.value}>
              {selectedCurrency.label}
            </span>
          </div>
        ) : null} */}
        {selectedLanguage ? (
          <div css={styles.basic.info.row.root}>
            <span css={styles.basic.info.row.label}>
              {transcription.language}
            </span>
            <span css={styles.basic.info.row.value}>
              {selectedLanguage.label}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
