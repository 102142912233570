import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  footer: {
    root: css({
      backgroundColor: theme.colors.background.gray,
      borderTop: `1px solid ${theme.colors.gray[100]}`,
      bottom: 0,
      display: "flex",
      gap: "16px",
      justifyContent: "flex-end",
      padding: "24px",
      position: "sticky",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
  },
  body: {
    root: css({
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      gap: "16px",
      padding: "24px",
      width: "100%",
      "@media screen and (max-width: 768px)": {
        padding: "16px",
      },
    }),
    select: {
      label: css({
        color: theme.colors.gray[700],
        fontWeight: 600,
      }),
      root: css({
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }),
      select: css({
        width: "100%",
      }),
    },
  },
  root: css({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  }),
};
