import React, { useState, useEffect, useCallback, createContext } from "react";

import * as tagsService from "~/apps/corporate/pages/configurations/views/company/tags/tags.service";
import { ALERT_TYPES } from "~/apps/shared/constants";
import {
  TripsStatusFilter,
  TripsTabsFilter,
} from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";
import { Error } from "~/apps/shared/types";

import { useApplication } from "../../contexts/application.context";
import { useUser } from "../../contexts/user.context";
import { GetUserRewardsPointsResponse } from "../../dtos/user.dto";
import { TravelTag } from "../../models/tags.models";
import { TravelsCount, UserTravel } from "../../models/travel.model";
import * as tripsService from "./trips.service";
import { FetchTravelsCountParams } from "./trips.types";

interface TravelsFiltersActions {
  handleChangePendingFilters: (pendingFilters: TripsStatusFilter[]) => void;
  handleChangeSearchFilter: (search: string) => void;
  handleChangeTabFilter: (tabFilter: TripsTabsFilter) => void;
  handleChangeUserFilter: (userToken: string) => void;
  pendingFilters: TripsStatusFilter[];
  tabFilter: TripsTabsFilter;
}
interface TravelsListActions {
  fetchTravelsCount: (params: FetchTravelsCountParams) => Promise<void>;
  loadMoreTravels: () => Promise<void>;
}
interface TravelsListDeletionActions {
  handleCloseDeletionDialog: () => void;
  handleSelectToDelete: (travel: UserTravel) => void;
  processTravelDeletion: () => Promise<void>;
}
interface TravelsSmartPointsActions {
  handleClosePointsExplanation: () => void;
  handleOpenPointsExplanation: () => void;
}
interface TravelsTagsActions {
  loadTagConfigOptions: () => Promise<void>;
  loadUserTravelTags: () => Promise<void>;
}

type DeletionState = {
  isDeleting: boolean;
  isDeletionDialogVisible: boolean;
  selectedTravel: UserTravel | null;
};
type FiltersState = {
  pendingFilters: TripsStatusFilter[];
  search: string;
  tabFilter: TripsTabsFilter;
  userToken: string;
};
type SmartPointsState = {
  isExplanationVisible: boolean;
  pointsInfo: GetUserRewardsPointsResponse;
};
type TagsState = {
  isLoading: boolean;
  onlyAdminOption: boolean | null;
  requiredTravelTagOption: boolean | null;
  tags: {
    [travelToken: string]: TravelTag[];
  };
};
type TravelsListState = {
  currentPage: number;
  isLoadingTravelsCount: boolean;
  loading: boolean;
  loadingError: Error | null;
  loadingMore: boolean;
  totalPages: number;
  travels: UserTravel[];
  travelsCount: TravelsCount;
};

const deletionInitialState: DeletionState = {
  isDeleting: false,
  isDeletionDialogVisible: false,
  selectedTravel: null,
};
const filtersInitialState: FiltersState = {
  pendingFilters: [],
  search: "",
  tabFilter: TripsTabsFilter.ALL,
  userToken: "",
};
const smartPointsInitialState: SmartPointsState = {
  isExplanationVisible: false,
  pointsInfo: {
    notPaidPoints: 0,
    paidPoints: 0,
    rewardActive: false,
  },
};
const tagsInitialState: TagsState = {
  isLoading: false,
  onlyAdminOption: null,
  requiredTravelTagOption: null,
  tags: {},
};
const travelsListInitialState: TravelsListState = {
  currentPage: 1,
  isLoadingTravelsCount: false,
  loading: false,
  loadingError: null,
  loadingMore: false,
  totalPages: 1,
  travels: [],
  travelsCount: {
    all: 0,
    canceled: 0,
    draft: 0,
    now: 0,
    past: 0,
    upcoming: 0,
  },
};

const TravelsFiltersActionsContext = createContext<TravelsFiltersActions>(
  {} as TravelsFiltersActions,
);
const TravelsFiltersContext = createContext<FiltersState>({
  ...filtersInitialState,
});
const TravelsListActionsContext = createContext<TravelsListActions>(
  {} as TravelsListActions,
);
const TravelsListContext = createContext<TravelsListState>({
  ...travelsListInitialState,
});
const TravelsListDeletionActionsContext = createContext<TravelsListDeletionActions>(
  {} as TravelsListDeletionActions,
);
const TravelsListDeletionContext = createContext<DeletionState>({
  ...deletionInitialState,
});
const TravelsSmartPointsActionsContext = createContext<TravelsSmartPointsActions>(
  {} as TravelsSmartPointsActions,
);
const TravelsSmartPointsContext = createContext<SmartPointsState>({
  ...smartPointsInitialState,
});
const TravelsTagsActionsContext = createContext<TravelsTagsActions>(
  {} as TravelsTagsActions,
);
const TravelsTagsContext = createContext<TagsState>({
  ...tagsInitialState,
});

export const TravelsListProvider: React.FC = ({ children }) => {
  const { showSnackMessage } = useApplication();
  const { user } = useUser();

  const [deletionState, setDeletionState] = useState<DeletionState>(
    deletionInitialState,
  );
  const [filtersState, setFiltersState] = useState<FiltersState>(
    filtersInitialState,
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [smartPointsState, setSmartPointsState] = useState<SmartPointsState>(
    smartPointsInitialState,
  );
  const [tagsState, setTagsState] = useState<TagsState>(tagsInitialState);
  const [travelListState, setTravelsListState] = useState<TravelsListState>(
    travelsListInitialState,
  );

  const fetchTravelsCount = useCallback(
    async ({ pendingFilters, search, userToken }: FetchTravelsCountParams) => {
      if (!user) {
        return;
      }

      setTravelsListState((prev) => ({
        ...prev,
        isLoadingTravelsCount: true,
        travelsCount: {
          all: 0,
          canceled: 0,
          draft: 0,
          now: 0,
          past: 0,
          upcoming: 0,
        },
      }));

      const getTravelsCountResponse = await tripsService.getTravelsCount({
        filters: {
          search,
          travelerToken: userToken,
          pendingFilters,
        },
        userToken: user.getUserToken(),
      });

      if (getTravelsCountResponse.isFailure()) {
        setTravelsListState((prev) => ({
          ...prev,
          isLoadingTravelsCount: false,
        }));

        return;
      }

      setTravelsListState((prev) => ({
        ...prev,
        isLoadingTravelsCount: false,
        travelsCount: getTravelsCountResponse.data,
      }));
    },
    [],
  );

  // Deletion

  const handleSelectToDelete = (travel: UserTravel) => {
    setDeletionState((prev) => ({
      ...prev,
      selectedTravel: travel,
      isDeletionDialogVisible: true,
    }));
  };

  const handleCloseDeletionDialog = () => {
    if (deletionState.isDeleting) {
      return null;
    }

    setDeletionState((prev) => ({
      ...prev,
      selectedTravel: null,
      isDeletionDialogVisible: false,
    }));
  };

  const processTravelDeletion = async () => {
    const { selectedTravel } = deletionState;

    if (!selectedTravel) {
      return;
    }

    setDeletionState((prev) => ({
      ...prev,
      isDeleting: true,
    }));

    const { error } = await tripsService.deleteTravel(selectedTravel.travelId);

    if (error) {
      setDeletionState((prev) => ({ ...prev, isDeleting: false }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    setTravelsListState((prev) => ({
      ...prev,
      travels: prev.travels.filter(
        (travel) => travel.travelId !== selectedTravel.travelId,
      ),
    }));

    void fetchTravelsCount({
      pendingFilters: filtersState.pendingFilters,
      search: filtersState.search,
      userToken: filtersState.userToken,
    });

    setDeletionState((prev) => ({
      ...prev,
      isDeleting: false,
      isDeletionDialogVisible: false,
      selectedTravel: null,
    }));

    showSnackMessage("Viagem excluída com sucesso", ALERT_TYPES.SUCCESS);
  };

  // Travels List

  const fetchData = useCallback(async () => {
    if (!user) {
      return;
    }

    setTravelsListState((prev) => ({
      ...prev,
      loading: true,
    }));

    const params = {
      page: 1,
      ...filtersState,
    };

    const getUserTravelsResponse = await tripsService.getUserTravels(params);

    if (getUserTravelsResponse.isFailure()) {
      const error = getUserTravelsResponse.data;

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setTravelsListState((prev) => ({
        ...prev,
        loading: false,
        loadingError: error,
      }));

      return;
    }

    if (isFirstRender) {
      if (user.hasGamificationPermission()) {
        const getUserRewardsPointsResponse = await tripsService.getUserRewardsPoints(
          user.getUserToken(),
        );

        if (getUserRewardsPointsResponse.isFailure()) {
          const error = getUserRewardsPointsResponse.data;

          showSnackMessage(error.description, ALERT_TYPES.ERROR);

          return;
        }

        setSmartPointsState((prev) => ({
          ...prev,
          pointsInfo: getUserRewardsPointsResponse.data,
        }));
      }

      setIsFirstRender(false);
    }

    const { metadata, travels } = getUserTravelsResponse.data;

    setTravelsListState((prev) => ({
      ...prev,
      currentPage: metadata.current,
      loading: false,
      totalPages: metadata.pages,
      travels: travels,
    }));
  }, [filtersState, user]);

  const loadMoreTravels = async () => {
    setTravelsListState((prev) => ({
      ...prev,
      loadingMore: true,
    }));

    const nextPage = travelListState.currentPage + 1;

    const params = {
      page: nextPage,
      ...filtersState,
    };

    const getUserTravelsResponse = await tripsService.getUserTravels(params);

    if (getUserTravelsResponse.isFailure()) {
      const error = getUserTravelsResponse.data;

      setTravelsListState((prev) => ({
        ...prev,
        loadingMore: false,
        loadingError: error,
      }));

      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      return;
    }

    const { metadata, travels } = getUserTravelsResponse.data;

    setTravelsListState((prev) => ({
      ...prev,
      currentPage: metadata.current,
      loadingMore: false,
      totalPages: metadata.pages,
      travels: [...prev.travels, ...travels],
    }));
  };

  // Filters

  const handleChangePendingFilters = (pendingFilters: TripsStatusFilter[]) => {
    setFiltersState((prev) => ({
      ...prev,
      pendingFilters,
    }));
  };

  const handleChangeSearchFilter = (search: string) => {
    setFiltersState((prev) => ({
      ...prev,
      search,
    }));
  };

  const handleChangeTabFilter = (tabFilter: TripsTabsFilter) => {
    setFiltersState((prev) => ({
      ...prev,
      tabFilter,
    }));
  };

  const handleChangeUserFilter = (userToken: string) => {
    setFiltersState((prev) => ({
      ...prev,
      userToken,
    }));
  };

  // Points

  const handleClosePointsExplanation = () => {
    setSmartPointsState((prev) => ({
      ...prev,
      isExplanationVisible: false,
    }));
  };

  const handleOpenPointsExplanation = () => {
    setSmartPointsState((prev) => ({
      ...prev,
      isExplanationVisible: true,
    }));
  };

  // Tags

  const loadUserTravelTags = useCallback(async () => {
    if (!user) {
      return;
    }

    setTagsState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    const { data, error } = await tagsService.getUserTravelTags(
      user.getUserToken(),
    );

    if (error) {
      showSnackMessage(error.description, ALERT_TYPES.ERROR);

      setTagsState((prev) => ({
        ...prev,
        isLoading: false,
        tags: {},
      }));

      return;
    }

    setTagsState((prev) => ({
      ...prev,
      isLoading: false,
      tags: data,
    }));
  }, [user]);

  const loadTagConfigOptions = async () => {
    const { onlyAdminOption, requiredTravelTagOption } = tagsState;

    if (onlyAdminOption === null || requiredTravelTagOption === null) {
      const {
        data: clientTagOptions,
        error,
      } = await tagsService.getClientTagOptions();

      if (error) {
        showSnackMessage(error.description, ALERT_TYPES.ERROR);

        return;
      }

      const { tagsAdminOnly, travelTagsRequired } = clientTagOptions;

      setTagsState((prev) => ({
        ...prev,
        onlyAdminOption: tagsAdminOnly,
        requiredTravelTagOption: travelTagsRequired,
      }));
    }
  };

  useEffect(() => {
    void fetchData();
  }, [
    filtersState.search,
    filtersState.userToken,
    filtersState.tabFilter,
    filtersState.pendingFilters,
  ]);

  useEffect(() => {
    void loadTagConfigOptions();
  }, []);

  return (
    <TravelsListContext.Provider
      value={{
        ...travelListState,
      }}
    >
      <TravelsListActionsContext.Provider
        value={{
          fetchTravelsCount,
          loadMoreTravels,
        }}
      >
        <TravelsFiltersContext.Provider value={{ ...filtersState }}>
          <TravelsFiltersActionsContext.Provider
            value={{
              handleChangePendingFilters,
              handleChangeSearchFilter,
              handleChangeTabFilter,
              handleChangeUserFilter,
              pendingFilters: filtersState.pendingFilters,
              tabFilter: filtersState.tabFilter,
            }}
          >
            <TravelsSmartPointsContext.Provider value={{ ...smartPointsState }}>
              <TravelsSmartPointsActionsContext.Provider
                value={{
                  handleOpenPointsExplanation,
                  handleClosePointsExplanation,
                }}
              >
                <TravelsListDeletionContext.Provider
                  value={{ ...deletionState }}
                >
                  <TravelsListDeletionActionsContext.Provider
                    value={{
                      handleSelectToDelete,
                      handleCloseDeletionDialog,
                      processTravelDeletion,
                    }}
                  >
                    <TravelsTagsContext.Provider
                      value={{
                        ...tagsState,
                      }}
                    >
                      <TravelsTagsActionsContext.Provider
                        value={{
                          loadUserTravelTags,
                          loadTagConfigOptions,
                        }}
                      >
                        {children}
                      </TravelsTagsActionsContext.Provider>
                    </TravelsTagsContext.Provider>
                  </TravelsListDeletionActionsContext.Provider>
                </TravelsListDeletionContext.Provider>
              </TravelsSmartPointsActionsContext.Provider>
            </TravelsSmartPointsContext.Provider>
          </TravelsFiltersActionsContext.Provider>
        </TravelsFiltersContext.Provider>
      </TravelsListActionsContext.Provider>
    </TravelsListContext.Provider>
  );
};

export const useTravelsListActions = useContextFactory(
  "TravelsListActionsContext",
  TravelsListActionsContext,
);
export const useTravelsList = useContextFactory(
  "TravelsListContext",
  TravelsListContext,
);
export const useTravelsFiltersActions = useContextFactory(
  "TravelsFiltersActionsContext",
  TravelsFiltersActionsContext,
);
export const useTravelsFilters = useContextFactory(
  "TravelsFiltersContext",
  TravelsFiltersContext,
);
export const useTravelsListDeletionActions = useContextFactory(
  "TravelsListDeletionActionsContext",
  TravelsListDeletionActionsContext,
);
export const useTravelsListDeletion = useContextFactory(
  "TravelsListDeletionContext",
  TravelsListDeletionContext,
);
export const useTravelsSmartPointsActions = useContextFactory(
  "TravelsSmartPointsActionsContext",
  TravelsSmartPointsActionsContext,
);
export const useTravelsSmartPoints = useContextFactory(
  "TravelsSmartPointsContext",
  TravelsSmartPointsContext,
);
export const useTravelsTagsActions = useContextFactory(
  "TravelsTagsActionsContext",
  TravelsTagsActionsContext,
);
export const useTravelsTags = useContextFactory(
  "TravelsTagsContext",
  TravelsTagsContext,
);
