import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    rentalDays: "day(s)",
    rentalWith: "rental with",
    modelDescription: "Model",
  },
  [LanguageCode.PT_BR]: {
    rentalDays: "dia(s)",
    rentalWith: "de aluguel com",
    modelDescription: "Modelo",
  },
} as const;

export const useTripCardCarOfferTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
