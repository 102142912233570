import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    approvalProcess: "User approval process",
    billingProfile: "CNPJ",
    buttons: {
      cancel: "Cancel",
      save: "Save",
    },
    fields: {
      firstName: {
        label: "First name*",
        placeholder: "Enter first name...",
      },
      lastName: {
        label: "Last name*",
        placeholder: "Enter last name...",
      },
      phone: {
        label: "Phone",
        placeholder: "Enter phone number...",
      },
    },
    placeholders: {
      approvalProcess: "Choose a user approval process...",
      billingProfile: "Choose a CNPJ...",
    },
    smsNotifications: "Receive SMS notifications",
  },
  [LanguageCode.PT_BR]: {
    approvalProcess: "Processo de aprovação de usuário",
    billingProfile: "CNPJ",
    buttons: {
      cancel: "Cancelar",
      save: "Salvar",
    },
    fields: {
      firstName: {
        label: "Nome*",
        placeholder: "Digite o nome...",
      },
      lastName: {
        label: "Sobrenome*",
        placeholder: "Digite o sobrenome...",
      },
      phone: {
        label: "Celular",
        placeholder: "Digite o número do celular...",
      },
    },
    placeholders: {
      approvalProcess: "Escolha um processo de aprovação de usuário...",
      billingProfile: "Escolha um CNPJ...",
    },
    smsNotifications: "Receber informações por SMS",
  },
} as const;

export const useUserProfileDrawerEditBasicInformationTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
