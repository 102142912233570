import React, { useEffect } from "react";

import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { useNewTrip } from "../new-trip.context";
import { useNewTripRecentFlightsTranscription } from "./recent-flights.transcription";
import { styles } from "./styles";

const formatDate = (date: string) => {
  return moment(date).format("DD MMM");
};

const Component: React.FC = () => {
  const { transcription } = useNewTripRecentFlightsTranscription();

  const {
    fetchRecentFlights,
    handlePopulateFlightsSearchWithRecent,
    recentFlightsSearches,
  } = useNewTrip();

  useEffect(() => {
    void fetchRecentFlights();
  }, []);

  if (recentFlightsSearches.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>{transcription.title}</span>
      <div css={styles.cards.root}>
        {recentFlightsSearches.slice(0, 3).map((recentFlightsSearch, index) => (
          <button
            css={styles.cards.card.root}
            key={index}
            onClick={() => {
              handlePopulateFlightsSearchWithRecent(recentFlightsSearch);
            }}
          >
            <div css={styles.cards.card.top.root}>
              <div css={styles.cards.card.top.traveler.root}>
                <span css={styles.cards.card.top.traveler.label}>
                  {transcription.traveler}
                </span>
                <span css={styles.cards.card.top.traveler.name}>
                  {recentFlightsSearch.user.fullName}
                </span>
              </div>
              <div css={styles.cards.card.top.date.root}>
                <span css={styles.cards.card.top.date.label}>
                  {recentFlightsSearch.oneway
                    ? transcription.oneway
                    : transcription.roundtrip}
                </span>
                <span css={styles.cards.card.top.date.value}>
                  {formatDate(recentFlightsSearch.outboundFlightDate)}
                  {!recentFlightsSearch.oneway &&
                    ` - ${formatDate(recentFlightsSearch.inboundFlightDate!)}`}
                </span>
              </div>
            </div>
            <div css={styles.cards.card.bottom.root}>
              <div css={styles.cards.card.bottom.origin.root}>
                <span css={styles.cards.card.bottom.origin.city}>
                  {recentFlightsSearch.origin.originCity}
                </span>
                <span css={styles.cards.card.bottom.origin.airport}>
                  {recentFlightsSearch.origin.originLocale}
                </span>
              </div>
              <Icon
                css={styles.cards.card.bottom.icon}
                use="airplane-taking-off"
              />
              <div css={styles.cards.card.bottom.destination.root}>
                <span css={styles.cards.card.bottom.destination.city}>
                  {recentFlightsSearch.destination.destinationCity}
                </span>
                <span css={styles.cards.card.bottom.destination.airport}>
                  {recentFlightsSearch.destination.destinationLocale}
                </span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export const NewTripRecentFlights: React.FC = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
