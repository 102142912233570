import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    placeholder: "Search trips...",
  },
  [LanguageCode.PT_BR]: {
    placeholder: "Pesquisar viagens...",
  },
} as const;

export const useTripsSearchbarTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
