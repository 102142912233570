import React, { createContext, useCallback, useEffect, useState } from "react";

import { ServiceType } from "~/apps/shared/constants/enums";
import { useContextFactory } from "~/apps/shared/hooks/use-context-factory";

import {
  FlightsSearch,
  HotelsSearch,
} from "../../components/offer-searcher/offer-searcher.types";
import { useUser } from "../../contexts/user.context";
import { BookingTarget } from "../../models/booking-target.model";
import * as newTripHelper from "./new-trip.helper";
import * as newTripService from "./new-trip.service";
import { RecentFlightsSearch, RecentHotelsSearch } from "./new-trip.types";

export const OFFER_SEARCHER_TABS = {
  BUS: "bus",
  CAR: "car",
  FLIGHT: "flight",
  HOTEL: "hotel",
} as const;

export type OfferSearcherTab = typeof OFFER_SEARCHER_TABS[keyof typeof OFFER_SEARCHER_TABS];

interface Actions {
  fetchRecentFlights: () => void;
  fetchRecentHotels: () => void;
  handlePopulateFlightsSearchWithRecent: (
    recentFlightSearch: RecentFlightsSearch,
  ) => void;
  handlePopulateHotelsSearchWithRecent: (
    recentHotelsearch: RecentHotelsSearch,
  ) => void;
  handleTabChange: (tab: OfferSearcherTab) => void;
}

type State = {
  defaultBookingTarget: BookingTarget | null;
  isLoadingRecentFlights: boolean;
  isLoadingRecentHotels: boolean;
  recentFlightsSearches: RecentFlightsSearch[];
  recentHotelsSearches: RecentHotelsSearch[];
  selectedSearch:
    | (
        | {
            data: FlightsSearch;
            type: ServiceType.FLIGHT;
          }
        | {
            data: HotelsSearch;
            type: ServiceType.HOTEL;
          }
      )
    | null;
  selectedTab: OfferSearcherTab;
  userDoesNotHaveRecentSearches: boolean;
};

const initialState: State = {
  defaultBookingTarget: null,
  isLoadingRecentFlights: false,
  isLoadingRecentHotels: false,
  recentFlightsSearches: [],
  recentHotelsSearches: [],
  selectedSearch: null,
  selectedTab: OFFER_SEARCHER_TABS.FLIGHT,
  userDoesNotHaveRecentSearches: true,
};

type ContextProps = Actions & State;

const NewTripContext = createContext<ContextProps>({
  ...initialState,
  fetchRecentFlights: async () => {
    return;
  },
  fetchRecentHotels: async () => {
    return;
  },
  handlePopulateFlightsSearchWithRecent: () => {
    return;
  },
  handlePopulateHotelsSearchWithRecent: () => {
    return;
  },
  handleTabChange: () => {
    return;
  },
});

export const NewTripProvider: React.FC = ({ children }) => {
  const { user } = useUser();

  const [state, setState] = useState(initialState);

  const fetchRecentFlights = useCallback(async () => {
    const { recentFlightsSearches } = state;

    if (recentFlightsSearches.length > 0 || !user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingRecentFlights: true,
    }));

    const userRecentFlightsResponse = await newTripService.getUserRecentFlights(
      user.getUserToken(),
    );

    if (userRecentFlightsResponse.isFailure()) {
      setState((prev) => ({
        ...prev,
        isLoadingRecentFlights: false,
      }));

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingRecentFlights: false,
      recentFlightsSearches: userRecentFlightsResponse.data,
      userDoesNotHaveRecentSearches:
        userRecentFlightsResponse.data.length === 0,
    }));
  }, [state.recentFlightsSearches, user]);

  const fetchRecentHotels = useCallback(async () => {
    const { recentHotelsSearches } = state;

    if (recentHotelsSearches.length > 0 || !user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingRecentHotels: true,
    }));

    const userRecentHotelsResponse = await newTripService.getUserRecentHotels(
      user.getUserToken(),
    );

    if (userRecentHotelsResponse.isFailure()) {
      setState((prev) => ({
        ...prev,
        isLoadingRecentHotels: false,
      }));

      return;
    }

    setState((prev) => ({
      ...prev,
      isLoadingRecentHotels: false,
      recentHotelsSearches: userRecentHotelsResponse.data,
      userDoesNotHaveRecentSearches: userRecentHotelsResponse.data.length === 0,
    }));
  }, [state.recentHotelsSearches, user]);

  const handlePopulateFlightsSearchWithRecent = useCallback(
    (recentFlightSearch: RecentFlightsSearch) => {
      setState((prev) => ({
        ...prev,
        selectedSearch: {
          data: newTripHelper.buildFlightsSearch(recentFlightSearch),
          type: ServiceType.FLIGHT,
        },
      }));
    },
    [],
  );

  const handlePopulateHotelsSearchWithRecent = useCallback(
    (recentHotelsearch: RecentHotelsSearch) => {
      setState((prev) => ({
        ...prev,
        selectedSearch: {
          data: newTripHelper.buildHotelsSearch(recentHotelsearch),
          type: ServiceType.HOTEL,
        },
      }));
    },
    [],
  );

  const handleTabChange = useCallback((tab: OfferSearcherTab) => {
    setState((prev) => ({
      ...prev,
      selectedTab: tab,
    }));
  }, []);

  useEffect(() => {
    const { defaultBookingTarget } = state;

    if (defaultBookingTarget) {
      return;
    }

    if (!user) {
      return;
    }

    setState((prev) => ({
      ...prev,
      defaultBookingTarget: {
        allowed: true,
        email: user.getEmail(),
        fullName: user.getFullName(),
        userToken: user.getUserToken(),
      },
    }));
  }, [state.defaultBookingTarget, user]);

  return (
    <NewTripContext.Provider
      value={{
        ...state,
        fetchRecentFlights,
        fetchRecentHotels,
        handlePopulateFlightsSearchWithRecent,
        handlePopulateHotelsSearchWithRecent,
        handleTabChange,
      }}
    >
      {children}
    </NewTripContext.Provider>
  );
};

export const useNewTrip = useContextFactory("NewTripContext", NewTripContext);
