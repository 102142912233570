import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    links: {
      approvals: "Approvals",
      home: "Home",
      trips: "Trips",
    },
    warning: "This is the company you're connected to.",
  },
  [LanguageCode.PT_BR]: {
    links: {
      approvals: "Aprovações",
      home: "Início",
      trips: "Viagens",
    },
    warning: "Esta é a empresa em que você está conectado.",
  },
} as const;

export const useNavBarDesktopTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
