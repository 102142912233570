import React from "react";

import { UserTravelOffer } from "~/apps/corporate/models/travel.model";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment from "moment";

import { CardOfferStatus } from "../card-offer-status/card-offer-status";
import { CardOfferTags } from "../card-offer-tags/card-offer-tags";
import { useTripCardHotelOfferTranscription } from "./card-hotel-offer.transcription";
import { styles } from "./styles";

type Props = {
  offer: UserTravelOffer;
};

export const TripCardHotelOffer: React.FC<Props> = ({ offer }) => {
  const { transcription } = useTripCardHotelOfferTranscription();

  const { date, hotel, status, tagStatus } = offer;

  if (!hotel) {
    return null;
  }

  const { city, name, totalNights } = hotel;

  const formattedStartDate = moment(date.initial).format("DD MMM YYYY");
  const formattedEndDate = moment(date.end).format("DD MMM YYYY");

  return (
    <div css={styles.root}>
      <div css={styles.left.root}>
        <Icon css={styles.left.icon} size={16} use="hotel" />
        <div css={styles.left.right.root}>
          <div css={styles.left.right.top.root}>
            <span css={styles.left.right.top.label}>
              {totalNights} {transcription.nights} {transcription.stayAt} {name}{" "}
              {transcription.stayAt} <strong>{city}</strong>
            </span>
          </div>
          <div css={styles.left.right.bottom.root}>
            <CardOfferStatus status={status} />
            <span css={styles.left.right.bottom.date}>
              {formattedStartDate} - {formattedEndDate}
            </span>
          </div>
        </div>
      </div>
      <CardOfferTags tags={tagStatus} />
    </div>
  );
};
