import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    edit: "Edit",
    labels: {
      email: "Email:",
      name: "Name:",
      phone: "Phone:",
    },
    title: "Basic information",
  },
  [LanguageCode.PT_BR]: {
    edit: "Editar",
    labels: {
      email: "E-mail:",
      name: "Nome:",
      phone: "Telefone:",
    },
    title: "Informações básicas",
  },
} as const;

export const useUserProfileDrawerBasicInformationSectionTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
