import React from "react";

import { Icon } from "~/apps/shared/components/icon/icon";
import { Tooltip } from "~/apps/shared/components/tooltip/tooltip";

import { useNavBarDesktopMemberGetMemberButtonTranscription } from "./member-get-member-button.transcription";
import { styles } from "./styles";

type Props = {
  handleOpenMemberGetMemebrDrawer: () => void;
};

export const NavBarDesktopMemberGetMemberButton: React.FC<Props> = ({
  handleOpenMemberGetMemebrDrawer,
}) => {
  const {
    transcription,
  } = useNavBarDesktopMemberGetMemberButtonTranscription();

  return (
    <Tooltip arrow content={transcription.tooltip} position="bottom">
      <button
        css={styles.root}
        onClick={() => {
          handleOpenMemberGetMemebrDrawer();
        }}
      >
        <Icon size={16} use="person-invite" />
      </button>
    </Tooltip>
  );
};
