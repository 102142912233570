import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    from: "from",
    oneWay: "One-way trip",
    roundTrip: "Roundtrip",
  },
  [LanguageCode.PT_BR]: {
    from: "de",
    oneWay: "Ida",
    roundTrip: "Ida e Volta",
  },
} as const;

export const useTripCardBusOfferTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
