import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    edit: "Edit",
    remove: "Remove",
  },
  [LanguageCode.PT_BR]: {
    edit: "Editar",
    remove: "Remover",
  },
} as const;

export const useUserDocumentCardMoreActionsPopoverTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
