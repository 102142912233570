import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    button: "Search buses",
    dates: {
      departure: {
        placeholder: "Departure*",
      },
      return: {
        placeholder: "Return*",
      },
    },
    destination: {
      empty: "No place found...",
      label: "To*",
      placeholder: "Type in the destination location...",
    },
    oneway: "Oneway",
    origin: {
      empty: "No place found...",
      label: "From*",
      placeholder: "Type in the origin location...",
    },
    roundtrip: "Roundtrip",
  },
  [LanguageCode.PT_BR]: {
    button: "Buscar ônibus",
    dates: {
      departure: {
        placeholder: "Ida*",
      },
      return: {
        placeholder: "Volta*",
      },
    },
    destination: {
      empty: "Nenhum local encontrado...",
      label: "Destino*",
      placeholder: "Digite o local de destino...",
    },
    oneway: "Ida",
    origin: {
      empty: "Nenhum local encontrado...",
      label: "Origem*",
      placeholder: "Digite o local de origem...",
    },
    roundtrip: "Ida e volta",
  },
} as const;

export const useBusFormTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
