import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    tabs: {
      buses: "Buses",
      cars: "Cars",
      flights: "Flights",
      hotels: "Hotels",
    },
  },
  [LanguageCode.PT_BR]: {
    tabs: {
      buses: "Ônibus",
      cars: "Carros",
      flights: "Voos",
      hotels: "Hotéis",
    },
  },
} as const;

export const useNewTripOfferSearcherTabsTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
