import React, { useCallback, useEffect, useMemo, useState } from "react";

import { UserDocumentForm } from "~/apps/corporate/components/user-document-form/user-document-form";
import { useDocuments } from "~/apps/corporate/contexts/documents.context";
import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { useUser } from "~/apps/corporate/contexts/user.context";
import { DOCUMENT_TYPES_TRANSCRIPTION } from "~/apps/shared/constants";
import { DocumentType } from "~/apps/shared/constants/enums";
import { Option } from "~/apps/shared/types";
import { sleep } from "~/apps/shared/utils/sleep";

import {
  mapUserDocumentFormDefaultValues,
  mapUserDocumentFormValuesToCreateDocumentDto,
  mapUserDocumentFormValuesToEditDocumentDto,
} from "../../../user-document-form/user-document-form.helper";
import { UserDocumentFormSchema } from "../../../user-document-form/user-document-form.schema";
import { useUserProfileDrawer } from "../../user-profile-drawer.context";

type Props = {
  back: () => void;
};

export const UserProfileDrawerSaveDocument: React.FC<Props> = ({ back }) => {
  const { createDocument, documents, editDocument } = useDocuments();
  const { languageCode } = useLanguage();
  const { user } = useUser();

  const { selectedDocument } = useUserProfileDrawer();

  const [availableDocumentTypes, setAvailableDocumentTypes] = useState<
    Option<DocumentType>[]
  >([]);

  const mode = useMemo(() => (!selectedDocument ? "create" : "edit"), [
    selectedDocument,
  ]);

  const onSubmit = useCallback(
    async (values: UserDocumentFormSchema) => {
      if (mode === "edit" && !!selectedDocument) {
        const { documentToken } = selectedDocument;

        const sucess = await editDocument(
          mapUserDocumentFormValuesToEditDocumentDto(documentToken, values),
        );

        if (!sucess) {
          return;
        }

        back();

        return;
      }

      await sleep(2000);

      const sucess = await createDocument(
        mapUserDocumentFormValuesToCreateDocumentDto(values),
      );

      if (!sucess) {
        return;
      }

      back();
    },
    [back, createDocument, editDocument, mode],
  );

  useEffect(() => {
    if (!documents || selectedDocument) {
      return;
    }

    const availableDocumentTypes = Object.entries(DOCUMENT_TYPES_TRANSCRIPTION)
      .map(([k, v]) => ({
        label: v[languageCode],
        value: k as DocumentType,
      }))
      .filter(
        (documentType) =>
          !documents.find((document) => document.type === documentType.value),
      );

    setAvailableDocumentTypes(availableDocumentTypes);
  }, [documents, languageCode, selectedDocument]);

  if (!documents) {
    return null;
  }

  if (mode === "edit" && !!selectedDocument) {
    return (
      <UserDocumentForm
        defaultValues={mapUserDocumentFormDefaultValues(selectedDocument, user)}
        onCancel={() => {
          back();
        }}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <UserDocumentForm
      availableDocumentTypes={availableDocumentTypes}
      defaultValues={mapUserDocumentFormDefaultValues(null, user)}
      onCancel={() => {
        back();
      }}
      onSubmit={onSubmit}
    />
  );
};
