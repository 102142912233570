import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    buttons: {
      search: "Search cars",
    },
    dropoff: {
      date: {
        placeholder: "Drop-off date*",
      },
      location: {
        empty: "No place found...",
        label: "Drop-off*",
        placeholder: "Type in the drop-off location...",
      },
    },
    pickup: {
      date: {
        placeholder: "Pickup date*",
      },
      location: {
        empty: "No place found...",
        label: "Pickup*",
        placeholder: "Type in the pickup location...",
      },
    },
    sameLocation: "Return at the same location",
  },
  [LanguageCode.PT_BR]: {
    buttons: {
      search: "Pesquisar carros",
    },
    dropoff: {
      date: {
        placeholder: "Data da devolução*",
      },
      location: {
        empty: "Nenhum local encontrado...",
        label: "Devolução*",
        placeholder: "Digite o local da devolução...",
      },
    },
    pickup: {
      date: {
        placeholder: "Data da retirada*",
      },
      location: {
        empty: "Nenhum local encontrado...",
        label: "Retirada*",
        placeholder: "Digite o local da retirada...",
      },
    },
    sameLocation: "Devolver no mesmo local",
  },
} as const;

export const useCarFormTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
