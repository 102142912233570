import { LanguageCode, OfferStatus } from "./enums";

export const OFFER_STATUS_COLOR = {
  [OfferStatus.APPROVAL_DECLINED]: "#FF0000",
  [OfferStatus.BOOKING_FAILED]: "#FF0000",
  [OfferStatus.BOOKING_PROCESSING]: "#FA831B",
  [OfferStatus.CANCELED]: "#B24531",
  [OfferStatus.CANCELING]: "#FA831B",
  [OfferStatus.DRAFT]: "#808080",
  [OfferStatus.EMITTED]: "#027A48",
} as const;

export const OFFER_STATUS_TRANSCRIPTION = {
  [OfferStatus.APPROVAL_DECLINED]: {
    [LanguageCode.EN_US]: "Approval declined",
    [LanguageCode.PT_BR]: "Aprovação negada",
  },
  [OfferStatus.BOOKING_FAILED]: {
    [LanguageCode.EN_US]: "Booking failed",
    [LanguageCode.PT_BR]: "Falha de emissão",
  },
  [OfferStatus.BOOKING_PROCESSING]: {
    [LanguageCode.EN_US]: "Processing",
    [LanguageCode.PT_BR]: "Emitindo",
  },
  [OfferStatus.CANCELED]: {
    [LanguageCode.EN_US]: "Canceled",
    [LanguageCode.PT_BR]: "Cancelada",
  },
  [OfferStatus.CANCELING]: {
    [LanguageCode.EN_US]: "Canceling",
    [LanguageCode.PT_BR]: "Cancelando",
  },
  [OfferStatus.DRAFT]: {
    [LanguageCode.EN_US]: "Draft",
    [LanguageCode.PT_BR]: "Rascunho",
  },
  [OfferStatus.EMITTED]: {
    [LanguageCode.EN_US]: "Emitted",
    [LanguageCode.PT_BR]: "Emitida",
  },
} as const;
