import React from "react";

import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

import { styles } from "./styles";

const OFFER_TAGS = {
  negotiated: {
    color: "#FA831B",
    label: {
      [LanguageCode.EN_US]: "Negotiated",
      [LanguageCode.PT_BR]: "Negociada",
    },
  },
  pendingApproval: {
    color: "#C354FF",
    label: {
      [LanguageCode.EN_US]: "Pending Approval",
      [LanguageCode.PT_BR]: "Pendente de aprovação",
    },
  },
  preReserved: {
    color: "#194EC0",
    label: {
      [LanguageCode.EN_US]: "Pre-reserved",
      [LanguageCode.PT_BR]: "Pré-reservado",
    },
  },
  preReserveExpired: {
    color: "#FA831B",
    label: {
      [LanguageCode.EN_US]: "Pre-reserve Expired",
      [LanguageCode.PT_BR]: "Pré-reserva expirada",
    },
  },
  preReserveFailed: {
    color: "#FA831B",
    label: {
      [LanguageCode.EN_US]: "Pre-reserve Failed",
      [LanguageCode.PT_BR]: "Falha na pré-reserva",
    },
  },
  preReserving: {
    color: "#FA831B",
    label: {
      [LanguageCode.EN_US]: "Pre-reserving",
      [LanguageCode.PT_BR]: "Pré-reservando",
    },
  },
} as const;

type Props = {
  tags: {
    [key in keyof typeof OFFER_TAGS]: boolean;
  };
};

export const CardOfferTags: React.FC<Props> = ({ tags }) => {
  const { languageCode } = useLanguage();

  return (
    <div css={styles.root}>
      {Object.entries(tags).map(([tag, isPresent]) => {
        if (!isPresent || !(tag in OFFER_TAGS)) {
          return null;
        }

        const offerTag = OFFER_TAGS[tag as keyof typeof OFFER_TAGS];

        return (
          <span
            css={styles.tag({
              color: offerTag.color,
            })}
            key={tag}
          >
            {offerTag.label[languageCode]}
          </span>
        );
      })}
    </div>
  );
};
