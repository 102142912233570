import React, { useMemo } from "react";

import { useLoyaltyPrograms } from "~/apps/corporate/contexts/loyalty-program.context";
import { Form, useForm } from "~/apps/shared/components/form/form";
import { InputErrorMessage } from "~/apps/shared/components/input-error-message/input-error-message";
import { Input } from "~/apps/shared/components/input/input";
import { Select } from "~/apps/shared/components/select/select";
import { LOYALTY_PROGRAMS } from "~/apps/shared/constants";

import { Button } from "@toolkit/v2";

import { useUserProfileDrawer } from "../../user-profile-drawer.context";
import {
  saveLoyaltyProgramSchema,
  SaveLoyaltyProgramSchema,
} from "./save-loyalty-program.schema";
import { useUserProfileDrawerSaveLoyaltyProgramTranscription } from "./save-loyalty-program.transcription";
import { styles } from "./styles";

const loyaltyPrograms = Object.entries(LOYALTY_PROGRAMS).map(([k, v]) => ({
  label: v,
  value: k,
}));

type Props = {
  back: () => void;
};

export const UserProfileDrawerSaveLoyaltyProgram: React.FC<Props> = ({
  back,
}) => {
  const {
    transcription,
  } = useUserProfileDrawerSaveLoyaltyProgramTranscription();
  const { saveLoyaltyProgram } = useLoyaltyPrograms();
  const { selectedLoyaltyProgram } = useUserProfileDrawer();

  const form = useForm<SaveLoyaltyProgramSchema>({
    defaultValues: {
      code: selectedLoyaltyProgram ? selectedLoyaltyProgram.code : null,
      name: selectedLoyaltyProgram ? selectedLoyaltyProgram.name : null,
    },
    onSubmit: async () => {
      const values = form.getValues();

      const sucess = await saveLoyaltyProgram({
        code: values.code,
        name: values.name,
      });

      if (!sucess) {
        return;
      }

      back();
    },
    schema: saveLoyaltyProgramSchema,
  });

  const { name } = form.watch(["name"]);

  const translatedLoyaltyProgramName = useMemo(() => {
    return loyaltyPrograms.find(
      (loyaltyProgram) => loyaltyProgram.value === name,
    );
  }, [loyaltyPrograms, name]);

  return (
    <Form context={form} css={styles.root}>
      <div css={styles.body.root}>
        <div css={styles.body.select.root}>
          <span css={styles.body.select.label}>
            {transcription.fields.program.label}
          </span>
          <Select
            id="name"
            name="name"
            onChange={({ value }) => {
              form.setValue("name", value);
            }}
            options={loyaltyPrograms}
            placeholder={transcription.fields.program.placeholder}
            useSetDefaultValueIfOnlyOneOption
            value={translatedLoyaltyProgramName}
          />
          <InputErrorMessage>{form.errors["name"]?.message}</InputErrorMessage>
        </div>
        <div css={styles.body.input.root}>
          <span css={styles.body.input.label}>
            {transcription.fields.code.label}
          </span>
          <Form.Field
            name="code"
            render={({ onChange, value }) => (
              <Input
                css={styles.body.input.input}
                onChange={onChange}
                placeholder={transcription.fields.code.placeholder}
                value={value}
              />
            )}
          />
          <InputErrorMessage>{form.errors["code"]?.message}</InputErrorMessage>
        </div>
      </div>
      <div css={styles.footer.root}>
        <Button
          disabled={form.formState.isSubmitting}
          fill="outlined"
          onClick={() => {
            back();
          }}
          type="button"
        >
          {transcription.buttons.cancel}
        </Button>
        <Button disabled={form.formState.isSubmitting} variant="pink">
          {transcription.buttons.save}
        </Button>
      </div>
    </Form>
  );
};
