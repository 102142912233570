import {
  GetClientBossesResponse,
  GetClientConfigResponse,
  GetClientInfoResponse,
} from "~/apps/corporate/dtos/client.dto";
import { ClientBookingPhoneConfig } from "~/apps/shared/constants/enums";

import {
  ClientBoss,
  ClientConfigModelFactory,
  ClientInfo,
} from "../models/client.model";

export const parseClientBoss = (dto: GetClientBossesResponse) => {
  const clientBoss: ClientBoss = {
    email: dto.boss_email,
    token: dto.boss_token,
    employeeToken: dto.employee_token,
  };

  return clientBoss;
};

export const parseClientConfig = (dto: GetClientConfigResponse) => {
  const clientConfig = ClientConfigModelFactory.create({
    approvalConfig: dto.approval_config,
    approvalPaySelection: dto.approval_pay_selection,
    approvalPriorityRanking: dto.approval_ranking,
    bank3Enable: dto.bank3_enabled,
    bookingPhoneConfig: dto.booking_phone_config
      ? dto.booking_phone_config
      : ClientBookingPhoneConfig.NOT_REQUIRED,
    budgetsActive: dto.budgets_active,
    clientConfigToken: dto.client_config_token,
    companyAreaDisplay: dto.company_area_display,
    companyAreaEnabled: dto.company_area_enabled,
    companyBoardDisplay: dto.company_board_display,
    config: dto.config,
    costCenterActive: dto.cost_center_active,
    customCorporativeCardName: dto.custom_corporative_card_name,
    customMessage: dto.custom_message,
    customMessageLink: dto.custom_message_link,
    featureVisibilities: dto.feature_visibilities,
    guestApprovalType: dto.guest_approval_type,
    projectEnablingState: dto.project_enabling_state,
    supportChannel: dto.support_channel,
    tagsAdminOnly: dto.tags_admin_only,
    travelIntegration: dto.travel_integration,
    travelTagsRequired: dto.travel_tags_required,
  });

  return clientConfig;
};

export const parseClientInfo = (dto: GetClientInfoResponse) => {
  const clientInfo: ClientInfo = {
    active: dto.active,
    bookingFeeOption: dto.booking_fee_option,
    budgetsActive: dto.budgets_active,
    clientSlug: dto.client_slug,
    clientToken: dto.client_token,
    costCenterActive: dto.cost_center_active,
    createdAt: dto.created_at,
    email: dto.email,
    id: dto.id,
    name: dto.name,
  };

  return clientInfo;
};
