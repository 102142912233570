import React, { useEffect } from "react";

import { ErrorBoundary } from "~/apps/shared/components/error-boundary/error-boundary";
import { Icon } from "~/apps/shared/components/icon/icon";
import moment, { Moment } from "moment";

import { useNewTrip } from "../new-trip.context";
import { useNewTripRecentHotelsTranscription } from "./recent-hotels.transcription";
import { styles } from "./styles";

const formatDate = (date: Moment) => {
  return moment(date).format("DD MMM");
};

const Component: React.FC = () => {
  const { transcription } = useNewTripRecentHotelsTranscription();

  const {
    fetchRecentHotels,
    handlePopulateHotelsSearchWithRecent,
    recentHotelsSearches,
  } = useNewTrip();

  useEffect(() => {
    void fetchRecentHotels();
  }, []);

  if (recentHotelsSearches.length === 0) {
    return null;
  }

  return (
    <div css={styles.root}>
      <span css={styles.title}>{transcription.title}</span>
      <div css={styles.cards.root}>
        {recentHotelsSearches.slice(0, 3).map((recentHotelsSearch, index) => (
          <button
            css={styles.cards.card.root}
            key={index}
            onClick={() => {
              handlePopulateHotelsSearchWithRecent(recentHotelsSearch);
            }}
          >
            <div css={styles.cards.card.top.root}>
              <div css={styles.cards.card.top.traveler.root}>
                <span css={styles.cards.card.top.traveler.label}>
                  {transcription.traveler}
                </span>
                <span css={styles.cards.card.top.traveler.name}>
                  {recentHotelsSearch.traveler?.fullName}
                </span>
              </div>
              <div css={styles.cards.card.top.date.root}>
                <span css={styles.cards.card.top.date.label}>
                  {transcription.date.label}
                </span>
                <span css={styles.cards.card.top.date.value}>
                  {recentHotelsSearch.startDate
                    ? formatDate(recentHotelsSearch.startDate)
                    : null}
                  {recentHotelsSearch.startDate && recentHotelsSearch.endDate
                    ? " - "
                    : null}
                  {recentHotelsSearch.endDate
                    ? formatDate(recentHotelsSearch.endDate)
                    : null}
                </span>
              </div>
            </div>
            <div css={styles.cards.card.bottom.root}>
              <div css={styles.cards.card.bottom.origin.root}>
                <span css={styles.cards.card.bottom.origin.city}>
                  {`${recentHotelsSearch.location?.city}${
                    recentHotelsSearch.location?.state
                      ? ` - ${recentHotelsSearch.location.state}`
                      : ""
                  }`}
                </span>
                <span css={styles.cards.card.bottom.origin.airport}>
                  {recentHotelsSearch.location?.country}
                </span>
              </div>
              <Icon css={styles.cards.card.bottom.icon} use="hotel" />
              <div css={styles.cards.card.bottom.destination.root}>
                <span css={styles.cards.card.bottom.destination.city}>
                  {transcription.guests}
                </span>
                <span css={styles.cards.card.bottom.destination.airport}>
                  {recentHotelsSearch.totalGuests}
                </span>
              </div>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export const NewTripRecentHotels: React.FC = ({ ...props }) => {
  return (
    <ErrorBoundary fallback={() => <></>}>
      <Component {...props} />
    </ErrorBoundary>
  );
};
