import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    columns: {
      from: "From",
      expiration: "Expires on",
      id: "ID",
      provider: "Airline",
      value: "Value",
    },
    message: "See the details of your credits:",
    title: "Your credits.",
  },
  [LanguageCode.PT_BR]: {
    columns: {
      from: "A partir de",
      expiration: "Expira em",
      id: "ID",
      provider: "Companhia aérea",
      value: "Valor",
    },
    message: "Veja os detalhes dos seus créditos:",
    title: "Seus créditos.",
  },
} as const;

export const useCreditsCreditDetailsDialogTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
