import { css } from "@styled-system/css";

import { theme } from "@skin/v2";

export const styles = {
  button: css({
    backgroundColor: theme.colors.gray[500],
    border: "none",
    borderRadius: "50%",
    color: theme.colors.white,
    cursor: "pointer",
    display: "grid",
    height: "60px",
    placeItems: "center",
    position: "relative",
    transition: "background-color 0.15s ease-in-out",
    width: "60px",
    ":hover": {
      backgroundColor: theme.colors.gray[700],
    },
  }),
  root: css({
    position: "fixed",
    zIndex: 1300,
    "@media (min-width: 992px)": {
      bottom: 20,
      right: 20,
    },
    "@media (max-width: 991px)": {
      bottom: "calc(68px + 16px + env(safe-area-inset-bottom))",
      right: 16,
    },
  }),
};
