import React from "react";

import { Menu } from "~/apps/shared/components/menu/menu";
import { MenuItem } from "~/apps/shared/components/menu/menu-item/menu-item";
import { Document } from "~/apps/shared/models/documents.model";

import { useUserDocumentCardMoreActionsPopoverTranscription } from "./user-document-card-more-actions-popover.transcription";

type Props = {
  anchorElement: HTMLElement | null;
  document: Document | null;
  onClose: () => void;
  onDelete: (document: Document) => void;
  onEdit: (document: Document) => void;
};

export const UserDocumentCardMoreActionsPopover: React.FC<Props> = ({
  anchorElement,
  document,
  onClose,
  onDelete,
  onEdit,
}) => {
  const {
    transcription,
  } = useUserDocumentCardMoreActionsPopoverTranscription();

  if (!document) {
    return null;
  }

  return (
    <Menu anchorEl={anchorElement} onClose={onClose} open={!!anchorElement}>
      <MenuItem
        onClick={() => {
          onEdit(document);

          onClose();
        }}
      >
        {transcription.edit}
      </MenuItem>
      <MenuItem
        onClick={() => {
          onDelete(document);

          onClose();
        }}
      >
        {transcription.remove}
      </MenuItem>
    </Menu>
  );
};
