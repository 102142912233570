import React, { useCallback, useEffect, useMemo } from "react";

import { useDocuments } from "~/apps/corporate/contexts/documents.context";
import { AlertDialog } from "~/apps/shared/components/alert-dialog/alert-dialog";
import { DOCUMENT_TYPES_TRANSLATION } from "~/apps/shared/constants";

import { useUserProfileDrawer } from "../user-profile-drawer.context";
import { useRemoveDocumentAlertDialogTranscription } from "./remove-document-alert-dialog.transcription";

export const RemoveDocumentAlertDialog: React.FC = () => {
  const {
    deleteDocument,
    errorOnFetchDeleteDocument,
    isLoadingDeleteDocument,
  } = useDocuments();
  const {
    closeRemoveDocumentAlertDialog,
    isRemoveDocumentAlertDialogOpen,
    selectedDocument,
  } = useUserProfileDrawer();

  const { transcription } = useRemoveDocumentAlertDialogTranscription();

  const handleClose = useCallback(() => {
    closeRemoveDocumentAlertDialog();
  }, [closeRemoveDocumentAlertDialog]);

  const handleConfirm = useCallback(async () => {
    if (!selectedDocument) {
      closeRemoveDocumentAlertDialog();
      return;
    }

    await deleteDocument(selectedDocument.documentToken);
    closeRemoveDocumentAlertDialog();
  }, [closeRemoveDocumentAlertDialog, deleteDocument, selectedDocument]);

  const translatedDocumentType = useMemo(() => {
    if (!selectedDocument) {
      return null;
    }
    return DOCUMENT_TYPES_TRANSLATION[selectedDocument.type];
  }, [selectedDocument]);

  useEffect(() => {
    if (!errorOnFetchDeleteDocument) {
      return;
    }
    handleClose();
  }, [errorOnFetchDeleteDocument, handleClose]);

  return (
    <AlertDialog
      confirmLabel={transcription.buttons.confirm}
      isLoading={isLoadingDeleteDocument}
      message={
        translatedDocumentType
          ? transcription.message(translatedDocumentType)
          : transcription.fallbackMessage
      }
      onClose={handleClose}
      onConfirm={handleConfirm}
      open={isRemoveDocumentAlertDialogOpen}
      title={transcription.title}
    />
  );
};
