import React, { useMemo } from "react";

import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { Filters } from "~/apps/shared/components/filters/filters";
import {
  LanguageCode,
  TripsStatusFilter as TripsStatusFilterEnum,
} from "~/apps/shared/constants/enums";
import { useWindowSize } from "~/apps/shared/hooks/use-window-size";

import { useTravelsFilters, useTravelsFiltersActions } from "../trips.context";

const options = [
  {
    label: {
      [LanguageCode.EN_US]: "Approval Pending",
      [LanguageCode.PT_BR]: "Aprovação Pendente",
    },
    value: TripsStatusFilterEnum.HAS_APPROVAL_PENDING,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Processing Booking",
      [LanguageCode.PT_BR]: "Processando Emissão",
    },
    value: TripsStatusFilterEnum.HAS_BOOKING_PENDING,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Processing Cancellation",
      [LanguageCode.PT_BR]: "Processando Cancelamento",
    },
    value: TripsStatusFilterEnum.HAS_CANCELING_PENDING,
  },
  {
    label: {
      [LanguageCode.EN_US]: "Booking Failed",
      [LanguageCode.PT_BR]: "Falha de Emissão",
    },
    value: TripsStatusFilterEnum.HAS_BOOKING_FAILED,
  },
] as const;

export const TripsStatusFilter: React.FC = () => {
  const { languageCode } = useLanguage();

  const { width: windowWidth } = useWindowSize();
  const isMobile = useMemo(() => windowWidth <= 435, [windowWidth]);

  const { pendingFilters } = useTravelsFilters();
  const { handleChangePendingFilters } = useTravelsFiltersActions();

  return (
    <Filters
      anchor={isMobile ? "left" : "right"}
      isMulti
      onChange={(value) => {
        if (pendingFilters.some((filter) => filter === value)) {
          handleChangePendingFilters(
            pendingFilters.filter((filter) => filter !== value),
          );
          return;
        }
        handleChangePendingFilters([...pendingFilters, value]);
      }}
      options={options.map((option) => ({
        ...option,
        label: option.label[languageCode],
      }))}
      value={pendingFilters.map((filter) => filter)}
    />
  );
};
