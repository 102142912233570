import { useLanguage } from "~/apps/corporate/contexts/language.context";
import { LanguageCode } from "~/apps/shared/constants/enums";

const transcriptions = {
  [LanguageCode.EN_US]: {
    nights: "night(s)",
    stayAt: "at",
  },
  [LanguageCode.PT_BR]: {
    nights: "noite(s)",
    stayAt: "em",
  },
} as const;

export const useTripCardHotelOfferTranscription = () => {
  const { languageCode } = useLanguage();

  const transcription =
    transcriptions[languageCode as keyof typeof transcriptions];

  return { transcription };
};
